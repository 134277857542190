<template lang="">
    <div class="staking-share-container">
        <div class="staking-share-heading">
            <h3>{{ $t('stakeRatio') }}</h3>
            <!-- <button>{{$t('seeMore')}} <span>></span></button> -->
        </div>
        <div v-if="StakeStat">
            <StakingSharePieChart
                :series="[
                    StakeStat.selfStakingAmount,
                    StakeStat.totalStakingAmount - StakeStat.selfStakingAmount,
                ]"
            />
        </div>
        <div>
            <div class="staking-share-total">
                <span> {{ $t('totalPoolStake') }}: </span>
                <span> {{ StakeStat.totalStakingAmount.toLocaleString('en-US') }} XIN </span>
            </div>
            <div class="staking-share-total">
                <span> {{ $t('myStake') }}: </span>
                <span> {{ StakeStat.selfStakingAmount.toLocaleString('en-US') }} XIN </span>
            </div>
        </div>
    </div>
</template>
<script>
import { h } from 'vue';
import { mapGetters } from 'vuex';

import StakingSharePieChart from './StakingSharePieChart.vue';

export default {
    name: 'StakingShare',
    components: {
        StakingSharePieChart,
    },
    computed: {
        ...mapGetters({
            StakeStat: 'stake/StakeStat',
        }),
    },
    methods: {
        stylizedText(input) {
            const sep = input.split('.');
            if (sep[1]) {
                return [h('span', sep[0]), h('span', sep[1])];
            }
            return [h('span', input)];
        },
    },
};
</script>
<style lang="scss" scoped>
.staking-share-container {
    border: 1px solid #ffff;
    border-radius: 20px;
    padding: 32px;
    border-color: #d8d8d8;
    background: #fff;
    min-height: 386px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: space-between;
    padding: 24px;

    @media (min-width: 1230px) {
        font-size: 14px;
        padding: 32px;
        gap: 10px;
    }

    .staking-share-heading {
        display: flex;
        justify-content: space-between;
        align-items: center;

        h3 {
            font-size: 24px;
            font-weight: 700;
            line-height: 24px;
            text-align: left;
        }

        button {
            padding: 6px 8px 6px 8px;
            gap: 6px;
            color: #0087cc;
            border-radius: 8px 0px 0px 0px;
            font-size: 14px;
            font-weight: 700;
            line-height: 24px;
            letter-spacing: 1px;
            text-align: left;
            @media (min-width: 1230px) {
                font-size: 16px;
            }
        }
    }

    .staking-share-total {
        display: flex;
        justify-content: space-between;
        margin-top: 17px;
    }

    .staking-share-total span:first-child {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.5px;
        color: #3c3c3c;
    }

    .staking-share-total span:last-child {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        text-align: right;
        color: #0087cc;
    }
    @media (min-width: 922px) {
        .staking-share-total
            span:first-child
            .staking-share-total
            span:last-child {
            font-size: 18px;
        }
    }
}
</style>
