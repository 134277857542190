<template>
    <div>
        <b-modal
            id="data-payment"
            hide-footer
            hide-header
            centered
            dialog-class="supa-modal"
            @hidden="resetForm"
        >
            <svg
                @click="$bvModal.hide('data-payment')"
                class="close"
                xmlns="http://www.w3.org/2000/svg"
                width="1em"
                height="1em"
                viewBox="0 0 24 24"
            >
                <path
                    fill="currentColor"
                    d="M6.4 19L5 17.6l5.6-5.6L5 6.4L6.4 5l5.6 5.6L17.6 5L19 6.4L13.4 12l5.6 5.6l-1.4 1.4l-5.6-5.6z"
                />
            </svg>
            <div class="mi-head">{{ $t('yourPhonePayment') }}</div>
            <div>
                <form
                    class="base-form"
                    @submit.prevent="$bvModal.show('data-confirm')"
                >
                    <label>
                        {{ $t('phoneNumber') }}
                        <input
                            v-model="phone"
                            autocomplete="tel"
                            :placeholder="$t('enterPhoneNumber')"
                            required
                        />
                    </label>
                    <label>
                        {{ $t('paymentType') }}
                        <select v-model="paymentType" required>
                            <option
                                v-for="payment in paymentTypes"
                                :key="payment.value"
                                :value="payment.value"
                            >
                                {{ $t(payment.text) }}
                            </option>
                        </select>
                    </label>
                    <label>
                        {{ $t('telcoProviders') }}
                        <select v-model="chosenTelco" required>
                            <option value="" disabled>
                                {{ $t('selectServiceProviders') }}
                            </option>
                            <option
                                v-for="telco in AvailableTelco"
                                :key="telco.value"
                                :value="telco.value"
                            >
                                {{ $t(telco.text) }}
                            </option>
                        </select>
                    </label>
                    <template v-if="chosenTelco">
                        <label>
                            {{ $t('dataPackage') }}
                            <select v-model="chosenProduct" required>
                                <option value="" disabled>
                                    {{ $t('selectDataPackage') }}
                                </option>
                                <option
                                    v-for="product in AvailableProducts"
                                    :key="product.productCode"
                                    :value="product"
                                >
                                    <span>
                                        {{
                                            Number(
                                                product.amount,
                                            ).toLocaleString('en-US')
                                        }}
                                        VNĐ
                                    </span>
                                    -
                                    <span>
                                        {{ product.capacity }}
                                    </span>
                                    -
                                    <span>
                                        {{ product.use_term }}
                                    </span>
                                </option>
                            </select>
                        </label>
                        <template v-if="chosenProduct">
                            <div class="mini-detail">
                                <div>
                                    <div>{{ $t('amount') }}:</div>
                                    <div>
                                        {{
                                            Number(
                                                chosenProduct.amount,
                                            ).toLocaleString('en-US')
                                        }}
                                        VNĐ
                                    </div>
                                </div>
                                <div>
                                    <div>{{ $t('capacity') }}:</div>
                                    <div>
                                        {{ chosenProduct.capacity }}
                                    </div>
                                </div>
                                <div>
                                    <div>{{ $t('useTerm') }}:</div>
                                    <div>
                                        {{ chosenProduct.use_term }}
                                    </div>
                                </div>
                                <template v-if="fetchingRate">
                                    <div class="loader">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="1em"
                                            height="1em"
                                            viewBox="0 0 24 24"
                                        >
                                            <path
                                                fill="none"
                                                stroke="currentColor"
                                                stroke-dasharray="16"
                                                stroke-dashoffset="16"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                                stroke-width="2"
                                                d="M12 3c4.97 0 9 4.03 9 9"
                                            >
                                                <animate
                                                    fill="freeze"
                                                    attributeName="stroke-dashoffset"
                                                    dur="0.2s"
                                                    values="16;0"
                                                />
                                                <animateTransform
                                                    attributeName="transform"
                                                    dur="1.5s"
                                                    repeatCount="indefinite"
                                                    type="rotate"
                                                    values="0 12 12;360 12 12"
                                                />
                                            </path>
                                        </svg>
                                    </div>
                                </template>
                                <template v-else-if="VNDToXIN">
                                    <div>
                                        <div>
                                            {{ $t('amountInXIN') }}
                                        </div>
                                        <div>
                                            {{
                                                Number(VNDToXIN).toLocaleString(
                                                    'en-US',
                                                )
                                            }}
                                            XIN
                                        </div>
                                    </div>
                                </template>
                            </div>
                        </template>
                    </template>
                    <div class="botans">
                        <button class="botan confirm" type="submit">
                            {{ $t('payment') }}
                        </button>
                    </div>
                </form>
            </div>
        </b-modal>
        <b-modal
            id="data-confirm"
            hide-header
            hide-footer
            dialog-class="supa-modal"
            centered
        >
            <div class="mi-head">{{ $t('confirmPayment') }}?</div>
            <div class="mi-confirm">{{ $t('doYouContinuePayment') }}?</div>
            <div class="confirm-value" v-if="chosenProduct">
                <div class="item">
                    <div>{{ $t('phoneNumber') }}</div>
                    <div class="value">
                        {{ phone }}
                    </div>
                </div>
                <div class="item">
                    <div>{{ $t('amount') }}</div>
                    <div class="value">
                        {{ TruncateToDecimals2(Number(chosenProduct.amount)) }}
                        VNĐ
                    </div>
                </div>
                <div class="item">
                    <div>{{ $t('capacity') }}:</div>
                    <div class="value">
                        {{ chosenProduct.capacity }}
                    </div>
                </div>
                <div class="item">
                    <div>{{ $t('useTerm') }}:</div>
                    <div class="value">
                        {{ chosenProduct.use_term }}
                    </div>
                </div>
                <div class="item">
                    <div>{{ $t('payWith') }}</div>
                    <div class="value">
                        {{ TruncateToDecimals2(VNDToXIN) }}
                        <img src="@/assets/images/logo/xin.png" width="18px" />
                    </div>
                </div>
            </div>
            <div class="botans">
                <button class="botan confirm" @click="postPayment">
                    {{ $t('confirm') }}
                </button>
                <button class="botan" @click="$bvModal.hide('data-confirm')">
                    {{ $t('cancel') }}
                </button>
            </div>
            <button
                class="close black"
                @click="$bvModal.hide('data-confirm')"
                type="button"
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="1em"
                    height="1em"
                    buyVoucherviewBox="0 0 24 24"
                >
                    <path
                        fill="currentColor"
                        d="M6.4 19L5 17.6l5.6-5.6L5 6.4L6.4 5l5.6 5.6L17.6 5L19 6.4L13.4 12l5.6 5.6l-1.4 1.4l-5.6-5.6z"
                    />
                </svg>
            </button>
        </b-modal>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    name: 'PhonePayment',
    data() {
        return {
            phone: '',
            amount: null,
            pending: false,
            VNDToXIN: 0,
            chosenTelco: '',
            chosenProduct: '',
            fetchingRate: false,
            paymentTypes: [
                {
                    value: 'prepaid',
                    text: 'prepaid',
                },
                {
                    value: 'postpaid',
                    text: 'postpaid',
                },
            ],
            paymentType: 'prepaid',
        };
    },
    computed: {
        ...mapGetters({
            TelData: 'utilities/TelData',
            TelMobile: 'utilities/TelMobile',
        }),
        AvailableTelco() {
            const array = [];
            for (let i = 0; this.TelData.length > i; i += 1) {
                if (!array.includes(this.TelData[i].telco)) {
                    console.log(
                        'Idex:',
                        i,
                        this.TelData[i].telco,
                        array.includes[this.TelData[i].telco],
                    );
                    array.push(this.TelData[i].telco);
                }
            }
            return array.map((el) => ({
                value: el,
                text: el.replace('_data', '').toUpperCase(),
            }));
        },
        AvailableProducts() {
            const array = this.TelData.filter(
                (el) => el.telco === this.chosenTelco,
            );
            return array;
        },
    },
    methods: {
        async postPayment() {
            if (this.chosenProduct === '') {
                return;
            }
            if (this.pending) {
                return;
            }
            this.pending = true;
            const trimmedTelco = this.chosenProduct.telco?.replace('_data', '');

            await this.$recaptchaLoaded();
            const token = await this.$recaptcha('utilityPayment');

            this.$store
                .dispatch('utilities/paymentBill', {
                    customer_code: this.chosenProduct.productCode,
                    type_payment: 'phone',
                    type_category: trimmedTelco,
                    category: 'PHONE',
                    telco: this.chosenProduct.telco,
                    telcoServiceType: 'prepaid',
                    phoneNumber: this.phone,
                    billDetail: [
                        {
                            amount: this.chosenProduct.amount,
                        },
                    ],
                    token
                })
                .then((res) => {
                    this.$bvModal.hide('data-confirm');
                    if (res.status) {
                        this.$bvModal.hide('data-payment');
                        this.$store.dispatch('info/req_getInfo');
                    }
                })
                .finally(() => {
                    this.pending = false;
                });
        },
        async getCurrentRate() {
            if (this.chosenProduct === '') {
                return;
            }
            if (this.fetchingRate) {
                return;
            }
            this.fetchingRate = true;

            const res = await this.$store.dispatch(
                'wallet/getVNDToXIN',
                this.chosenProduct.amount,
            );
            if (res?.status) {
                this.VNDToXIN = res.data;
            }

            this.fetchingRate = false;
        },
        resetForm() {
            this.phone = '';
            this.chosenTelco = '';
        },
    },
    watch: {
        chosenTelco() {
            this.chosenProduct = '';
        },
        chosenProduct() {
            this.getCurrentRate();
        },
    },
};
</script>

<style lang="scss" scoped>
.mi-head {
    font-size: 24px;
    font-weight: 600;
    color: #0087cc;
    text-align: center;

    &.fail {
        color: #cc0000;
    }
    @media (min-width: 768px) {
        font-size: 30px;
    }
}
.mi-confirm {
    font-size: 18px;
    text-align: center;
    padding: 15px 0px;
}
.botans {
    display: flex;
    align-items: center;
    justify-content: space-around;
    gap: 10px;
    .botan {
        color: #0087cc;
        border: 1px solid currentColor;
        border-radius: 100vw;
        padding: 8px 15px;
        font-size: 20px;
        font-weight: 700;
        flex-grow: 1;
        &.confirm {
            color: #fff;
            background-color: #0087cc;
        }
    }
}
.confirm-value {
    padding: 15px 0px;
    > .item {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        font-size: 18px;
        padding: 2px 0px;
        .value {
            color: #0087cc;
        }
    }
}
.tip {
    color: #0087cc;
    font-size: 0.875em;
    &.red {
        color: #dc2626;
    }
}
.mini-detail {
    padding: 8px 0px;
    > div:not(.loader) {
        display: flex;
        justify-content: space-between;
        gap: 8px;
        padding: 4px 0px;
        > div:last-child {
            font-weight: bold;
            color: #0087cc;
        }
    }
    > .loader {
        text-align: center;
        color: #0087cc;
        font-size: 32px;
    }
}
</style>
