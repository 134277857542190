<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd"
      d="M11.9991 20.9494C7.05406 20.9494 3.03906 16.9344 3.03906 11.9894C3.03906 8.96045 4.54606 6.28045 6.84906 4.65845C7.18806 4.41945 7.26906 3.95145 7.03106 3.61345C6.79306 3.27445 6.32406 3.19345 5.98606 3.43145C3.29706 5.32545 1.53906 8.45345 1.53906 11.9894C1.53906 17.7624 6.22606 22.4494 11.9991 22.4494C12.4131 22.4494 12.7491 22.1134 12.7491 21.6994C12.7491 21.2854 12.4131 20.9494 11.9991 20.9494Z"
      fill="currentColor" />
    <path fill-rule="evenodd" clip-rule="evenodd"
      d="M12.5775 3.04733C17.2535 3.34533 20.9595 7.23833 20.9595 11.9893C20.9595 14.7203 19.7355 17.1663 17.8065 18.8103C17.4915 19.0793 17.4535 19.5533 17.7225 19.8683C17.9905 20.1833 18.4645 20.2213 18.7795 19.9523C21.0315 18.0333 22.4595 15.1763 22.4595 11.9893C22.4595 6.44233 18.1325 1.89833 12.6725 1.55033C12.2595 1.52433 11.9025 1.83833 11.8765 2.25133C11.8505 2.66433 12.1645 3.02133 12.5775 3.04733Z"
      fill="currentColor" />
    <path fill-rule="evenodd" clip-rule="evenodd"
      d="M19.7909 19.1835H18.0469V17.4395C18.0469 17.0255 17.7109 16.6895 17.2969 16.6895C16.8829 16.6895 16.5469 17.0255 16.5469 17.4395V19.9335C16.5469 20.3475 16.8829 20.6835 17.2969 20.6835H19.7909C20.2049 20.6835 20.5409 20.3475 20.5409 19.9335C20.5409 19.5195 20.2049 19.1835 19.7909 19.1835Z"
      fill="currentColor" />
    <path fill-rule="evenodd" clip-rule="evenodd"
      d="M4.78906 3.91211H6.53206V5.65511C6.53206 6.06911 6.86806 6.40511 7.28206 6.40511C7.69606 6.40511 8.03206 6.06911 8.03206 5.65511V3.16211C8.03206 2.74811 7.69706 2.41211 7.28206 2.41211H4.78906C4.37506 2.41211 4.03906 2.74811 4.03906 3.16211C4.03906 3.57611 4.37506 3.91211 4.78906 3.91211Z"
      fill="currentColor" />
    <path fill-rule="evenodd" clip-rule="evenodd"
      d="M12.2891 6.24414C9.11506 6.24414 6.53906 8.82114 6.53906 11.9941C6.53906 15.1681 9.11506 17.7441 12.2891 17.7441C15.4621 17.7441 18.0391 15.1681 18.0391 11.9941C18.0391 8.82114 15.4621 6.24414 12.2891 6.24414ZM11.2891 9.24414V11.7441C11.2891 11.9431 11.3681 12.1341 11.5091 12.2751L13.5091 14.2751C13.8011 14.5671 14.2771 14.5671 14.5691 14.2751C14.8621 13.9821 14.8621 13.5071 14.5691 13.2141L12.7891 11.4341V9.24414C12.7891 8.83014 12.4531 8.49414 12.0391 8.49414C11.6251 8.49414 11.2891 8.83014 11.2891 9.24414Z"
      fill="currentColor" />
  </svg>
</template>
<script>
export default {
    name: 'ActivityIcon'
}
</script>
