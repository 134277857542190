<script>
import { mapGetters } from 'vuex';
import Paginate from '@/components/shared/Paginate.vue';
import TableStatistical from '@/components/shared/TableStatistical.vue';
import MemberList from '../member/MemberList.vue';

export default {
    components: {
        Paginate,
        MemberList,
        TableStatistical
    },
    data() {
        return {
            search: {
                keyword: '',
                page: 1,
                address: null,
                f: ''
            },
            fields: [
                {
                    key: 'refId',
                    sortable: true,
                    label: 'refID',
                    class: 'text-center',
                },
                {
                    key: 'email',
                    sortable: true,
                    label: 'email',
                    class: 'text-center',
                },
                {
                    key: 'largestNode',
                    sortable: false,
                    label: 'Largest Node',
                    class: 'text-center',
                },
                {
                    key: 'totalNode',
                    sortable: false,
                    label: 'Total Node',
                    class: 'text-center',
                },
                {
                    key: 'totalPrice',
                    sortable: false,
                    label: 'Total Sales',
                    class: 'text-center',
                },
                // {
                //     key: 'ref',
                //     label: 'Referral',
                //     sortable: false,
                //     class: 'text-center',
                // },
                {
                    key: 'sponsor',
                    sortable: true,
                    label: 'sponsor',
                    class: 'text-center',
                },
                {
                    key: 'f',
                    sortable: true,
                    label: 'f',
                    class: 'text-center',
                },
                {
                    key: 'createdAt',
                    sortable: true,
                    label: 'registerDate',
                },
            ],
            sortBy: 'sponsor',
            sortDesc: false,
            mobileFields: [
                {
                    key: 'refId',
                    sortable: true,
                    label: 'refID',
                    class: 'text-left',
                },
                {
                    key: 'largestNode',
                    sortable: false,
                    label: 'Largest Node',
                    class: 'text-center',
                },
                {
                    key: 'totalNode',
                    sortable: false,
                    label: 'Total Node',
                    class: 'text-center',
                },
                {
                    key: 'f',
                    sortable: true,
                    label: 'f',
                    class: 'text-center',
                },
                {
                    key: '#',
                    label: 'action',
                    class: 'text-center',
                },
            ],
            currentPage: 1,
            totalPages: 10,
            renderComponent: true,
            options2: {
                useEasing: true,
                useGrouping: true,
                separator: ',',
                decimal: '.',
                prefix: '',
                suffix: ' USDT',
                decimalPlaces: 0,
            },
            delay: 2000,
            staticUser: {
                member: 0,
                usdt: 0,
            },
            comQuery: {
                page: 1,
            },
            xNodeFields: [
                {
                    key: 'name',
                    label: 'name',
                    sortable: true,
                },
                {
                    key: 'performance',
                    label: 'performance',
                    sortable: true,
                },
                {
                    key: 'expired',
                    label: 'expiryDate',
                    sortable: true,
                },
                {
                    key: 'status',
                    label: 'status',
                    sortable: true,
                },
                {
                    key: 'moreInfo',
                    label: 'more',
                    class: 'text-right',
                },
            ],
            comFields: [
                {
                    key: 'amount',
                    label: 'amount',
                    class: 'text-center',
                },
                {
                    key: 'currency',
                    label: 'currency',
                    class: 'text-center',
                },
                {
                    key: 'createdAt',
                    label: 'createdDate',
                    class: 'text-center',
                },
                {
                    key: '#',
                    label: 'more',
                    class: 'text-center',
                },
            ],
            // option: {
            //     title: {
            //         text: this.$i18n.t('commissionChart'),
            //         left: 'center',
            //         textStyle: {
            //             fontFamily: 'Inter',
            //             color: '#fff',
            //         },
            //         show: false,
            //     },
            //     tooltip: {
            //         trigger: 'item',
            //         formatter: ({ name, value, percent, seriesName }) => {
            //             return `${seriesName} <br /> ${name}: ${this.TruncateToDecimals2(
            //                 value,
            //             )} USDT (${percent}%)`;
            //         },
            //     },
            //     legend: {
            //         orient: 'horizontal',
            //         left: 'left',
            //         bottom: 'bottom',
            //         textStyle: {
            //             fontFamily: 'Inter',
            //             color: '#fff',
            //             fontSize: '10px'
            //         },
            //     },
            // },
        };
    },
    computed: {
        url() {
            return `${window.location.hostname}/register?sponsor=${this.UserInfo.refId}`;
        },
        ...mapGetters({
            Member: 'member/Member',
            CommissionHistory: 'member/CommissionHistory',
            Statistic: 'member/Statistic',
            MaxOut: 'mining/MaxOut',
            TotalFSale: 'member/TotalFSale',
            TotalGroupMint: 'member/TotalGroupMint',
            TotalGroupSale: 'member/TotalGroupSale',
            MaxoutChart: 'member/MaxoutChart',
            NodeStat: 'mining/NodeStat',
            UserInfo: 'info/UserInfo',
            Rates: 'wallet/Rates',
            XNode: 'info/XNode',
            UserNode: 'mining/UserNode',
            GroupStat: 'member/GroupStat',
        }),

        maxUserNode() {
            if (
                !this.UserNode ||
                !this.UserNode.results ||
                this.UserNode.results.length === 0
            ) {
                return null; // or return a default value
            }
            let maxNode = this.UserNode.results[0];

            for (const node of this.UserNode.results) {
                if (node.nodeInfo.priceUSDT > maxNode.nodeInfo.priceUSDT) {
                    maxNode = node;
                } else if (
                    node.nodeInfo.priceUSDT === maxNode.nodeInfo.priceUSDT
                ) {
                    if (
                        node.nodeInfo.performance > maxNode.nodeInfo.performance
                    ) {
                        maxNode = node;
                    }
                }
            }

            return maxNode;
        },

        Option() {
            return {
                title: {
                    text: this.$i18n.t('commissionChart'),
                    left: 'center',
                    textStyle: {
                        fontFamily: 'Inter',
                        color: '#fff',
                    },
                },
                tooltip: {
                    trigger: 'item',
                    formatter: ({ name, value, percent, seriesName }) => {
                        return `${seriesName} (USDT) <br /> ${name}: ${this.TruncateToDecimals2(
                            value,
                        )} USDT (${percent}%)`;
                    },
                },
                legend: {
                    orient: 'horizontal',
                    left: 'left',
                    bottom: 'bottom',
                    textStyle: {
                        fontFamily: 'Inter',
                        color: '#fff',
                        fontSize: '10px',
                    },
                    itemStyle: {
                        color: 'inherit',
                    },
                },
                color: ['#6dceff', '#fff'],
            };
        },
        MyReference() {
            return Object.entries(this.Member.refs);
        },
        BuyNodeCommissionsUSDT() {
            return this.findCommissions('comm_buy_node', 'USDT');
        },
        BuyNodeXinCommissions() {
            return this.findCommissions('comm_buy_node', 'xinCoin');
        },
        BuyNodeIncomeCommissions() {
            return this.findCommissions('comm_buy_node_incom', 'USDT');
        },
        BuyNodeXinIncomeCommissions() {
            return this.findCommissions('comm_buy_node_incom', 'xinCoin');
        },
        ClaimCommissions() {
            const xinCoM = this.Member.commissions.find(
                (el) =>
                    el._id.type === 'comm_claim' &&
                    el._id.currency === 'xinCoin',
            );
            const bXinCoM = this.Member.commissions.find(
                (el) =>
                    el._id.type === 'comm_claim' &&
                    el._id.currency === 'bxinCoin',
            );
            const toOah = (xinCoM?.total ?? 0) + (bXinCoM?.total ?? 0);
            return {
                total: toOah,
            };
        },
        ClaimIncomeCommissions() {
            const xinCoM = this.Member.commissions.find(
                (el) =>
                    el._id.type === 'comm_claim_incom' &&
                    el._id.currency === 'xinCoin',
            );
            const bXinCoM = this.Member.commissions.find(
                (el) =>
                    el._id.type === 'comm_claim_incom' &&
                    el._id.currency === 'bxinCoin',
            );
            const toOah = (xinCoM?.total ?? 0) + (bXinCoM?.total ?? 0);
            return {
                total: toOah,
            };
        },
        ComputedData() {
            // eslint-disable-next-line prefer-destructuring
            let MaxOut = Number(this.MaxOut);
            const data = [];

            if (
                this.BuyNodeCommissionsUSDT &&
                this.BuyNodeCommissionsUSDT.total
            ) {
                data.push({
                    value: this.BuyNodeCommissionsUSDT.total,
                    name: this.$i18n.t('directBuyCoM'),
                    itemStyle: {
                        color: '#5589B9',
                    },
                });
                MaxOut -= this.BuyNodeCommissionsUSDT.total;
            }

            if (
                this.BuyNodeIncomeCommissions &&
                this.BuyNodeIncomeCommissions.total
            ) {
                data.push({
                    value: this.BuyNodeIncomeCommissions.total,
                    name: this.$i18n.t('indirectBuyCoM'),
                });
                MaxOut -= this.BuyNodeIncomeCommissions.total;
            }

            if (
                this.BuyNodeXinCommissions &&
                this.BuyNodeXinCommissions.total
            ) {
                data.push({
                    value: this.BuyNodeXinCommissions.total,
                    name: this.$i18n.t('directBuyXinCoM'),
                });
                MaxOut -= this.BuyNodeXinCommissions.total;
            }

            if (
                this.BuyNodeXinIncomeCommissions &&
                this.BuyNodeXinIncomeCommissions.total
            ) {
                data.push({
                    value: this.BuyNodeXinIncomeCommissions.total,
                    name: this.$i18n.t('indirectBuyXinCoM'),
                });
                MaxOut -= this.BuyNodeXinIncomeCommissions.total;
            }

            if (MaxOut > 0) {
                data.push({
                    value: MaxOut,
                    name: this.$i18n.t('remainingCommission'),
                    itemStyle: {
                        color: '#78E8F7',
                        label: false,
                    },
                });
            }

            if (this.MaxOut) {
                return data;
            }
            return [];
        },
        TotalCommission() {
            return this.MaxoutChart?.totalCommission ?? 0;
        },
        RemainingCommission() {
            return this.MaxoutChart
                ? this.MaxoutChart.totalMaxout -
                this.MaxoutChart.totalCommission
                : 0;
        },
        ComputedOptions() {
            return {
                ...this.Option,
                series: [
                    {
                        name: this.$i18n.t('commissions'),
                        type: 'pie',
                        radius: '60%',
                        data: [
                            {
                                value: this.TotalCommission,
                                name: this.$t('commission'),
                            },
                            {
                                value: this.RemainingCommission,
                                name: this.$t('remainingCommission'),
                            },
                        ],
                        label: {
                            show: false,
                        },
                        emphasis: {
                            itemStyle: {
                                shadowBlur: 10,
                                shadowOffsetX: 0,
                                shadowColor: 'rgba(0, 0, 0, 0.5)',
                            },
                        },
                    },
                ],
            };
        },
        DirectStakingCommission() {
            return this.findCommissions('commission_staking', 'xinCoin');
        },
        DirectCommission() {
            return (
                (this.BuyNodeCommissionsUSDT?.total ?? 0) +
                (this.DirectStakingCommission?.amount ?? 0)
            );
        },
    },
    methods: {
        sortCompare(a, b, key) {
            //call api
            console.log(a, b, key);
        },
        onCopy() {
            this.$toastr.s('Copy Link Referral Success', 'Successfully');
        },
        CopyAddress(string) {
            if (window.navigator.clipboard) {
                window.navigator.clipboard
                    .writeText(string)
                    .then(() => {
                        this.$toastr.s('Address copied', 'Successfully');
                    })
                    .catch(() => {
                        this.$toastr.e('Failed to copy', 'Error');
                    });
            } else {
                this.$toastr.e('Failed to copy', 'Error');
            }
        },
        onError() {
            this.$toastr.e(
                'Copy Link Referral Fail,Please Try Again',
                'Failed',
            );
        },
        onSearch(type) {
            if (type === 'reset') {
                this.search = {
                    keyword: '',
                    page: 1,
                };
            }

            this.search.page = 1;
            this.$store.dispatch('member/req_getListMember', this.search);
            this.forceRerender();
        },
        onChangePage(payload) {
            this.search.page = payload;
            this.$store.dispatch('member/req_getListMember', this.search);
        },
        forceRerender() {
            // Remove my-component from the DOM
            this.renderComponent = false;

            this.$nextTick(() => {
                // Add the component back in
                this.renderComponent = true;
            });
        },
        async StatisUser() {
            const { register } = this.$store.state.contract.managers;
            const { address } = this.UserInfo;
            if (!register.cont) return;
            const data = await register.cont.methods
                .countReferral(address)
                .call();
            if (data) {
                console.log(data);
                this.staticUser.member = data;
                this.staticUser.usdt = this.TruncateToDecimals2(
                    parseInt(data, 0) * 2.5,
                    '',
                    4,
                );
            }
        },
        changeComPage(payload) {
            this.comQuery.page = payload;
            this.$store.dispatch(
                'member/req_getCommissionHistory',
                this.comQuery,
            );
        },
        findCommissions(type, currency) {
            // elegent code ?
            // let amount = 0
            // const commission = this.Member.commissions.find(
            //     (el) => el._id.type === type && el._id.currency === currency
            // );
            // if(commission) amount = commission.total
            // return amount

            // shitty code ?
            const commission = this.Member.commissions.find(
                (el) => el._id.type === type && el._id.currency === currency,
            );
            if (commission) {
                console.log('my comm', commission);
                return commission;
            }
            return {
                _id: {
                    type,
                    currency,
                },
                total: 0,
            };
        },
        updateGroupStat() {
            this.$store.dispatch('member/reqTotalGroupStat', { refresh: true });
        },
    },
    // watch: {
    //     UserInfo: {
    //         handler() {
    //             this.$store.dispatch('member/req_getListMember', {
    //                 // address: newVal.address,
    //             });
    //             // this.StatisUser();
    //             this.$store.dispatch('member/req_getDownline');
    //             this.$store.dispatch('member/req_getStatistical');
    //             // this.search.address = newVal.address;
    //             this.$store.dispatch('member/req_getCommissionStat');
    //             this.$store.dispatch(
    //                 'member/req_getCommissionHistory',
    //                 this.comQuery,
    //             );
    //         },
    //     },
    // },
    mounted() {
        const self = this;
        if (self.UserInfo) {
            // this.$store.dispatch('member/req_getListMember', {
            //     // address: self.UserInfo.address,
            // });
            this.$store.dispatch('member/req_getDownline');
            this.$store.dispatch('member/req_getStatistical');
            this.$store.dispatch('member/req_getCommissionStat');
            this.$store.dispatch(
                'member/req_getCommissionHistory',
                this.comQuery,
            );
            // if (this.UserInfo.address) {
            //     this.StatisUser();
            // }
        }
        this.$store.dispatch('mining/max_out');
        this.$store.dispatch('member/reqTotalFSale');
        this.$store.dispatch('member/reqChartData');
        this.$store.dispatch('member/reqTotalGroupStat');
    },
};
</script>

<template>
    <b-container class="member-tab">
        <div class="statistical">
            <div class="d-flex align-items-center mb-2">
                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
                    <path
                        d="M13.125 20H8.75C7.42392 20 6.15215 19.4732 5.21447 18.5355C4.27678 17.5979 3.75 16.3261 3.75 15C3.75 13.6739 4.27678 12.4021 5.21447 11.4645C6.15215 10.5268 7.42392 10 8.75 10H13.125C13.2908 10 13.4497 9.93415 13.5669 9.81694C13.6842 9.69973 13.75 9.54076 13.75 9.375C13.75 9.20924 13.6842 9.05027 13.5669 8.93306C13.4497 8.81585 13.2908 8.75 13.125 8.75H8.75C7.0924 8.75 5.50269 9.40848 4.33058 10.5806C3.15848 11.7527 2.5 13.3424 2.5 15C2.5 16.6576 3.15848 18.2473 4.33058 19.4194C5.50269 20.5915 7.0924 21.25 8.75 21.25H13.125C13.2908 21.25 13.4497 21.1842 13.5669 21.0669C13.6842 20.9497 13.75 20.7908 13.75 20.625C13.75 20.4592 13.6842 20.3003 13.5669 20.1831C13.4497 20.0658 13.2908 20 13.125 20ZM10 15C10 15.1658 10.0658 15.3247 10.1831 15.4419C10.3003 15.5592 10.4592 15.625 10.625 15.625H19.375C19.5408 15.625 19.6997 15.5592 19.8169 15.4419C19.9342 15.3247 20 15.1658 20 15C20 14.8342 19.9342 14.6753 19.8169 14.5581C19.6997 14.4408 19.5408 14.375 19.375 14.375H10.625C10.4592 14.375 10.3003 14.4408 10.1831 14.5581C10.0658 14.6753 10 14.8342 10 15ZM21.25 8.75H16.875C16.7092 8.75 16.5503 8.81585 16.4331 8.93306C16.3158 9.05027 16.25 9.20924 16.25 9.375C16.25 9.54076 16.3158 9.69973 16.4331 9.81694C16.5503 9.93415 16.7092 10 16.875 10H21.25C22.5761 10 23.8479 10.5268 24.7855 11.4645C25.7232 12.4021 26.25 13.6739 26.25 15C26.25 16.3261 25.7232 17.5979 24.7855 18.5355C23.8479 19.4732 22.5761 20 21.25 20H16.875C16.7092 20 16.5503 20.0658 16.4331 20.1831C16.3158 20.3003 16.25 20.4592 16.25 20.625C16.25 20.7908 16.3158 20.9497 16.4331 21.0669C16.5503 21.1842 16.7092 21.25 16.875 21.25H21.25C22.9076 21.25 24.4973 20.5915 25.6694 19.4194C26.8415 18.2473 27.5 16.6576 27.5 15C27.5 13.3424 26.8415 11.7527 25.6694 10.5806C24.4973 9.40848 22.9076 8.75 21.25 8.75Z"
                        fill="#0087CC" />
                    <defs>
                        <linearGradient id="paint0_linear_57_1580" x1="2.5" y1="15" x2="27.5" y2="15"
                            gradientUnits="userSpaceOnUse">
                            <stop offset="0.48" stop-color="#00D1FF" />
                            <stop offset="1" stop-color="#0087C8" />
                        </linearGradient>
                    </defs>
                </svg>
                <div class="title p-0 pl-2">{{ $t('referralLink') }}</div>
            </div>
            <b-row class="overflow-hidden mx-n1">
                <b-col cols="12" sm="6" lg="3" class="my-1 px-1" v-if="BuyNodeCommissionsUSDT">
                    <div class="box-statistical">
                        <div class="text">
                            <div class="text-title">
                                {{ $t('directBuyCoM') }} (USDT)
                            </div>
                            <div class="text-content">
                                {{ TruncateToDecimals2(DirectCommission) }}
                                USDT
                            </div>
                        </div>
                    </div>
                </b-col>
                <b-col cols="12" sm="6" lg="3" class="my-1 px-1" v-if="BuyNodeIncomeCommissions">
                    <div class="box-statistical">
                        <div class="text">
                            <div class="text-title">
                                {{ $t('indirectBuyCoM') }} (USDT)
                            </div>
                            <div class="text-content">
                                {{
                                    TruncateToDecimals2(
                                        BuyNodeIncomeCommissions.total,
                                    )
                                }}
                                USDT
                            </div>
                        </div>
                    </div>
                </b-col>
                <b-col cols="12" sm="6" lg="3" class="my-1 px-1" v-if="BuyNodeXinCommissions">
                    <div class="box-statistical">
                        <div class="text">
                            <div class="text-title">
                                {{ $t('directBuyCoM') }} (XIN)
                            </div>
                            <div class="text-content">
                                {{
                                    TruncateToDecimals2(
                                        BuyNodeXinCommissions.total,
                                    )
                                }}
                                XIN
                            </div>
                        </div>
                    </div>
                </b-col>
                <b-col cols="12" sm="6" lg="3" class="my-1 px-1" v-if="BuyNodeXinIncomeCommissions">
                    <div class="box-statistical">
                        <div class="text">
                            <div class="text-title">
                                {{ $t('indirectBuyCoM') }} (XIN)
                            </div>
                            <div class="text-content">
                                {{
                                    TruncateToDecimals2(
                                        BuyNodeXinIncomeCommissions.total,
                                    )
                                }}
                                XIN
                            </div>
                        </div>
                    </div>
                </b-col>
                <b-col cols="12" sm="6" lg="3" class="my-1 px-1" v-if="ClaimCommissions">
                    <div class="box-statistical">
                        <div class="text">
                            <div class="text-title">
                                {{ $t('directClaimCoM') }}
                            </div>
                            <div class="text-content">
                                <div>
                                    {{
                                        TruncateToDecimals2(
                                            ClaimCommissions.total,
                                        )
                                    }}
                                    XIN
                                </div>
                                <!-- <div>
                                    ~
                                    {{
                                        TruncateToDecimals2(
                                            ClaimCommissions.amount,
                                        )
                                    }}
                                    USDT
                                </div> -->
                            </div>
                        </div>
                    </div>
                </b-col>
                <b-col cols="12" sm="6" lg="3" class="my-1 px-1" v-if="ClaimIncomeCommissions">
                    <div class="box-statistical">
                        <div class="text">
                            <div class="text-title">
                                {{ $t('indirectClaimCoM') }}
                            </div>
                            <div class="text-content">
                                <div>
                                    {{
                                        TruncateToDecimals2(
                                            ClaimIncomeCommissions.total,
                                        )
                                    }}
                                    XIN
                                </div>
                                <!-- <div>
                                ~
                                    {{
                                        TruncateToDecimals2(
                                            ClaimIncomeCommissions.usdt,
                                        )
                                    }}
                                    USDT
                                </div> -->
                            </div>
                        </div>
                    </div>
                </b-col>
                <b-col cols="12" sm="6" lg="3" class="my-1 px-1">
                    <div class="box-statistical">
                        <div class="text">
                            <div class="text-title">
                                {{ $t('maxoutCoM') }}
                            </div>
                            <div class="text-content">
                                {{ TruncateToDecimals2(MaxOut) }} USDT
                            </div>
                        </div>
                    </div>
                </b-col>
                <b-col cols="12" sm="6" lg="3" class="my-1 px-1">
                    <div class="box-statistical">
                        <div class="text">
                            <div class="text-title">
                                {{ $t('remainingCommission') }}
                            </div>
                            <div class="text-content">
                                {{ TruncateToDecimals2(RemainingCommission) }}
                                USDT
                            </div>
                        </div>
                    </div>
                </b-col>
            </b-row>
            <div class="row member mx-n1">
                <div class="col-lg-8 px-1 my-1">
                    <div class="mining-header">
                        <div class="mining-text">
                            <div>
                                {{ $t('totalHzGlobal') }}:
                                <span>{{
                                    TruncateToDecimals2(
                                        NodeStat.totalHZGlobal,
                                    )
                                }}
                                    Hz</span>
                            </div>
                            <div>
                                {{ $t('totalXINMined') }}:
                                <span>{{
                                    TruncateToDecimals2(
                                        NodeStat.totalXinMined,
                                    )
                                }}
                                    XIN</span>
                            </div>
                            <div>
                                {{ $t('total10KNode') }}:
                                <span>{{
                                    TruncateToDecimals2(
                                        NodeStat.totalNodeTenThousand,
                                    )
                                }}
                                    / 200
                                </span>
                            </div>
                            <div>
                                {{ $t('total20kNode') }}:
                                <span>{{
                                    TruncateToDecimals2(
                                        NodeStat.totalNodeTwentyThousand,
                                    )
                                }}
                                    / 100
                                </span>
                            </div>
                            <div>
                                {{ $t('onePercentTotalSales') }}:
                                <span>
                                    <span>{{
                                        TruncateToDecimals2(
                                            NodeStat.OnePercentSales,
                                        )
                                    }}
                                        USDT
                                    </span>
                                    <span v-if="Rates[0]">~
                                        {{
                                            TruncateToDecimals2(
                                                NodeStat.OnePercentSales *
                                                Rates[0].rate,
                                            )
                                        }}
                                        XIN</span>
                                </span>
                            </div>
                            <div>
                                {{ $t('onePercentTotalSalesXin') }}:
                                <span>
                                    <span>{{
                                        TruncateToDecimals2(
                                            NodeStat.OnePercentSalesXin,
                                        )
                                    }}
                                        XIN
                                    </span>
                                </span>
                            </div>
                            <div>
                                {{ $t('twoPercentTotalSales') }}:
                                <span>
                                    <span>{{
                                        TruncateToDecimals2(
                                            NodeStat.twoPercentSales,
                                        )
                                    }}
                                        USDT
                                    </span>
                                    <span v-if="Rates[0]">~
                                        {{
                                            TruncateToDecimals2(
                                                NodeStat.twoPercentSales *
                                                Rates[0].rate,
                                            )
                                        }}
                                        XIN</span>
                                </span>
                            </div>
                            <div>
                                {{ $t('twoPercentTotalSalesXin') }}:
                                <span>
                                    <span>{{
                                        TruncateToDecimals2(
                                            NodeStat.twoPercentSalesXin,
                                        )
                                    }}
                                        XIN
                                    </span>
                                </span>
                            </div>
                        </div>
                        <div class="cta">
                            <img class="node" src="@/assets/images/mockup/node.png" />
                            <RouterLink class="changepage" v-if="$route.name === 'BuyXnode'"
                                :to="{ name: 'XnodeInventory' }">
                                {{ $t('myXNode') }}
                                <svg width="30" height="30" viewBox="0 0 30 30" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="15" cy="15" r="14.5" stroke="currentColor" />
                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                        d="M7.40127 15.0001C7.40127 15.144 7.45846 15.2821 7.56024 15.3838C7.66203 15.4856 7.80009 15.5428 7.94404 15.5428L20.7456 15.5428L17.3295 18.9579C17.2276 19.0598 17.1703 19.198 17.1703 19.3422C17.1703 19.4863 17.2276 19.6245 17.3295 19.7264C17.4314 19.8284 17.5696 19.8856 17.7138 19.8856C17.8579 19.8856 17.9961 19.8284 18.098 19.7264L22.4402 15.3843C22.4907 15.3339 22.5308 15.274 22.5582 15.2081C22.5855 15.1421 22.5996 15.0714 22.5996 15.0001C22.5996 14.9287 22.5855 14.858 22.5582 14.792C22.5308 14.7261 22.4907 14.6662 22.4402 14.6158L18.098 10.2737C18.0476 10.2232 17.9877 10.1832 17.9217 10.1559C17.8558 10.1286 17.7851 10.1145 17.7138 10.1145C17.5696 10.1145 17.4314 10.1718 17.3295 10.2737C17.2276 10.3756 17.1703 10.5138 17.1703 10.658C17.1703 10.8021 17.2276 10.9403 17.3295 11.0422L20.7456 14.4573L7.94404 14.4573C7.80009 14.4573 7.66203 14.5145 7.56024 14.6163C7.45846 14.7181 7.40127 14.8561 7.40127 15.0001Z"
                                        fill="currentColor" />
                                </svg>
                            </RouterLink>
                            <RouterLink class="changepage" v-else :to="{ name: 'BuyXnode' }">
                                {{ $t('buyXNode') }}
                                <svg width="30" height="30" viewBox="0 0 30 30" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="15" cy="15" r="14.5" stroke="currentColor" />
                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                        d="M7.40127 15.0001C7.40127 15.144 7.45846 15.2821 7.56024 15.3838C7.66203 15.4856 7.80009 15.5428 7.94404 15.5428L20.7456 15.5428L17.3295 18.9579C17.2276 19.0598 17.1703 19.198 17.1703 19.3422C17.1703 19.4863 17.2276 19.6245 17.3295 19.7264C17.4314 19.8284 17.5696 19.8856 17.7138 19.8856C17.8579 19.8856 17.9961 19.8284 18.098 19.7264L22.4402 15.3843C22.4907 15.3339 22.5308 15.274 22.5582 15.2081C22.5855 15.1421 22.5996 15.0714 22.5996 15.0001C22.5996 14.9287 22.5855 14.858 22.5582 14.792C22.5308 14.7261 22.4907 14.6662 22.4402 14.6158L18.098 10.2737C18.0476 10.2232 17.9877 10.1832 17.9217 10.1559C17.8558 10.1286 17.7851 10.1145 17.7138 10.1145C17.5696 10.1145 17.4314 10.1718 17.3295 10.2737C17.2276 10.3756 17.1703 10.5138 17.1703 10.658C17.1703 10.8021 17.2276 10.9403 17.3295 11.0422L20.7456 14.4573L7.94404 14.4573C7.80009 14.4573 7.66203 14.5145 7.56024 14.6163C7.45846 14.7181 7.40127 14.8561 7.40127 15.0001Z"
                                        fill="currentColor" />
                                </svg>
                            </RouterLink>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 px-1 my-1">
                    <div class="member-right">
                        <div class="member-title">
                            <h3>{{ $t('myMaxXNode') }}</h3>
                            <router-link :to="{ name: 'XnodeInventory' }">
                                {{ $t('myXNode') }}
                                <img src="../../assets/images/icons/arrow_right.png" alt="" />
                            </router-link>
                        </div>
                        <div class="member-right-content" v-if="maxUserNode">
                            <img :src="maxUserNode &&
                                maxUserNode.nodeInfo.photoUrl !== ''
                                ? maxUserNode.nodeInfo.photoUrl
                                : require('@/assets/images/mockup/node.png')
                                " alt="" />

                            <p class="text-1 m-0">
                                {{ maxUserNode.nodeInfo.name }}
                            </p>
                            <p class="text-2 m-0">
                                {{ maxUserNode.nodeInfo.performance }} Hz/h
                            </p>
                            <p class="text-3 m-0">
                                XIN
                                <span>
                                    {{
                                        TruncateToDecimals2(
                                            maxUserNode.nodeInfo.priceXin,
                                        )
                                    }}
                                    XIN
                                </span>
                            </p>
                            <p class="text-3">
                                USDT
                                <span>
                                    {{
                                        TruncateToDecimals2(
                                            maxUserNode.nodeInfo.priceUSDT,
                                        )
                                    }}
                                    USDT
                                </span>
                            </p>

                            <p class="text-3">
                                {{ $t('expiryDate') }}
                                <span>
                                    <!-- {{ XNode.usedSlot }}/ {{ XNode.nodeSlot }} slots -->
                                    {{ getDateTime3(maxUserNode.expired) }}
                                </span>
                            </p>
                        </div>
                        <div v-else class="no-data">No data</div>
                        <b-button class="buyXNode" @click="$router.push({ name: 'BuyXnode' })">
                            {{ $t('buyXNode') }}
                        </b-button>
                    </div>
                </div>
            </div>
            <div class="row my-1 mx-n1">
                <div class="px-1 my-1 col-lg-8">
                    <TableStatistical
                        :Statistic="Statistic"
                        :TotalFSale="TotalFSale"
                        :TotalGroupSale="TotalGroupSale"
                        :TotalGroupMint="TotalGroupMint"
                        :GroupStat="GroupStat"
                        :UserInfo="UserInfo"
                    />
                </div>
                <div class="px-1 my-1 col-lg-4">
                    <div class="chart member-chart">
                        <v-chart :option="ComputedOptions" :autoresize="true"></v-chart>
                    </div>
                </div>
            </div>
        </div>
        <MemberList/>

        <!-- <div class="table-container">
            <template>
                <h3>
                    {{ $t('XNodeList') }}
                    <svg v-if="UserInfo.mana < 10" xmlns="http://www.w3.org/2000/svg" width="1.2em" height="1.2em"
                        viewBox="0 0 256 256" class="warning" v-b-tooltip.hover :title="$t('manaTooLow')">
                        <path fill="currentColor"
                            d="M240.26 186.1L152.81 34.23a28.74 28.74 0 0 0-49.62 0L15.74 186.1a27.45 27.45 0 0 0 0 27.71A28.31 28.31 0 0 0 40.55 228h174.9a28.31 28.31 0 0 0 24.79-14.19a27.45 27.45 0 0 0 .02-27.71m-20.8 15.7a4.46 4.46 0 0 1-4 2.2H40.55a4.46 4.46 0 0 1-4-2.2a3.56 3.56 0 0 1 0-3.73L124 46.2a4.77 4.77 0 0 1 8 0l87.44 151.87a3.56 3.56 0 0 1 .02 3.73M116 136v-32a12 12 0 0 1 24 0v32a12 12 0 0 1-24 0m28 40a16 16 0 1 1-16-16a16 16 0 0 1 16 16" />
                    </svg>
                </h3>
                <div class="">
                    <b-table class="xnode-table" responsive striped hover :fields="xNodeFields"
                        :items="UserNode.results" @sort-changed="nodeSort">
                        <template #cell(name)="{ item }">
                            <div>
                                {{ item.nodeInfo.name }}
                            </div>
                            <div>
                                {{
                                    TruncateToDecimals2(item.nodeInfo.priceUSDT)
                                }}
                                USDT
                            </div>
                        </template>
                        <template #cell(performance)="{ item }">
                            {{ item.nodeInfo.performance }} Hz/h
                        </template>
                        <template #cell(expired)="{ value }">
                            {{ getDateTime3(value) }}
                        </template>
                        <template #cell(status)="{ value, item }">
                            <template v-if="item.actived || !item.promotionId">
                                <template v-if="UserInfo.mana < 10">
                                    <span class="status" v-b-tooltip.hover :title="$t('manaTooLow')">
                                        {{ $t('inactive') }}
                                        <svg xmlns="http://www.w3.org/2000/svg" width="1.2em" height="1.2em"
                                            viewBox="0 0 256 256">
                                            <path fill="currentColor"
                                                d="M240.26 186.1L152.81 34.23a28.74 28.74 0 0 0-49.62 0L15.74 186.1a27.45 27.45 0 0 0 0 27.71A28.31 28.31 0 0 0 40.55 228h174.9a28.31 28.31 0 0 0 24.79-14.19a27.45 27.45 0 0 0 .02-27.71m-20.8 15.7a4.46 4.46 0 0 1-4 2.2H40.55a4.46 4.46 0 0 1-4-2.2a3.56 3.56 0 0 1 0-3.73L124 46.2a4.77 4.77 0 0 1 8 0l87.44 151.87a3.56 3.56 0 0 1 .02 3.73M116 136v-32a12 12 0 0 1 24 0v32a12 12 0 0 1-24 0m28 40a16 16 0 1 1-16-16a16 16 0 0 1 16 16" />
                                        </svg>
                                    </span>
                                </template>
                                <template v-else>
                                    <span class="status" :class="{ active: value === 0 }">
                                        {{
                                            value === 1
                                                ? 'Expired'
                                                : value === -1
                                                    ? 'Canceled'
                                                    : 'Active'
                                        }}
                                    </span>
                                </template>
                            </template>
                            <template v-else-if="!item.actived">
                                <b-button v-if="!item.actived" size="sm" variant="success" @click="openSendModal(item)"
                                    class="px-2 py-0 mr-2">
                                    {{ $t('send') }}
                                </b-button>
                                <b-button v-if="!item.actived" size="sm" variant="success"
                                    @click="openActivationModal(item)" class="px-2 py-0">
                                    {{ $t('activate') }}
                                </b-button>
                            </template>
                        </template>
                        <template #cell(moreInfo)="row">
                            <img v-if="
                                row.item.promotionId === 1 ||
                                row.item.promotionId === 2
                            " width="30px" src="@/assets/images/icons/package.svg" />
                            <svg v-if="row.item.giftId" xmlns="http://www.w3.org/2000/svg" width="30px" height="30px"
                                viewBox="0 0 36 36" class="pr-1">
                                <path fill="#0087cc"
                                    d="M33 31c0 2.2-1.8 4-4 4H7c-2.2 0-4-1.8-4-4V14c0-2.2 1.8-4 4-4h22c2.2 0 4 1.8 4 4z" />
                                <path fill="#0087cc"
                                    d="M36 11c0 2.2-1.8 4-4 4H4c-2.2 0-4-1.8-4-4s1.8-4 4-4h28c2.2 0 4 1.8 4 4" />
                                <path fill="#005f8f" d="M3 15h30v2H3z" />
                                <path fill="#da2f47" d="M19 3h-2a3 3 0 0 0-3 3v29h8V6a3 3 0 0 0-3-3" />
                                <path fill="#da2f47"
                                    d="M16 7c1.1 0 1.263-.516.361-1.147L9.639 1.147a1.795 1.795 0 0 0-2.631.589L4.992 5.264C4.446 6.219 4.9 7 6 7zm4 0c-1.1 0-1.263-.516-.361-1.147l6.723-4.706a1.796 1.796 0 0 1 2.631.589l2.016 3.527C31.554 6.219 31.1 7 30 7z" />
                            </svg>
                            <b-button size="sm" variant="info" @click="row.toggleDetails" class="px-2 py-0">
                                {{
                                    row.detailsShowing ? $t('hide') : $t('show')
                                }}
                            </b-button>
                        </template>
                        <template #row-details="{ item }">
                            <div class="d-flex justify-content-between p-2 flex-wrap">
                                <div>{{ $t('nodeDuration') }}:</div>
                                <div>
                                    {{ item.nodeInfo.quantityUseMonth }}
                                    {{
                                        $tc(
                                            'month',
                                            item.nodeInfo.quantityUseMonth,
                                        )
                                    }}
                                </div>
                            </div>
                            <div class="d-flex justify-content-between p-2 flex-wrap">
                                <div>{{ $t('createdDate') }}:</div>
                                <div>{{ getDateTime3(item.createdAt) }}</div>
                            </div>
                            <div class="d-flex justify-content-between p-2 flex-wrap"
                                v-if="item.comment && item.comment.length > 0">
                                <div>{{ $t('comment') }}:</div>
                                <div>{{ item.comment }}</div>
                            </div>
                        </template>
                        <template #head()="data">
                            {{ $t(data.label) }}
                        </template>
                    </b-table>
                </div>
                <div class="d-lg-none">
                    <b-table
                        class="xnode-table"
                        responsive
                        striped
                        hover
                        :fields="mobileFields"
                        :items="UserNode.results"
                        @sort-changed="nodeSort"
                    >
                        <template #cell(name)="{ item }">
                            <div>
                                {{ item.nodeInfo.name }}
                            </div>
                            <div>
                                {{
                                    TruncateToDecimals2(item.nodeInfo.priceUSDT)
                                }}
                                USDT
                            </div>
                        </template>
                        <template #cell(performance)="{ item }">
                            {{ item.nodeInfo.performance }} Hz/h
                        </template>
                        <template #cell(moreInfo)="row">
                            <img
                                v-if="
                                    row.item.promotionId === 1 ||
                                    row.item.promotionId === 2
                                "
                                width="30px"
                                src="@/assets/images/icons/package.svg"
                            />
                            <svg
                                v-if="row.item.giftId"
                                xmlns="http://www.w3.org/2000/svg"
                                width="30px"
                                height="30px"
                                viewBox="0 0 36 36"
                                class="pr-1"
                            >
                                <path
                                    fill="#0087cc"
                                    d="M33 31c0 2.2-1.8 4-4 4H7c-2.2 0-4-1.8-4-4V14c0-2.2 1.8-4 4-4h22c2.2 0 4 1.8 4 4z"
                                />
                                <path
                                    fill="#0087cc"
                                    d="M36 11c0 2.2-1.8 4-4 4H4c-2.2 0-4-1.8-4-4s1.8-4 4-4h28c2.2 0 4 1.8 4 4"
                                />
                                <path fill="#005f8f" d="M3 15h30v2H3z" />
                                <path
                                    fill="#da2f47"
                                    d="M19 3h-2a3 3 0 0 0-3 3v29h8V6a3 3 0 0 0-3-3"
                                />
                                <path
                                    fill="#da2f47"
                                    d="M16 7c1.1 0 1.263-.516.361-1.147L9.639 1.147a1.795 1.795 0 0 0-2.631.589L4.992 5.264C4.446 6.219 4.9 7 6 7zm4 0c-1.1 0-1.263-.516-.361-1.147l6.723-4.706a1.796 1.796 0 0 1 2.631.589l2.016 3.527C31.554 6.219 31.1 7 30 7z"
                                />
                            </svg>
                            <b-button
                                size="sm"
                                variant="info"
                                @click="row.toggleDetails"
                                class="px-2 py-0"
                            >
                                {{
                                    row.detailsShowing ? $t('hide') : $t('show')
                                }}
                            </b-button>
                        </template>
                        <template #row-details="{ item }">
                            <div
                                class="d-flex justify-content-between p-2 flex-wrap"
                            >
                                <div>{{ $t('nodeDuration') }}:</div>
                                <div>
                                    {{ item.nodeInfo.quantityUseMonth }}
                                    {{
                                        $t(
                                            'month',
                                            item.nodeInfo.quantityUseMonth,
                                        )
                                    }}
                                </div>
                            </div>
                            <div
                                class="d-flex justify-content-between p-2 flex-wrap"
                            >
                                <div>{{ $t('createdDate') }}:</div>
                                <div>{{ getDateTime3(item.createdAt) }}</div>
                            </div>
                            <div
                                class="d-flex justify-content-between p-2 flex-wrap"
                            >
                                <div>{{ $t('expiryDate') }}:</div>
                                <div>{{ getDateTime3(item.expired) }}</div>
                            </div>
                            <div
                                class="d-flex justify-content-between p-2 flex-wrap"
                                v-if="item.comment && item.comment.length > 0"
                            >
                                <div>{{ $t('comment') }}:</div>
                                <div>{{ item.comment }}</div>
                            </div>
                            <div
                                class="d-flex justify-content-between p-2 flex-wrap"
                                v-if="item.nodeInfo.performance"
                            >
                                <div>{{ $t('performance') }}:</div>
                                <div>{{ item.nodeInfo.performance }} Hz/h</div>
                            </div>
                            <div
                                class="d-flex justify-content-between p-2 flex-wrap"
                            >
                                <div>{{ $t('status') }}:</div>
                                <div>
                                    <template
                                        v-if="item.actived || !item.promotionId"
                                    >
                                        <template v-if="UserInfo.mana < 10">
                                            <span
                                                class="status"
                                                v-b-tooltip.hover
                                                :title="$t('manaTooLow')"
                                            >
                                                {{ $t('inactive') }}
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="1.2em"
                                                    height="1.2em"
                                                    viewBox="0 0 256 256"
                                                >
                                                    <path
                                                        fill="currentColor"
                                                        d="M240.26 186.1L152.81 34.23a28.74 28.74 0 0 0-49.62 0L15.74 186.1a27.45 27.45 0 0 0 0 27.71A28.31 28.31 0 0 0 40.55 228h174.9a28.31 28.31 0 0 0 24.79-14.19a27.45 27.45 0 0 0 .02-27.71m-20.8 15.7a4.46 4.46 0 0 1-4 2.2H40.55a4.46 4.46 0 0 1-4-2.2a3.56 3.56 0 0 1 0-3.73L124 46.2a4.77 4.77 0 0 1 8 0l87.44 151.87a3.56 3.56 0 0 1 .02 3.73M116 136v-32a12 12 0 0 1 24 0v32a12 12 0 0 1-24 0m28 40a16 16 0 1 1-16-16a16 16 0 0 1 16 16"
                                                    />
                                                </svg>
                                            </span>
                                        </template>
                                        <template v-else>
                                            <span
                                                class="status"
                                                :class="{ active: value === 0 }"
                                            >
                                                {{
                                                    value === 1
                                                        ? 'Expired'
                                                        : value === -1
                                                        ? 'Canceled'
                                                        : 'Active'
                                                }}
                                            </span>
                                        </template>
                                    </template>
                                    <template v-else-if="!item.actived">
                                        <b-button
                                            v-if="!item.actived"
                                            size="sm"
                                            variant="success"
                                            @click="openSendModal(item)"
                                            class="px-2 py-0 mr-2"
                                        >
                                            {{ $t('send') }}
                                        </b-button>
                                        <b-button
                                            v-if="!item.actived"
                                            size="sm"
                                            variant="success"
                                            @click="openActivationModal(item)"
                                            class="px-2 py-0"
                                        >
                                            {{ $t('activate') }}
                                        </b-button>
                                    </template>
                                </div>
                            </div>
                        </template>
                        <template #head()="data">
                            {{ $t(data.label) }}
                        </template>
                    </b-table>
                </div>
                <Paginate
                    @current="nodePaging"
                    :totalPages="UserNode.totalPages"
                />
            </template>
            <template v-if="
                UserGift && UserGift.results && UserGift.results.length > 0
            ">
                <h3 class="pt-4">{{ $t('giftList') }}</h3>
                <div class="d-none d-lg-block">
                    <b-table class="xnode-table" responsive striped hover :fields="fields" :items="UserGift.results">
                        <template #cell(name)="{ item }">
                            <div>
                                {{ item.nodeInfo.name }}
                            </div>
                            <div>
                                {{
                                    TruncateToDecimals2(item.nodeInfo.priceUSDT)
                                }}
                                USDT
                            </div>
                        </template>
                        <template #cell(performance)="{ item }">
                            {{ item.nodeInfo.performance }} Hz/h
                        </template>
                        <template #cell(expired)="{ value }">
                            {{ getDateTime3(value) }}
                        </template>
                        <template #cell(status)="{ item, value }">
                            <b-button v-if="value === 0" size="sm" variant="success" @click="openGiftModal(item)"
                                class="px-2 py-0">
                                {{ $t('gift') }}
                            </b-button>
                            <span v-else-if="value === 2" class="text-success font-weight-bold">
                                {{ $t('sent') }}
                            </span>
                            <span v-else-if="value === 1" class="text-danger font-weight-bold">
                                {{ $t('expired') }}
                            </span>
                            <span v-else-if="value === -1" class="text-danger font-weight-bold">
                                {{ $t('canceled') }}
                            </span>
                        </template>
                        <template #cell(moreInfo)="row">
                            <b-button size="sm" variant="info" @click="row.toggleDetails" class="px-2 py-0">
                                {{
                                    row.detailsShowing ? $t('hide') : $t('show')
                                }}
                            </b-button>
                        </template>
                        <template #row-details="{ item }">
                            <div class="d-flex justify-content-between p-2 flex-wrap">
                                <div>{{ $t('nodeDuration') }}:</div>
                                <div>
                                    {{ item.nodeInfo.quantityUseMonth }}
                                    {{
                                        $tc(
                                            'month',
                                            item.nodeInfo.quantityUseMonth,
                                        )
                                    }}
                                </div>
                            </div>
                            <div class="d-flex justify-content-between p-2 flex-wrap">
                                <div>{{ $t('createdDate') }}:</div>
                                <div>{{ getDateTime3(item.createdAt) }}</div>
                            </div>
                            <div class="d-flex justify-content-between p-2 flex-wrap"
                                v-if="item.comment && item.comment.length > 0">
                                <div>{{ $t('comment') }}:</div>
                                <div>{{ item.comment }}</div>
                            </div>
                        </template>
                        <template #head()="data">
                            {{ $t(data.label) }}
                        </template>
                    </b-table>
                </div>
                <div class="d-lg-none">
                    <b-table class="xnode-table" responsive striped hover :fields="mobileFields"
                        :items="UserGift.results">
                        <template #cell(name)="{ item }">
                            <div>
                                {{ item.nodeInfo.name }}
                            </div>
                            <div>
                                {{
                                    TruncateToDecimals2(item.nodeInfo.priceUSDT)
                                }}
                                USDT
                            </div>
                        </template>
                        <template #cell(performance)="{ item }">
                            {{ item.nodeInfo.performance }} Hz/h
                        </template>
                        <template #cell(status)="{ item, value }">
                            <b-button v-if="value === 0" size="sm" variant="success" @click="openGiftModal(item)"
                                class="px-2 py-0">
                                {{ $t('gift') }}
                            </b-button>
                            <span v-else-if="value === 2" class="text-success font-weight-bold">
                                {{ $t('sent') }}
                            </span>
                            <span v-else-if="value === 1" class="text-danger font-weight-bold">
                                {{ $t('expired') }}
                            </span>
                            <span v-else-if="value === -1" class="text-danger font-weight-bold">
                                {{ $t('canceled') }}
                            </span>
                        </template>
                        <template #cell(moreInfo)="row">
                            <b-button size="sm" variant="info" @click="row.toggleDetails" class="px-2 py-0">
                                {{
                                    row.detailsShowing ? $t('hide') : $t('show')
                                }}
                            </b-button>
                        </template>
                        <template #row-details="{ item }">
                            <div class="d-flex justify-content-between p-2 flex-wrap">
                                <div>{{ $t('nodeDuration') }}:</div>
                                <div>
                                    {{ item.nodeInfo.quantityUseMonth }}
                                    {{
                                        $tc(
                                            'month',
                                            item.nodeInfo.quantityUseMonth,
                                        )
                                    }}
                                </div>
                            </div>
                            <div class="d-flex justify-content-between p-2 flex-wrap">
                                <div>{{ $t('createdDate') }}:</div>
                                <div>{{ getDateTime3(item.createdAt) }}</div>
                            </div>
                            <div class="d-flex justify-content-between p-2 flex-wrap">
                                <div>{{ $t('expiryDate') }}:</div>
                                <div>{{ getDateTime3(item.expired) }}</div>
                            </div>
                            <div class="d-flex justify-content-between p-2 flex-wrap"
                                v-if="item.comment && item.comment.length > 0">
                                <div>{{ $t('comment') }}:</div>
                                <div>{{ item.comment }}</div>
                            </div>
                            <div class="d-flex justify-content-between p-2 flex-wrap" v-if="item.nodeInfo.performance">
                                <div>{{ $t('performance') }}:</div>
                                <div>{{ item.nodeInfo.performance }} Hz/h</div>
                            </div>
                            <div class="d-flex justify-content-between p-2 flex-wrap">
                                <div>{{ $t('status') }}:</div>
                                <div>
                                    <b-button v-if="item.status === 0" size="sm" variant="success"
                                        @click="openGiftModal(item)" class="px-2 py-0">
                                        {{ $t('gift') }}
                                    </b-button>
                                    <span v-else-if="item.status === 2" class="text-success font-weight-bold">
                                        {{ $t('sent') }}
                                    </span>
                                    <span v-else-if="item.status === 1" class="text-danger font-weight-bold">
                                        {{ $t('expired') }}
                                    </span>
                                    <span v-else-if="item.status === -1" class="text-danger font-weight-bold">
                                        {{ $t('canceled') }}
                                    </span>
                                </div>
                            </div>
                        </template>
                        <template #head()="data">
                            {{ $t(data.label) }}
                        </template>
                    </b-table>
                </div>
                <Paginate @current="giftPaging" :totalPages="UserGift.totalPages"></Paginate>
            </template>
        </div> -->

        <div class="com-history">
            <div class="title">{{ $t('commissionHistory') }}</div>
            <div class="table-responsive" v-if="CommissionHistory.results">
                <b-table striped hover :items="CommissionHistory.results" :fields="comFields" show-empty
                    thead-class="customer-header">
                    <template #empty>
                        <h4 class="text-center my-4">
                            {{ $t('noCommission') }}
                        </h4>
                    </template>
                    <template #cell(createdAt)="data">
                        <span>
                            {{ getDateTime3(data.value) }}
                        </span>
                    </template>
                    <template #cell(comment)="data">
                        <div class="comment">
                            {{ data.value }}
                        </div>
                    </template>
                    <template #cell(currency)="{ value }">
                        <img v-if="value === 'xinCoin' || value === 'bxinCoin'" src="@/assets/images/logo/xin.png"
                            width="30px" />
                        <img v-else src="@/assets/images/logo/usdt.png" width="30px" />
                    </template>
                    <template #cell(#)="row">
                        <b-button size="sm" variant="info" @click="row.toggleDetails" class="px-2 py-0">
                            {{ row.detailsShowing ? 'Hide' : 'Show' }}
                        </b-button>
                    </template>
                    <template #row-details="{ item }">
                        <div class="px-2 text-center">
                            {{ item.comment }}
                        </div>
                    </template>
                    <template #head()="data">
                        {{ $t(data.label) }}
                    </template>
                </b-table>
            </div>
            <div class="table-paginate">
                <Paginate v-if="CommissionHistory.results" @current="changeComPage"
                    :totalPages="CommissionHistory.totalPages" />
            </div>
        </div>
    </b-container>
</template>
<style lang="scss">
.member-tab {
    .header-icon {
        max-width: 55px;
        width: 100%;
        height: 100%;

        img {
            width: 100%;
            height: auto;
            object-fit: scale-down;
        }
    }

    .title {
        font-size: clamp(1.2em, 4vw, 1.8em);
        padding-bottom: 10px;
        position: relative;
        letter-spacing: 0.6px;
        width: max-content;
        // margin: 0 0 25px;
        font-weight: 600;
        background: #0087cc;

        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    .statistical {
        margin-top: 15px;
    }

    .member-list {
        margin-top: 30px;
    }

    .search-member {
        display: flex;
        gap: 32px;
        align-items: center;

        .title-member {
            width: fit-content;
        }

        .input-box {
            min-width: none;
        }

        // input {
        //     outline: none;
        //     border: none;
        //     height: 40px;
        //     width: 100%;
        //     border-radius: 12px;
        //     background: transparent;
        //     border: 1px solid #d8d8d8;
        //     color: #fff;
        //     min-width: 0px;

        //     &:focus {
        //         box-shadow: none;
        //     }
        // }

        button {
            border-radius: 100vw;
            background: linear-gradient(50deg, #0087c8 14.98%, #00d1ff 106.07%);
            box-shadow: 0px 2px 11.6px 1px rgba(176, 176, 176, 0.25) inset;
            display: flex;
            gap: 5px;
        }

        @media (max-width: 1280px) {
            gap: 20px;

            .title-member {
                .title {
                    font-size: 24px;
                }
            }
        }

        @media (max-width: 1024px) {
            flex-direction: column;
            align-items: flex-start;
        }
    }

    .box-input-group {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
        gap: 5px;

        .link-input {
            border: none;
            max-width: 570px;
            width: 100%;
            padding: 5px;
            word-wrap: break-word;
            background: transparent;
            box-shadow: 0px 0px 5px 1px #0000001e;
            // opacity: 0.5;
            border-radius: 12px;
            outline: none;
            color: #a6a6a6;
        }

        .copy {
            background-image: linear-gradient(50deg,
                    #0087c8 14.98%,
                    #00d1ff 106.07%);
        }

        svg {
            margin-right: 5px;
        }

        input {
            outline: none;
            border: 1px solid #00c2ff;
            background: linear-gradient(180deg, #00172a 0%, #00000b 100%);
            height: 40px;
            width: 80%;
            border-radius: 0;
            color: #fff;
            border: none;
        }

        button {
            max-width: 170px;
            width: 100%;
            height: 42px;
            border-radius: 267px;
            background: rgba(0, 255, 255, 0.15);
            font-weight: 600;
            font-size: 18px;
            display: flex;
            align-items: center;
            justify-content: center;
            text-transform: capitalize;
            border: none;
        }
    }

    // .table-responsive {
    //     background: #1478b0;
    //     border-radius: 22px;

    //     .customer-header {
    //         background-color: transparent;
    //         text-align: center;
    //         font-style: normal;
    //         font-weight: 500;
    //         font-size: 16px;
    //         line-height: 24px;
    //         color: #fff;
    //     }

    //     .table th,
    //     .table td {
    //         border: none;
    //     }

    //     table {
    //         thead {
    //             tr {
    //                 th {
    //                     white-space: nowrap;
    //                 }
    //             }
    //         }

    //         tbody {
    //             tr {
    //                 height: 70px;
    //                 border-radius: 12px;
    //                 background: transparent;

    //                 &:nth-child(even) {
    //                     background: #e1e1e175;
    //                 }

    //                 td {
    //                     background: none;
    //                     height: 100%;
    //                     padding: 0.75rem;
    //                     vertical-align: middle;
    //                     color: #fff;
    //                     font-weight: 500;

    //                     .mobile-data {
    //                         display: flex;
    //                         justify-content: space-between;
    //                         flex-wrap: wrap;
    //                         line-height: 1;
    //                         padding: 5px 0px;

    //                         >div:last-child {
    //                             color: #fff;
    //                         }
    //                     }

    //                     >span {
    //                         height: 70px;
    //                         display: flex;
    //                         padding: 0 0.75rem;
    //                         align-items: center;
    //                         margin-bottom: 0;
    //                         justify-content: center;
    //                         backdrop-filter: blur(15.899999618530273px);
    //                     }
    //                 }
    //             }
    //         }
    //     }
    // }

    .table-statistical {
        background: #1478b0;
        border-radius: 22px;
        border: 1px solid #d8d8d8;
        padding: 15px;
        color: #fff;
        position: relative;
        z-index: 999;

        .logo {
            max-width: 165px;
            width: 100%;
            margin: 30px auto;
        }

        .ref-link {
            background-color: #579ec6;
            box-shadow: 0px 0px 5px 1px #0000001e;
            border-radius: 6px;
            max-width: 465px;
            min-width: 0;
            margin: auto;
            display: block;
            width: 100%;
            border: none;
            padding: 6px 10px;
            word-wrap: break-word;
            text-align: center;
            font-size: 14px;
            line-height: 1;
        }

        .copy {
            background: #fff;
            width: 100%;
            max-width: 465px;
            border-radius: 100vw;
            font-weight: 600;
            font-size: 18px;
            margin: auto;
            margin-top: 15px;
            display: flex;
            align-items: center;
            justify-content: center;
            text-transform: capitalize;
            border: none;
            color: #1478b0;
            transition: 0.2s;

            &:hover {
                opacity: 0.8;
            }
        }

        .item {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            padding: 10px 0px;

            >div:last-child {
                color: #97d7f8;
            }
        }
        .updated-at {
            text-align: right;
            color: #dfdfdf;
            font-weight: 500;
        }
        .update-data {
            color: #1478b0;
            background-color: #fff;
            border-radius: 100vw;
            font-weight: 600;
            padding: 4px;
        }

        .plane-1,
        .plane-2,
        .plane-3 {
            position: absolute;
            z-index: -999;
        }

        .plane-1 {
            top: 35px;
            left: 0;

            img {
                width: 150px;
            }
        }

        .plane-2 {
            bottom: 0;
            right: 0;

            img {
                width: 150px;
            }
        }

        .plane-3 {
            top: 15px;
            right: 50%;
        }

        .col-lg-7 {
            display: flex;
            flex-direction: column;
            justify-content: center;
        }
    }

    .box-statistical {
        background: #1478b0;
        height: 100%;
        outline: none;
        padding: 15px;
        position: relative;
        border-radius: 22px;
        border: 1px solid #d8d8d8;

        .icons {
            flex: 0 0 70px;
            width: 70px;
            height: 100%;

            img {
                width: 50px;
                height: auto;
            }
        }

        .text {
            color: #fff;
            z-index: 1;
            flex-grow: 1;
            font-size: 14px;

            .text-title {
                font-size: 1em;
                font-style: italic;
                font-weight: 500;
                margin-bottom: 15px;
                text-transform: capitalize;
                display: flex;
                justify-content: space-between;
            }

            .text-content {
                font-size: 1.125em;
                color: #6dceff;
                font-weight: 700;
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
                gap: 5px;

                &.bound {
                    color: #0f766e;
                }

                >span {
                    min-width: 0;
                    word-wrap: break-word;
                }

                >div:last-child {
                    flex-shrink: 0;
                }
            }

            &.bound {
                .text-content {
                    color: #fff;
                }

                .text-title {
                    color: #fff;
                }
            }

            @media (min-width: 992px) {
                font-size: 16px;
            }
        }
    }
}

.member {
    margin-top: 1rem;

    .mining-header {
        background: #1478b0;
        padding: 15px;
        align-items: center;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        border: 1px solid #d8d8d8;
        border-radius: 22px;

        @media (min-width: 768px) {
            padding: 35px 45px;
        }

        .mining-text {
            div {
                font-size: 16px;
                font-weight: 400;
                color: #fff;
                margin: 10px 0px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                gap: 5px;

                @media (min-width: 576px) {
                    display: block;
                }

                span {
                    font-size: 14px;
                    font-weight: 600;
                    color: #97d7f8;
                    text-align: right;
                }

                @media (min-width: 576px) {
                    font-size: 20px;

                    span {
                        font-size: 18px;
                    }
                }
            }

            div:nth-child(3),
            div:last-child {
                >span {
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: flex-end;
                }

                @media (min-width: 575px) {
                    display: flex;
                    justify-content: unset;

                    >span {
                        display: flex;
                        gap: 5px;
                    }
                }
            }
        }

        .changepage {
            font-size: 20px;
            font-style: italic;
            font-weight: 400;
            color: #fff;
            // background: linear-gradient(180deg, #00d1ff 0%, #0087c8 100%);
            background: #fff;
            padding: 10px 15px;
            border-radius: 100vw;
            color: #1478b0;
            font-weight: 700;
            transition: 0.2s;

            svg {
                padding-left: 5px;
            }

            &:hover {
                opacity: 0.8;
            }
        }

        .cta {
            text-align: center;

            .node {
                max-width: 180px;
                display: block;
                margin: auto;
                padding-bottom: 20px;
            }

            @media (max-width: 991px) {
                font-size: 16px;
            }
        }

        >div {
            flex-basis: 100%;

            @media (min-width: 576px) {
                flex-basis: auto;
            }
        }
    }

    .member-title {
        display: flex;
        justify-content: space-between;
        width: 100%;
        color: #fff;

        h3 {
            font-size: 20px;
            font-weight: 600;
        }

        >div {
            .router-link {
                border-radius: 6px;
                background: #fff;
                padding: 5px 15px;
                color: #1478b0;
                font-weight: 700;
                transition: 0.2s;

                img {
                    width: 15px;
                    height: 13px;
                }

                &:hover {
                    opacity: 0.8;
                }
            }
        }
    }

    .member-right {
        background-color: #107fb9;
        min-height: 400px;
        border-radius: 22px;
        border: 1px solid #d8d8d8;
        padding: 15px;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;

        .member-right-content {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-around;
            flex-grow: 1;
            width: 100%;

            img {
                width: 120px;
            }

            .text-1 {
                font-size: 20px;
                font-weight: 600;
                color: #6dceff;
            }

            .text-2 {
                font-size: 24px;
                font-weight: 600;
                color: #6dceff;
            }

            .text-3,
            .text-4 {
                font-size: 16px;
                font-weight: 400;
                margin: 0;
            }
            
            .text-3 {
                display: flex;
                justify-content: space-between;
                width: 100%;
                font-weight: 600;
                color: #fff;

                span {
                    color: #6dceff;
                }
            }
        }

        .no-data {
            font-size: 50px;
            font-weight: 600;
            color: #fff;

            @media (max-width: 991px) {
                font-size: 30px;
            }
        }
    }

    @media (max-width: 991px) {
        gap: 2rem;

        .member-left {
            .plane-2 {
                right: 0;
            }
        }
    }
}

.buyXNode {
    font-size: 20px;
    background: #fff;
    color: #1478b0;
    font-weight: 700;
    border-radius: 100vw;
    border: none;
    transition: 0.2s;

    &:hover {
        opacity: 0.8;
    }
}

.member-chart {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #1478b0;
    border-radius: 22px;
    padding: 8px;

    @media (max-width: 769px) {
        margin-top: 10px;
    }

    height: 100%;

    .echarts {
        height: 375px;

        @media (min-width: 992px) {
            height: 100%;
        }
    }
}

.xnode-table {
    th {
        white-space: nowrap;
    }

    tbody {
        tr {
            &:nth-child(even) {
                background-color: rgba(0, 0, 0, 0.05) !important;
            }
        }

        tr:hover {
            color: inherit;
        }
    }

    .status {
        background: #dc2626 !important;
        padding: 2px 8px !important;
        border-radius: 6px;
        font-size: 16px;
        height: fit-content !important;
        width: fit-content !important;

        svg {
            vertical-align: text-bottom;
        }

        &.active {
            background: #22c55e !important;
            color: #fff;
        }
    }

    thead,
    tbody {
        color: #fff;
    }

    tbody td {
        vertical-align: middle;
    }
}

.table-container {
    background: #1478b0;
    border: 1px solid #d8d8d8;
    border-radius: 20px;
    padding: 1.5rem 0.5rem;
    margin-top: 1rem;
    color: #fff;

    @media (min-width: 768px) {
        padding: 2rem;
    }

    h3 {
        font-size: 20px;
        font-weight: 600;
    }
}

.input-box,
.input-F {
    position: relative;

    >input {
        border-radius: 6px;
        padding: 4px 36px 4px 16px;
        border: none;
        outline: none;
        box-shadow: 0px 0px 0px 1px #adadad;
    }

    .search {
        position: absolute;
        right: 5px;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;
    }
}

.input-F {
    position: relative;

    >input {
        padding: 4px 4px 4px 30px;
    }

    .label-F {
        position: absolute;
        z-index: 10;
        top: 50%;
        transform: translateY(-50%);
        left: 5%;
        color: #767676;
    }
}
</style>
