<template>
    <div class="container py-2">
        <EventBanners></EventBanners>
        <div class="row mt-4">
            <div class="col-md-6 col-lg-4 py-3">
                <div class="box-statistical">
                    <div class="text">
                        <div class="text-title">
                            <p>{{ $t('totalSoM', { something: 'USDT' }) }}</p>
                        </div>
                        <div class="text-content">
                            <div>
                                <img
                                    src="../../assets/images/logo/usdt.png"
                                    alt=""
                                />
                                <span>
                                    {{ TruncateToDecimals2(UserInfo.USDT) }}
                                </span>
                            </div>
                            <div>
                                <router-link
                                    class="router-link"
                                    :to="{ name: 'Wallet' }"
                                >
                                    {{ $t('wallet') }}
                                    <img
                                        src="../../assets/images/icons/arrow_right.svg"
                                        alt=""
                                    />
                                </router-link>
                            </div>
                        </div>
                        <div class="text-content">
                            <span>
                                {{ TruncateToDecimals2(UserInfo.USDT) }}
                            </span>
                            <p>USDT</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6 col-lg-4 py-3">
                <div class="box-statistical">
                    <div class="text">
                        <div class="text-title">
                            <div>
                                {{ $t('totalSoM', { something: $t('token') }) }}
                            </div>
                        </div>
                        <div class="text-content">
                            <div>
                                <img
                                    src="../../assets/images/logo/xin.png"
                                    alt=""
                                />
                                <span>{{ TruncateToDecimals2(Balance) }}</span>
                            </div>
                            <div>
                                <router-link
                                    class="router-link"
                                    :to="{ name: 'Wallet' }"
                                >
                                    {{ $t('wallet') }}
                                    <img
                                        src="../../assets/images/icons/arrow_right.svg"
                                        alt=""
                                    />
                                </router-link>
                            </div>
                        </div>
                        <div class="text-content">
                            <span>{{
                                TruncateToDecimals2(UserInfo.xinCoin)
                            }}</span>
                            <p>XIN80</p>
                        </div>
                        <div class="text-content bound">
                            <span>{{
                                TruncateToDecimals2(UserInfo.bxinCoin)
                            }}</span>
                            <p>XIN20</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6 col-lg-4 py-3">
                <div class="box-statistical">
                    <div class="text">
                        <div class="text-title">
                            <div>
                                {{
                                    $t('totalSoM', {
                                        something: $t('commissions'),
                                    })
                                }}
                            </div>
                        </div>
                        <div class="text-content">
                            <div>
                                <img
                                    src="../../assets/images/logo/usdt.png"
                                    alt=""
                                />
                                <span>
                                    {{ TruncateToDecimals2(USDTCommission) }}
                                </span>
                            </div>
                            <div>
                                <router-link
                                    class="router-link"
                                    :to="{ name: 'Wallet' }"
                                >
                                    {{ $t('wallet') }}
                                    <img
                                        src="../../assets/images/icons/arrow_right.svg"
                                        alt=""
                                    />
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <RateChartSwap></RateChartSwap>
        <div class="member row">
            <div class="col-lg-8">
                <!-- <div class="member-left">
                    <div class="member-title">
                        <h3>{{ $t('affiliate') }}</h3>
                        <div>
                            <router-link
                                class="router-link"
                                :to="{ name: 'Member' }"
                            >
                                {{ $t('affiliate') }}
                                <img
                                    src="../../assets/images/icons/arrow_right.svg"
                                    alt=""
                                />
                            </router-link>
                        </div>
                    </div>
                    <div class="member-left-content">
                        <div class="refer">
                            <div class="text-center">
                                <VueQr :text="url" class="logo" />
                            </div>
                            <div class="ref-link">
                                {{ url }}
                            </div>
                            <b-button
                                class="copy"
                                v-clipboard:copy="url"
                                v-clipboard:success="onCopy"
                                v-clipboard:error="onError"
                            >
                                <svg
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M21.75 24C22.9927 24 24 22.9927 24 21.75V8.25C24 7.00734 22.9927 6 21.75 6H8.25C7.00734 6 6 7.00734 6 8.25V21.75C6 22.9927 7.00734 24 8.25 24H21.75ZM8.25 4.5C6.18225 4.5 4.5 6.18225 4.5 8.25V18H2.25C1.00734 18 0 16.9927 0 15.75V2.25C0 1.00734 1.00734 0 2.25 0H15.75C16.9927 0 18 1.00734 18 2.25V4.5H8.25Z"
                                        fill="#1478B0"
                                    />
                                </svg>
                                <div class="text pl-2">
                                    {{ $t('referralLink') }}
                                </div>
                            </b-button>
                        </div>

                        <div>
                            <div>
                                <p>
                                    {{ $t('totalDownline') }}
                                </p>
                                <p>
                                    {{
                                        TruncateToDecimals2(
                                            Statistic.totalDownlines,
                                        )
                                    }}
                                </p>
                            </div>
                            <div>
                                <p>
                                    {{ $t('totalF1') }}
                                </p>
                                <p>
                                    {{ TruncateToDecimals2(Statistic.totalF1) }}
                                </p>
                            </div>
                            <div>
                                <p>
                                    {{ $t('totalF1Sales') }}
                                </p>
                                <p>
                                    {{ TruncateToDecimals2(TotalFSale) }}
                                    USDT
                                </p>
                            </div>
                            <div>
                                <p>
                                    {{ $t('totalGroupSales') }}
                                </p>
                                <p>
                                    {{ TruncateToDecimals2(TotalGroupSale) }}
                                    USDT
                                </p>
                            </div>
                            <div>
                                <p>
                                    {{ $t('totalGroupMinings') }}
                                </p>
                                <p>
                                    {{ TruncateToDecimals2(TotalGroupMint) }}
                                    XIN
                                </p>
                            </div>
                            <div class="updated-at">
                                <template v-if="GroupStat">
                                    {{ getDateTime3(GroupStat.date) }}
                                    <button
                                        class="update-data"
                                        @click="updateGroupStat"
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                        >
                                            <path
                                                fill="none"
                                                stroke="currentColor"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                                stroke-width="2"
                                                d="M20 20v-5h-5M4 4v5h5m10.938 2A8.001 8.001 0 0 0 5.07 8m-1.008 5a8.001 8.001 0 0 0 14.868 3"
                                            />
                                        </svg>
                                    </button>
                                </template>
                                <template v-else>
                                    <span class="h-4 d-inline-block"></span>
                                </template>
                            </div>
                        </div>
                    </div>
                    <div class="plane-1">
                        <img
                            src="../../assets/images/background/flight-big.png"
                            alt=""
                        />
                    </div>
                    <div class="plane-2">
                        <img
                            src="../../assets/images/background/flight-small.png"
                            alt=""
                        />
                    </div>
                    <div class="plane-3">
                        <img
                            src="../../assets/images/background/flight-tiny.png"
                            alt=""
                        />
                    </div>
                </div> -->
                <TableStatistical
                    :Statistic="Statistic"
                    :TotalFSale="TotalFSale"
                    :TotalGroupSale="TotalGroupSale"
                    :TotalGroupMint="TotalGroupMint"
                    :GroupStat="GroupStat"
                    :UserInfo="UserInfo"
                />
            </div>
            <div class="col-lg-4">
                <div class="member-right">
                    <div class="member-title">
                        <h3>{{ $t('myXNode') }}</h3>
                        <router-link :to="{ name: 'XnodeInventory' }">
                            {{ $t('myXNode') }}
                            <img
                                src="../../assets/images/icons/arrow_right.png"
                                alt=""
                            />
                        </router-link>
                    </div>
                    <div class="member-right-content">
                        <img src="@/assets/images/mockup/node.png" alt="" />
                        <p class="text-1">
                            {{ $t('SoMData', { something: 'XNode' }) }}
                        </p>
                        <p class="text-2">{{ XNode.miningHz }} Hz/h</p>
                        <p class="text-3">
                            {{ $t('totalXINPending') }}
                            <span>
                                {{ TruncateToDecimals2(XNode.totalXinPending) }}
                                XIN
                            </span>
                        </p>
                        <p class="text-3">
                            {{ $t('totalXINMined') }}
                            <span>
                                {{ TruncateToDecimals2(XNode.totalXinMined) }}
                                XIN
                            </span>
                        </p>

                        <p class="text-3">
                            {{ $t('totalSoM', { something: 'Mana' }) }}
                            <span v-if="XNode.totalMana === 0">
                                -/{{ UserInfo.limitMana }} mana
                            </span>
                            <span v-else
                                >{{ XNode.totalMana }}/{{
                                    UserInfo.limitMana
                                }}
                                mana</span
                            >
                        </p>
                        <p class="text-3">
                            {{ $t('XNodeSlot') }}
                            <span>
                                <!-- {{ XNode.usedSlot }}/ {{ XNode.nodeSlot }} slots -->
                                {{ XNode.usedSlot }}
                            </span>
                        </p>
                    </div>
                    <b-button
                        class="buyXNode"
                        @click="$router.push({ name: 'BuyXnode' })"
                    >
                        {{ $t('buyXNode') }}
                    </b-button>
                </div>
            </div>
        </div>
        <!-- <div class="chart">
            <div>
                <ColumnChart />
            </div>
            <div>
                <PieChart />
            </div>
        </div> -->
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
// import ColumnChart from './DashboardChart/ColumnChart.vue';
// import PieChart from './DashboardChart/PieChart.vue';
import VueQr from 'vue-qr';
import EventBanners from '@/components/shared/EventBanners.vue';
import RateChartSwap from './DashboardChart/RateChartSwap.vue';
import TableStatistical from '../../components/shared/TableStatistical.vue';

export default {
    name: 'DashboardPage',
    components: { VueQr, EventBanners, RateChartSwap, TableStatistical },
    computed: {
        ...mapGetters({
            Balance: 'info/Balance',
            UserInfo: 'info/UserInfo',
            Statistic: 'member/Statistic',
            XNode: 'info/XNode',
            TotalFSale: 'member/TotalFSale',
            TotalGroupMint: 'member/TotalGroupMint',
            TotalGroupSale: 'member/TotalGroupSale',
            GroupStat: 'member/GroupStat',
        }),
        url() {
            return `${window.location.hostname}/register?sponsor=${this.UserInfo.refId}`;
        },
        MyStatistic() {
            console.log(Object.entries(this.Statistic));
            return Object.entries(this.Statistic);
        },
        USDTCommission() {
            if (
                this.XNode.totalCommission &&
                this.XNode.totalCommission.length > 0
            ) {
                const com = this.XNode.totalCommission.find((el) => {
                    return el._id === 'USDT';
                });
                if (com) {
                    return com.total;
                }
            }
            return 0;
        },
    },
    methods: {
        onCopy() {
            this.$toastr.s('Copy Link Referral Success', 'Successfully');
        },
        onError() {
            this.$toastr.e(
                'Copy Link Referral Fail,Please Try Again',
                'Failed',
            );
        },
        updateGroupStat() {
            this.$store.dispatch('member/reqTotalGroupStat', { refresh: true });
        },
    },
    mounted() {
        this.$store.dispatch('member/req_getStatistical');
        this.$store.dispatch('member/reqTotalFSale');
        this.$store.dispatch('member/reqTotalGroupStat');
    },
};
</script>

<style lang="scss" scoped>
.box-statistical {
    background: #1478b0;
    height: 100%;
    outline: none;
    padding: 15px;
    position: relative;
    border-radius: 22px;
    border: 1px solid #d8d8d8;

    .text {
        color: #fff;
        z-index: 1;
        flex-grow: 1;
        font-size: 14px;

        .text-title {
            font-size: 1em;
            font-style: italic;
            font-weight: 500;
            margin-bottom: 15px;
            text-transform: capitalize;
            display: flex;
            justify-content: space-between;

            p {
                margin-bottom: 0;
            }
        }

        .text-content {
            font-size: 1.5em;
            color: #fff;
            font-weight: 700;
            display: flex;
            justify-content: space-between;
            gap: 5px;

            > div:first-child {
                display: flex;
                align-items: center;
                gap: 15px;
            }

            .router-link {
                background: #fff;
                border-radius: 6px;
                font-size: 16px;
                color: #1478b0;
                padding: 5px 10px;
                transition: 0.2s;

                &:hover {
                    opacity: 0.8;
                }
            }

            img {
                width: 25px;
                height: 25px;
            }

            > span {
                min-width: 0;
                word-wrap: break-word;
            }

            > div:last-child {
                flex-shrink: 0;

                img {
                    width: 15px;
                    height: 13px;
                    padding-bottom: 1px;
                }
            }
        }

        &.bound {
            .text-content {
                color: #0f766e;
            }

            @media (min-width: 1400px) {
                font-size: 32px;
            }
        }

        @media (min-width: 992px) {
            font-size: 16px;
        }
    }
}

.member {
    margin-top: 1rem;

    .member-title {
        display: flex;
        justify-content: space-between;
        width: 100%;
        color: #fff;

        h3 {
            font-size: 20px;
            font-weight: 600;
        }

        > div {
            .router-link {
                border-radius: 6px;
                background: #fff;
                padding: 5px 15px;
                color: #1478b0;
                font-weight: 700;
                transition: 0.2s;

                img {
                    width: 15px;
                    height: 13px;
                }

                &:hover {
                    opacity: 0.8;
                }
            }
        }
    }

    .member-left,
    .member-right {
        background-color: #107fb9;
        min-height: 434px;
        border-radius: 22px;
        border: 1px solid #d8d8d8;
        padding: 15px;
        height: 100%;
    }

    .member-left {
        position: relative;
        z-index: 999;

        .member-left-content {
            display: flex;
            margin-top: 1rem;
            flex-wrap: wrap;

            > div:nth-child(1) {
                display: flex;
                flex-direction: column;
                gap: 2rem;
                flex-basis: 100%;

                .img {
                    display: flex;
                    justify-content: center;
                }

                input {
                    width: 90%;
                    margin: 0 auto;
                    padding: 0.5rem;
                    border: 1px solid #e4e4e4;
                    border-radius: 12px;
                    outline: none;
                    font-size: 20px;
                    font-weight: 400;
                    color: #a6a6a6;
                }

                button {
                    width: 90%;
                    margin: 0 auto;
                    border-radius: 72px;
                    background: #fff;
                    color: #1478b0;
                    padding: 0.5rem;
                }
            }

            > div:nth-child(2) {
                flex-basis: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                gap: 1rem;
                padding: 10px 0px;

                > div:not(.updated-at) {
                    display: flex;
                    justify-content: space-between;

                    p {
                        font-size: 18px;
                        font-weight: 400;
                        color: #fff;
                    }

                    p:nth-child(2) {
                        color: #97d7f8;
                    }
                }
                .updated-at {
                    text-align: right;
                    color: #dfdfdf;
                    font-weight: 500;
                }
                .update-data {
                    color: #1478b0;
                    background-color: #fff;
                    border-radius: 100vw;
                    font-weight: 600;
                    padding: 4px;
                }
            }

            @media (min-width: 768px) {
                > div:nth-child(1) {
                    flex-basis: 50%;
                }

                > div:nth-child(2) {
                    flex-basis: 50%;
                    padding: 0px 10px;
                }
            }
        }

        .plane-1,
        .plane-2,
        .plane-3 {
            position: absolute;
            z-index: -999;
        }

        .plane-1 {
            top: 35px;
            left: 0;

            img {
                width: 150px;
            }
        }

        .plane-2 {
            top: 18px;
            right: 65px;

            img {
                width: 150px;
            }
        }

        .plane-3 {
            top: 15px;
            right: 50%;
        }
    }

    .member-right {
        display: flex;
        flex-direction: column;
        align-items: center;

        .member-right-content {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            flex-grow: 1;
            width: 100%;

            img {
                width: 153px;
            }

            .text-1 {
                font-size: 20px;
                font-weight: 600;
                color: #6dceff;
            }

            .text-2 {
                font-size: 24px;
                font-weight: 600;
                color: #6dceff;
            }

            .text-3,
            .text-4 {
                font-size: 16px;
                font-weight: 400;
                margin: 0;
            }

            .text-3 {
                display: flex;
                justify-content: space-between;
                width: 100%;
                font-weight: 600;
                color: #fff;

                span {
                    color: #6dceff;
                }
            }
        }
    }

    @media (max-width: 991px) {
        gap: 2rem;

        .member-left {
            .plane-2 {
                right: 0;
            }
        }
    }
}

.refer {
    padding: 0px 10px;

    .logo {
        max-width: 165px;
        width: 100%;
        margin: 30px auto;
    }

    .ref-link {
        background-color: #579ec6;
        color: #dfdfdf;
        box-shadow: 0px 0px 5px 1px #0000001e;
        border-radius: 6px;
        max-width: 465px;
        min-width: 0;
        margin: auto;
        display: block;
        width: 100%;
        border: none;
        padding: 6px 10px;
        word-wrap: break-word;
        text-align: center;
    }

    .copy {
        background-image: linear-gradient(
            50deg,
            #0087c8 14.98%,
            #00d1ff 106.07%
        );
        width: 100%;
        max-width: 465px;
        border-radius: 100vw;
        font-weight: 600;
        font-size: 18px;
        margin: auto;
        margin-top: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-transform: capitalize;
        border: none;
        transition: 0.2s;

        &:hover {
            opacity: 0.8;
        }
    }
}

.buyXNode {
    background: #fff;
    color: #1478b0;
    font-weight: 700;
    border-radius: 100vw;
    border: none;
    transition: 0.2s;

    &:hover {
        opacity: 0.8;
    }
}
</style>

<style lang="scss">
.banner-modal {
    max-width: 750px;
    position: relative;

    .modal-content {
        .modal-body {
            border-radius: 40px;
            padding: 2rem;

            .header {
                margin-top: 1rem;
                display: flex;
                justify-content: center;

                > div {
                    h2 {
                        font-size: 30px;
                        font-weight: 600;
                        color: #0087cc;
                    }

                    p {
                        font-size: 16px;
                        font-weight: 500;
                        color: #ff0000;
                        text-align: center;
                    }
                }

                button {
                    position: absolute;
                    right: 15px;
                    top: 15px;
                }
            }

            .body {
                display: flex;
                flex-direction: column;
                gap: 1rem;

                h3 {
                    color: red;
                }

                div {
                    display: flex;
                    justify-content: space-between;
                    align-items: flex-start;

                    .body-content {
                        display: flex;
                        align-items: flex-start;
                        flex-direction: column;
                        gap: 10px;

                        > div {
                            gap: 10px;
                        }

                        input {
                            border: 1px solid #e7e7e7;
                            border-radius: 12px;
                        }
                    }

                    p {
                        margin-bottom: 0;

                        font-size: 18px;
                        font-weight: 400;
                        color: #656565;
                    }

                    a {
                        font-size: 18px;
                        font-style: italic;
                        color: #fff;
                        background: linear-gradient(#00d1ff, #0087c8);
                        padding: 5px 35px;
                        border-radius: 72px;
                        outline: none;
                    }
                }
            }

            .footer {
                margin-top: 3rem;
                display: flex;
                justify-content: center;

                button {
                    border-radius: 72px;
                    max-width: 465px;
                    width: 465px;
                    height: 53px;
                    background: linear-gradient(#00d1ff, #0087c8);
                    color: #fff;
                    outline: none;
                }
            }
        }
    }
}
</style>
