<template>
    <div class="payment-bill container">
        <router-link :to="{ name: 'Utilities' }" class="breadcrumbs">
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
            >
                <path
                    fill="currentColor"
                    d="M7.828 11H20v2H7.828l5.364 5.364l-1.414 1.414L4 12l7.778-7.778l1.414 1.414z"
                />
            </svg>
            {{ $t(currentPath + 'Payment') }}
        </router-link>

        <div class="mt-4">
            <h3 class="head">
                {{ $t('getPaymentInfo') }}
            </h3>
            <form class="my-form" @submit.prevent="handleSubmit">
                <label>
                    {{ $t('billType') }}:
                    <input :value="$t(currentPath)" readonly />
                </label>
                <label>
                    {{ $t('provider') }}:
                    <select v-model="provider">
                        <option
                            v-for="option in providers"
                            :key="option.serviceName"
                            :value="option.serviceCode"
                        >
                            {{ option.serviceName }}
                        </option>
                    </select>
                </label>
                <label>
                    {{ $t('customerCode') }}:
                    <input v-model="code" required />
                </label>
                <template v-if="manual">
                    <label>
                        {{ $t('amount') }}:
                        <span class="instruction">
                            {{ $t('enterAmountToPayInVND') }}
                        </span>
                        <input v-model="amount" required />
                        <div class="amount-preview" :class="{ show: amount }">
                            {{ Number(amount).toLocaleString('en-US') }} VNĐ
                        </div>
                    </label>
                    <label>
                        {{
                            $t('pleaseUploadYourBillContainingYourCustomerCode')
                        }}
                        <b-form-file
                            v-model="file1"
                            :state="!!file1"
                            :placeholder="$t('chooseImageOrDropHere')"
                            :drop-placeholder="$t('dropImageHere')"
                            :browse-text="$t('browse')"
                            accept="image/*"
                            :required="true"
                        ></b-form-file>
                    </label>
                    <div class="ookii-notice text-center py-2">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="1.25em"
                            height="1.25em"
                            viewBox="0 0 256 256"
                        >
                            <path
                                fill="currentColor"
                                d="M236.8 188.09L149.35 36.22a24.76 24.76 0 0 0-42.7 0L19.2 188.09a23.51 23.51 0 0 0 0 23.72A24.35 24.35 0 0 0 40.55 224h174.9a24.35 24.35 0 0 0 21.33-12.19a23.51 23.51 0 0 0 .02-23.72m-13.87 15.71a8.5 8.5 0 0 1-7.48 4.2H40.55a8.5 8.5 0 0 1-7.48-4.2a7.59 7.59 0 0 1 0-7.72l87.45-151.87a8.75 8.75 0 0 1 15 0l87.45 151.87a7.59 7.59 0 0 1-.04 7.72M120 144v-40a8 8 0 0 1 16 0v40a8 8 0 0 1-16 0m20 36a12 12 0 1 1-12-12a12 12 0 0 1 12 12"
                            />
                        </svg>
                        {{ $t('paymentWillBeProcessedWithin24Hours') }}
                    </div>
                </template>

                <button type="submit" class="botan">{{ $t('submit') }}</button>
                <div class="switcheroo">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        v-b-tooltip.hover
                        :title="$t('manualPaymentExplanation')"
                    >
                        <path
                            fill="currentColor"
                            d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10s-4.477 10-10 10m0-2a8 8 0 1 0 0-16a8 8 0 0 0 0 16m-1-5h2v2h-2zm2-1.645V14h-2v-1.5a1 1 0 0 1 1-1a1.5 1.5 0 1 0-1.471-1.794l-1.962-.393A3.501 3.501 0 1 1 13 13.355"
                        />
                    </svg>
                    <button type="button" @click="manual = !manual">
                        {{ manual ? $t('manual') : $t('automated') }}
                    </button>
                </div>
            </form>
        </div>

        <b-modal
            id="payment-confirm"
            hide-header
            hide-footer
            dialog-class="supa-modal"
            centered
            @hidden="resetModal"
        >
            <div class="mi-head">{{ $t('confirmPayment') }}?</div>
            <div class="mi-confirm">{{ $t('doYouContinuePayment') }}?</div>
            <div class="confirm-value">
                <div v-if="customer && customer.customerCode" class="item">
                    <div>{{ $t('customerCode') }}:</div>
                    <div class="value">
                        {{ customer.customerCode }}
                    </div>
                </div>
                <div class="item">
                    <div>{{ $t('customerCode') }}:</div>
                    <div class="value">
                        {{ code }}
                    </div>
                </div>
                <div class="item">
                    <div>{{ $t('provider') }}:</div>
                    <div class="value">
                        {{ provider }}
                    </div>
                </div>
                <div v-if="customer && customer.customerName" class="item">
                    <div>{{ $t('customerName') }}:</div>
                    <div class="value">
                        {{ customer.customerName }}
                    </div>
                </div>
                <div v-if="customer && customer.customerAddress" class="item">
                    <div>{{ $t('customerAddress') }}:</div>
                    <div class="value">
                        {{ customer.customerAddress }}
                    </div>
                </div>
                <div v-if="billDetail && billDetail.period" class="item">
                    <div>{{ $t('period') }}:</div>
                    <div class="value">
                        {{ billDetail.period }}
                    </div>
                </div>
                <div class="break-line"></div>
                <div class="item">
                    <div>{{ $t('amount') }}:</div>
                    <div class="value">
                        {{ TruncateToDecimals2(Number(amount)) }} VNĐ
                    </div>
                </div>
                <div class="item">
                    <div>{{ $t('payWith') }}:</div>
                    <div class="value">
                        {{ TruncateToDecimals2(VNDToXIN) }}
                        <img src="@/assets/images/logo/xin.png" width="18px" />
                    </div>
                </div>
            </div>
            <div class="botans">
                <button class="botan confirm" @click="paymentBill">
                    {{ $t('confirm') }}
                </button>
                <button class="botan" @click="$bvModal.hide('payment-confirm')">
                    {{ $t('cancel') }}
                </button>
            </div>
            <button
                class="close black"
                @click="$bvModal.hide('payment-confirm')"
                type="button"
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="1em"
                    height="1em"
                    buyVoucherviewBox="0 0 24 24"
                >
                    <path
                        fill="currentColor"
                        d="M6.4 19L5 17.6l5.6-5.6L5 6.4L6.4 5l5.6 5.6L17.6 5L19 6.4L13.4 12l5.6 5.6l-1.4 1.4l-5.6-5.6z"
                    />
                </svg>
            </button>
        </b-modal>
    </div>
</template>

<script>
export default {
    name: 'Payment',

    data() {
        return {
            code: '',
            currentPath: window.location.pathname.split('/').pop(),
            providers: [],
            provider: '',
            pending: false,
            amount: '',
            file1: null,
            VNDToXIN: 0,
            billDetail: [],
            parnerRefId: '',
            customer: undefined,
            manual: false,
        };
    },

    methods: {
        async getServices() {
            const res = await this.$store.dispatch(
                'utilities/getServices',
                this.currentPath.toUpperCase(),
            );

            if (res.status) {
                this.providers = res.data;
                this.provider = this.providers[0].serviceCode;
            }
        },

        async getBill() {
            return this.$store
                .dispatch('utilities/getBill', {
                    customer_code: this.code,
                    type_category: this.provider,
                })
                .then((res) => {
                    if (res.status) {
                        this.$bvModal.show('payment-confirm');
                        this.amount = res.data.billDetail[0].amount;
                        this.code = res.data.bill_code;
                        this.VNDToXIN = res.data.transfer;
                        this.billDetail = res.data.billDetail;
                        this.customer = res.data.customer;
                        this.category = res.data.category;
                        this.parnerRefId = res.data.refId;
                    }
                });
        },

        resetModal() {
            this.amount = '';
            this.VNDToXIN = 0;
            this.billDetail = [];
            this.customer = undefined;
            this.parnerRefId = '';
        },

        async paymentBill() {
            if (this.pending) {
                return;
            }
            this.pending = true;

            await this.$recaptchaLoaded();
            const token = await this.$recaptcha('utilityPayment');

            const data = {
                customer_code: this.code,
                type_payment: this.category.toLowerCase(),
                type_category: this.provider,
                category: this.category,
                billDetail: this.billDetail,
                parnerRefId: this.parnerRefId,
                token
            };

            this.$store
                .dispatch('utilities/paymentBill', data)
                .then((res) => {
                    if (res.status) {
                        this.$store.dispatch('info/req_getInfo');
                        this.resetModal();
                        this.$bvModal.hide('payment-confirm');
                        this.$router.push({ name: 'UtilityOptions' });
                    }
                })
                .finally(() => {
                    this.pending = false;
                });
        },

        async paymentBillManual() {

            await this.$recaptchaLoaded();
            const token = await this.$recaptcha('utilityPayment');

            return this.$store
                .dispatch('utilities/paymentBillManual', {
                    type_payment: 'electric',
                    customer_code: this.code,
                    bill_value: this.amount,
                    type_category: this.provider,
                    image: this.file1,
                    token
                })
                .then((res) => {
                    if (res.status) {
                        this.$store.dispatch('info/req_getInfo');
                        this.$router.push({ name: 'UtilityOptions' });
                    }
                });
        },

        async handleSubmit() {
            if (this.pending) {
                return;
            }
            this.pending = true;

            if (this.manual) {
                await this.paymentBillManual();
            } else {
                await this.getBill();
            }

            this.pending = false;
        },
    },

    created() {
        this.getServices();
    },
};
</script>

<style lang="scss" scoped>
.payment-bill {
    padding-top: 30px;
    padding-bottom: 30px;
}

.breadcrumbs {
    font-size: 1.25em;
    color: #1478b0;
    font-weight: 600;
    transition: 0.2s;

    &:hover {
        color: #22578a;
    }

    @media (min-width: 992px) {
        font-size: 1.75em;
    }
}

.head {
    text-align: center;
    max-width: 700px;
    margin: auto;
    color: #1478b0;
    font-weight: 600;
    font-size: 1.5em;
    padding-bottom: 15px;

    @media (min-width: 992px) {
        font-size: 2em;
    }
}

.my-form {
    max-width: 700px;
    margin: auto;
    background-color: #1478b0;
    color: #fff;
    padding: 15px;
    border-radius: 16px;

    label {
        display: block;
        padding: 10px 0px;

        input {
            margin-top: 5px;
            display: block;
            width: 100%;
            min-width: none;
            border-radius: 100vw;
            padding: 5px 15px;
            border: none;
            outline: none;

            &[readonly] {
                background-color: #d9d9d9;
            }
        }

        select {
            display: block;
            width: 100%;
            border-radius: 100vw;
            padding: 5px 15px;
        }

        select {
            background: url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0Ljk1IDEwIj48ZGVmcz48c3R5bGU+LmNscy0xe2ZpbGw6I2ZmZjt9LmNscy0ye2ZpbGw6IzQ0NDt9PC9zdHlsZT48L2RlZnM+PHRpdGxlPmFycm93czwvdGl0bGU+PHJlY3QgY2xhc3M9ImNscy0xIiB3aWR0aD0iNC45NSIgaGVpZ2h0PSIxMCIvPjxwb2x5Z29uIGNsYXNzPSJjbHMtMiIgcG9pbnRzPSIxLjQxIDQuNjcgMi40OCAzLjE4IDMuNTQgNC42NyAxLjQxIDQuNjciLz48cG9seWdvbiBjbGFzcz0iY2xzLTIiIHBvaW50cz0iMy41NCA1LjMzIDIuNDggNi44MiAxLjQxIDUuMzMgMy41NCA1LjMzIi8+PC9zdmc+)
                no-repeat;
            background-position: right 5px top;
            /* Change the px value here to change the distance */
            -moz-appearance: none;
            -webkit-appearance: none;
            appearance: none;
            background-color: #fff;
            display: block;
            width: 100%;
            border-radius: 100vw;
            padding: 5px 15px;
        }
    }

    .botan {
        color: #1478b0;
        background-color: #fff;
        border-radius: 6px;
        display: block;
        margin: auto;
    }
    .switcheroo {
        color: #fff;
        display: flex;
        justify-content: end;
        justify-content: flex-end;
        align-items: center;
        gap: 8px;
        > button {
            color: inherit;
            background-color: #0087cc;
            border-radius: 6px;
        }
    }
}

.base-table {
    border-radius: 22px;
}

.amount-preview {
    padding: 4px 16px 0px;
    color: #f59e0b;
    font-weight: 600;
    min-height: 1.1em;
    line-height: 1.1;
    opacity: 0;

    &.show {
        opacity: 1;
    }
}

.instruction {
    padding: 4px 16px 0px;
    font-weight: 600;
}

.mi-head {
    font-size: 24px;
    font-weight: 600;
    color: #0087cc;
    text-align: center;

    &.fail {
        color: #cc0000;
    }

    @media (min-width: 768px) {
        font-size: 30px;
    }
}

.mi-confirm {
    font-size: 18px;
    text-align: center;
    padding: 15px 0px;
}

.botans {
    display: flex;
    align-items: center;
    justify-content: space-around;
    gap: 10px;

    .botan {
        color: #0087cc;
        border: 1px solid currentColor;
        border-radius: 100vw;
        padding: 8px 15px;
        font-size: 20px;
        font-weight: 700;
        flex-grow: 1;

        &.confirm {
            color: #fff;
            background-color: #0087cc;
        }
    }
}

.confirm-value {
    padding: 15px 0px;

    > .item {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        font-size: 18px;
        padding: 4px 0px;

        :not(.value) {
            font-size: 16px;
            color: #7c7c7c;
        }

        .value {
            color: #0087cc;
        }
    }
}

.tip {
    color: #0087cc;
    font-size: 0.875em;

    &.red {
        color: #dc2626;
    }
}

.break-line {
    height: 3px;
    width: 100%;
    background-image: linear-gradient(
        to right,
        #535353 0px 10px,
        rgba(255, 255, 255, 0) 10px 20px
    );
    max-width: 90%;
    margin: 16px auto;
    background-size: 20px 100%;
}
</style>
