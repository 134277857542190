<template>
    <div>
        <EventBanners></EventBanners>
        <div class="buyxnode-container">
            <div
                v-for="(item, index) in NodeList"
                :key="index"
                class="content"
                :style="colorCoding[index]"
            >
                <div
                    class="particle"
                    v-for="(particle, index) in particles"
                    :key="index"
                    :style="particle.style"
                ></div>
                <div class="title">
                    <div class="shape">
                        <div class="text">
                            <h1>{{ item.priceUSDT }} <span>USDT</span></h1>
                        </div>
                        <div class="shape-deco"></div>
                    </div>
                    <div class="shape-2"></div>
                    <div class="package">
                        <p>{{ item.name }}</p>
                    </div>
                </div>
                <div class="logo-node">
                    <div class="logo-wrap">
                        <div class="logo-wrap-2">
                            <div class="logo-wrap-3"></div>
                        </div>
                    </div>
                    <div class="logo">
                        <img :src="item.photoUrl" alt="xnode-img" />
                    </div>
                </div>
                <p class="year">
                    {{ item.quantityUseMonth }}
                    {{ item.quantityUseMonth > 1 ? 'Months' : 'Month' }}
                </p>
                <p class="mana">{{ item.performance }} Hz/h</p>
                <div
                    class="list"
                    v-if="item.content && item.content.length > 0"
                >
                    <div v-for="(point, bindex) in item.content" :key="bindex">
                        <svg
                            :style="{
                                color:
                                    index === 4
                                        ? '#FFF387'
                                        : index === 5
                                        ? '#EA7BFB'
                                        : 'white',
                            }"
                            v-if="point.status"
                            width="21"
                            height="21"
                            viewBox="0 0 21 21"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <circle
                                cx="10.5"
                                cy="10.5"
                                r="10"
                                stroke="currentColor"
                            />
                            <path
                                d="M5.75 10.75L9.25 14.25L16.25 6.75"
                                stroke="currentColor"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                        </svg>
                        <svg
                            :style="{
                                color:
                                    index === 4
                                        ? '#FFF387'
                                        : index === 5
                                        ? '#EA7BFB'
                                        : 'white',
                            }"
                            v-else
                            width="21"
                            height="21"
                            viewBox="0 0 21 21"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <circle
                                cx="10.5"
                                cy="10.5"
                                r="10"
                                stroke="currentColor"
                            />
                        </svg>
                        <p>{{ point.des }}</p>
                    </div>
                </div>
                <div class="but-wrap">
                    <button
                        class="button"
                        @click="setNode({ ...item, style: colorCoding[index] })"
                    >
                        {{ $t('buy') }}
                    </button>
                </div>
                <div class="particle-container">
                    <div v-for="i in 300" :key="i" class="particle"></div>
                </div>
                <div v-if="item.priceUSDT === 500" class="new-ribbon"></div>
            </div>
            <b-modal
                id="buy-node"
                hide-header
                hide-footer
                dialog-class="supa-modal"
                centered
                @hidden="resetChoice"
            >
                <svg
                    @click="closeBuyNode"
                    width="40"
                    height="40"
                    viewBox="0 0 40 40"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M32.5775 29.9235C32.9297 30.2757 33.1276 30.7534 33.1276 31.2516C33.1276 31.7497 32.9297 32.2275 32.5775 32.5797C32.2252 32.932 31.7475 33.1298 31.2493 33.1298C30.7512 33.1298 30.2735 32.932 29.9212 32.5797L20.0009 22.6563L10.0775 32.5766C9.72523 32.9288 9.24749 33.1267 8.74935 33.1267C8.2512 33.1267 7.77346 32.9288 7.42122 32.5766C7.06898 32.2244 6.87109 31.7466 6.87109 31.2485C6.87109 30.7503 7.06898 30.2726 7.42122 29.9203L17.3447 20L7.42435 10.0766C7.07211 9.72435 6.87422 9.24661 6.87422 8.74847C6.87422 8.25032 7.07211 7.77258 7.42435 7.42034C7.77659 7.0681 8.25433 6.87021 8.75247 6.87021C9.25061 6.87021 9.72836 7.0681 10.0806 7.42034L20.0009 17.3438L29.9243 7.41878C30.2766 7.06654 30.7543 6.86865 31.2525 6.86865C31.7506 6.86865 32.2284 7.06654 32.5806 7.41878C32.9328 7.77102 33.1307 8.24876 33.1307 8.7469C33.1307 9.24505 32.9328 9.72279 32.5806 10.075L22.6572 20L32.5775 29.9235Z"
                        fill="#0087CC"
                    />
                </svg>
                <div>
                    <h2>{{ $t('payment') }}</h2>
                    <!-- <h3>Package</h3> -->
                    <div class="partial-wrapper">
                        <div class="partial-item">
                            <div class="left">
                                <img :src="chosenNode.photoUrl" alt="" />
                            </div>
                            <div class="right">
                                <h1>
                                    {{ chosenNode.performance }}
                                    <span>Hz/h</span> -
                                    {{ chosenNode.quantityUseMonth }}
                                    {{
                                        chosenNode.quantityUseMonth > 1
                                            ? 'Months'
                                            : 'Month'
                                    }}
                                    - {{ chosenNode.quantityActionPerDay }} Mana
                                </h1>
                                <p
                                    v-if="chosenNode.style"
                                    :style="{
                                        color: chosenNode.style['--name-color'],
                                    }"
                                >
                                    {{ chosenNode.name }}
                                </p>
                                <div
                                    class="list"
                                    v-if="
                                        chosenNode.content &&
                                        chosenNode.content.length > 0
                                    "
                                >
                                    <div
                                        v-for="(
                                            point, index
                                        ) in chosenNode.content"
                                        :key="index"
                                    >
                                        <svg
                                            v-if="point.status"
                                            width="15"
                                            height="15"
                                            viewBox="0 0 15 15"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <circle
                                                cx="7.5"
                                                cy="7.5"
                                                r="7"
                                                stroke="white"
                                            />
                                            <path
                                                d="M4.10742 7.67855L6.60742 10.1786L11.6074 4.82141"
                                                stroke="white"
                                                stroke-width="1.5"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                            />
                                        </svg>
                                        <svg
                                            v-else
                                            width="15"
                                            height="15"
                                            viewBox="0 0 15 15"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <circle
                                                cx="7.5"
                                                cy="7.5"
                                                r="7"
                                                stroke="white"
                                            />
                                        </svg>
                                        <p>{{ point.des }}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        class="single-promo"
                        v-if="
                            SinglePromo &&
                            SinglePromo.totalSales < SinglePromo.quantity &&
                            chosenNode.nodeId === SinglePromo.nodeId
                        "
                    >
                        {{ SinglePromo.comment }}:
                        <span class="quantity">
                            {{ SinglePromo.totalSales }} /
                            {{ SinglePromo.quantity }}
                        </span>
                        <div class="promo-note">
                            <div>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="1em"
                                    height="1em"
                                    viewBox="0 0 24 24"
                                >
                                    <g
                                        fill="none"
                                        stroke="currentColor"
                                        stroke-width="1.5"
                                    >
                                        <path
                                            d="M10.577 8.704C11.21 7.568 11.527 7 12 7s.79.568 1.423 1.704l.164.294c.18.323.27.484.41.59c.14.107.316.147.665.226l.318.072c1.23.278 1.845.417 1.991.888c.147.47-.273.96-1.111 1.941l-.217.254c-.238.278-.357.418-.41.59c-.055.172-.037.358 0 .73l.032.338c.127 1.308.19 1.962-.193 2.253c-.383.29-.958.026-2.11-.504l-.298-.138c-.327-.15-.49-.226-.664-.226c-.173 0-.337.076-.664.226l-.298.138c-1.152.53-1.727.795-2.11.504c-.383-.29-.32-.945-.193-2.253l.032-.338c.037-.372.055-.558 0-.73c-.053-.172-.172-.312-.41-.59l-.217-.254c-.838-.98-1.258-1.47-1.111-1.941c.146-.47.76-.61 1.99-.888l.319-.072c.35-.08.524-.119.665-.225c.14-.107.23-.268.41-.59z"
                                        />
                                        <path
                                            stroke-linecap="round"
                                            d="M12 2v2m0 16v2M2 12h2m16 0h2"
                                            opacity="0.5"
                                        />
                                        <path
                                            stroke-linecap="round"
                                            d="m6 18l.343-.343M17.657 6.343L18 6m0 12l-.343-.343M6.343 6.343L6 6"
                                        />
                                    </g>
                                </svg>
                                {{ $t('oneTimePerAccount') }}
                            </div>
                            <div>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="1em"
                                    height="1em"
                                    viewBox="0 0 24 24"
                                >
                                    <g
                                        fill="none"
                                        stroke="currentColor"
                                        stroke-width="1.5"
                                    >
                                        <path
                                            d="M10.577 8.704C11.21 7.568 11.527 7 12 7s.79.568 1.423 1.704l.164.294c.18.323.27.484.41.59c.14.107.316.147.665.226l.318.072c1.23.278 1.845.417 1.991.888c.147.47-.273.96-1.111 1.941l-.217.254c-.238.278-.357.418-.41.59c-.055.172-.037.358 0 .73l.032.338c.127 1.308.19 1.962-.193 2.253c-.383.29-.958.026-2.11-.504l-.298-.138c-.327-.15-.49-.226-.664-.226c-.173 0-.337.076-.664.226l-.298.138c-1.152.53-1.727.795-2.11.504c-.383-.29-.32-.945-.193-2.253l.032-.338c.037-.372.055-.558 0-.73c-.053-.172-.172-.312-.41-.59l-.217-.254c-.838-.98-1.258-1.47-1.111-1.941c.146-.47.76-.61 1.99-.888l.319-.072c.35-.08.524-.119.665-.225c.14-.107.23-.268.41-.59z"
                                        />
                                        <path
                                            stroke-linecap="round"
                                            d="M12 2v2m0 16v2M2 12h2m16 0h2"
                                            opacity="0.5"
                                        />
                                        <path
                                            stroke-linecap="round"
                                            d="m6 18l.343-.343M17.657 6.343L18 6m0 12l-.343-.343M6.343 6.343L6 6"
                                        />
                                    </g>
                                </svg>
                                {{ $t('onlyAppliesForUSDTPayment') }}
                            </div>
                        </div>
                    </div>

                    <div class="confirmation" :style="chosenNode.style">
                        <div class="use-voucher" v-if="chosenNode.nodeId < 4">
                            <div>
                                <svg
                                    width="60"
                                    height="60"
                                    viewBox="0 0 60 60"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M10 10C8.67392 10 7.40215 10.5268 6.46447 11.4645C5.52678 12.4021 5 13.6739 5 15V25C6.32608 25 7.59785 25.5268 8.53553 26.4645C9.47322 27.4021 10 28.6739 10 30C10 31.3261 9.47322 32.5979 8.53553 33.5355C7.59785 34.4732 6.32608 35 5 35V45C5 46.3261 5.52678 47.5979 6.46447 48.5355C7.40215 49.4732 8.67392 50 10 50H50C51.3261 50 52.5979 49.4732 53.5355 48.5355C54.4732 47.5979 55 46.3261 55 45V35C53.6739 35 52.4021 34.4732 51.4645 33.5355C50.5268 32.5979 50 31.3261 50 30C50 28.6739 50.5268 27.4021 51.4645 26.4645C52.4021 25.5268 53.6739 25 55 25V15C55 13.6739 54.4732 12.4021 53.5355 11.4645C52.5979 10.5268 51.3261 10 50 10H10ZM38.75 17.5L42.5 21.25L21.25 42.5L17.5 38.75L38.75 17.5ZM22.025 17.6C24.475 17.6 26.45 19.575 26.45 22.025C26.45 23.1986 25.9838 24.3241 25.1539 25.1539C24.3241 25.9838 23.1986 26.45 22.025 26.45C19.575 26.45 17.6 24.475 17.6 22.025C17.6 20.8514 18.0662 19.7259 18.8961 18.8961C19.7259 18.0662 20.8514 17.6 22.025 17.6ZM37.975 33.55C40.425 33.55 42.4 35.525 42.4 37.975C42.4 39.1486 41.9338 40.2741 41.104 41.104C40.2741 41.9338 39.1486 42.4 37.975 42.4C35.525 42.4 33.55 40.425 33.55 37.975C33.55 36.8014 34.0162 35.6759 34.8461 34.8461C35.6759 34.0162 36.8014 33.55 37.975 33.55Z"
                                        fill="#0087CC"
                                    />
                                </svg>
                                <p>XNode Voucher</p>
                            </div>
                            <div>
                                <button @click="openVoucherModal">
                                    {{ $t('seemore') }}
                                    <svg
                                        width="14"
                                        height="14"
                                        viewBox="0 0 14 14"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M4.375 3.51094L5.2582 2.625L9.625 7L5.2582 11.375L4.375 10.4891L7.85586 7L4.375 3.51094Z"
                                            fill="white"
                                        />
                                    </svg>
                                </button>
                            </div>
                        </div>
                        <div class="detail">
                            <h3>{{ $t('detail') }}</h3>
                            <div
                                v-if="
                                    selectedVoucher &&
                                    selectedVoucher.voucherSetting
                                "
                                class="evoucher"
                            >
                                <span>E-Voucher: </span>
                                <span>{{
                                    selectedVoucher.voucherSetting.name
                                }}</span>
                            </div>
                            <div class="total">
                                {{ $t('total') }}
                                <span
                                    v-if="
                                        selectedVoucher &&
                                        selectedVoucher.voucherSetting &&
                                        this.currency === 'XIN'
                                    "
                                >
                                    {{ chosenNode.name }} -
                                    {{
                                        roundUpFormat(
                                            chosenNode.priceXin -
                                                chosenNode.priceXin *
                                                    selectedVoucher
                                                        .voucherSetting.amount,
                                        )
                                    }}
                                    {{ this.currency }}
                                </span>
                                <span
                                    v-else-if="
                                        selectedVoucher &&
                                        selectedVoucher.voucherSetting &&
                                        this.currency === 'USDT'
                                    "
                                >
                                    {{ chosenNode.name }} -
                                    {{
                                        roundUpFormat(
                                            chosenNode.priceUSDT -
                                                chosenNode.priceUSDT *
                                                    selectedVoucher
                                                        .voucherSetting.amount,
                                        )
                                    }}
                                    {{ this.currency }}
                                </span>
                                <span
                                    v-else-if="
                                        selectedVoucher &&
                                        selectedVoucher.voucherSetting &&
                                        this.currency === '50-50'
                                    "
                                >
                                    {{ chosenNode.name }} -
                                    <span>
                                        {{
                                            roundUpFormat(
                                                (chosenNode.priceXin / 2) *
                                                    (1 -
                                                        selectedVoucher
                                                            .voucherSetting
                                                            .amount),
                                            )
                                        }}
                                        XIN
                                    </span>
                                    {{ $t('and') }}
                                    <span>
                                        {{
                                            roundUpFormat(
                                                (chosenNode.priceUSDT / 2) *
                                                    (1 -
                                                        selectedVoucher
                                                            .voucherSetting
                                                            .amount),
                                            )
                                        }}
                                        USDT
                                    </span>
                                </span>
                                <span v-else-if="this.currency === 'XIN'">
                                    {{ chosenNode.name }} -
                                    {{ roundUpFormat(chosenNode.priceXin) }}
                                    {{ this.currency }}
                                </span>
                                <span v-else-if="this.currency === '50-50'">
                                    {{ chosenNode.name }} -
                                    <span>
                                        {{
                                            roundUpFormat(
                                                chosenNode.priceXin / 2,
                                            )
                                        }}
                                        XIN
                                    </span>
                                    and
                                    <span>
                                        {{
                                            roundUpFormat(
                                                chosenNode.priceUSDT / 2,
                                            )
                                        }}
                                        USDT
                                    </span>
                                </span>
                                <span v-else class="price">
                                    {{ chosenNode.name }} -
                                    {{ roundUpFormat(chosenNode.priceUSDT) }}
                                    {{ this.currency }}
                                </span>
                            </div>
                        </div>
                        <div class="botans">
                            <button
                                class="confirm"
                                :disabled="pending"
                                @click="openBuyNode"
                            >
                                {{ $t('continuepayment') }}
                            </button>
                        </div>
                    </div>
                </div>
            </b-modal>
            <b-modal
                id="congratulations"
                hide-header
                hide-footer
                centered
                dialog-class="supa-modal"
                @hidden="
                    () => {
                        boughtNode = {};
                    }
                "
            >
                <div>
                    <div class="mi-head">{{ $t('buyXNodeSuccess') }}!</div>
                    <div class="mi-sub">
                        {{ $t('buyNodeSuccess', [boughtNode.performance]) }}
                    </div>
                    <div class="botans">
                        <button
                            class="cancel"
                            @click="
                                $router.push({ name: 'XnodeInventory' }),
                                    $bvModal.hide('congratulations')
                            "
                        >
                            {{ $t('goToMyXNode') }}
                        </button>
                        <button
                            class="confirm"
                            @click="$bvModal.hide('congratulations')"
                        >
                            {{ $t('buyMore') }}
                        </button>
                    </div>
                    <button
                        class="close black"
                        @click="$bvModal.hide('congratulations')"
                        type="button"
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="1em"
                            height="1em"
                            viewBox="0 0 24 24"
                        >
                            <path
                                fill="currentColor"
                                d="M6.4 19L5 17.6l5.6-5.6L5 6.4L6.4 5l5.6 5.6L17.6 5L19 6.4L13.4 12l5.6 5.6l-1.4 1.4l-5.6-5.6z"
                            />
                        </svg>
                    </button>
                </div>
            </b-modal>
            <b-modal
                id="ohno"
                hide-header
                hide-footer
                centered
                dialog-class="supa-modal"
            >
                <div>
                    <div class="mi-head fail">{{ $t('uhOh') }}!</div>
                    <div class="mi-sub">{{ $t('failedToBuyXNode') }}</div>
                    <div class="botans fail">
                        <button class="confirm" @click="$bvModal.hide('ohno')">
                            {{ $t('buyMore') }}
                        </button>
                    </div>
                    <button
                        class="close black"
                        @click="$bvModal.hide('ohno')"
                        type="button"
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="1em"
                            height="1em"
                            viewBox="0 0 24 24"
                        >
                            <path
                                fill="currentColor"
                                d="M6.4 19L5 17.6l5.6-5.6L5 6.4L6.4 5l5.6 5.6L17.6 5L19 6.4L13.4 12l5.6 5.6l-1.4 1.4l-5.6-5.6z"
                            />
                        </svg>
                    </button>
                </div>
            </b-modal>
            <b-modal
                id="voucher-list"
                title="Voucher List"
                hide-header
                hide-footer
                centered
            >
                <svg
                    class="voucher-close"
                    @click="$bvModal.hide('voucher-list')"
                    xmlns="http://www.w3.org/2000/svg"
                    width="1em"
                    height="1em"
                    viewBox="0 0 1024 1024"
                >
                    <path
                        fill="currentColor"
                        d="M224 480h640a32 32 0 1 1 0 64H224a32 32 0 0 1 0-64"
                    />
                    <path
                        fill="currentColor"
                        d="m237.248 512l265.408 265.344a32 32 0 0 1-45.312 45.312l-288-288a32 32 0 0 1 0-45.312l288-288a32 32 0 1 1 45.312 45.312z"
                    />
                </svg>
                <h2>Choose Voucher</h2>
                <!-- {{ UserVoucherList }} -->
                <div class="user-voucher-container">
                    <h3>E-Voucher</h3>
                    <p>Only choose 1 voucher</p>
                    <div
                        class="user-voucher-list"
                        v-if="
                            FilteredUserVoucherList.results &&
                            FilteredUserVoucherList.results.length > 0
                        "
                    >
                        <div
                            class="voucher"
                            v-for="voucher in FilteredUserVoucherList.results"
                            :key="voucher.id"
                        >
                            <div class="branding">
                                <img src="@/assets/images/logo/logov4.png" />
                            </div>
                            <div class="content">
                                <div>
                                    <div class="tit">
                                        {{ voucher.voucherSetting.name }}
                                    </div>
                                    <div>
                                        <span>For every package</span>
                                    </div>
                                    <div class="prize">
                                        <div>
                                            {{ voucher.voucherSetting.comment }}
                                        </div>
                                    </div>
                                </div>
                                <input
                                    v-if="voucher.status === 0"
                                    @click="selectedVoucher = voucher"
                                    type="radio"
                                    name="radio"
                                />
                            </div>
                        </div>
                        <Paginate
                            @current="onChangePage"
                            :totalPages="UserVoucherList.totalPages"
                        />
                    </div>
                    <div class="no-voucher" v-else>
                        <h3>{{ $t('noVoucher') }}</h3>
                    </div>
                </div>
                <div class="button">
                    <button @click="useVoucher">Accept</button>
                </div>
            </b-modal>
            <b-modal id="buynode-confirm" centered hide-footer hide-header>
                <svg
                    @click="$bvModal.hide('buynode-confirm')"
                    xmlns="http://www.w3.org/2000/svg"
                    width="1em"
                    height="1em"
                    viewBox="0 0 24 24"
                >
                    <path
                        fill="currentColor"
                        d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12z"
                    />
                </svg>
                <h2>{{ $t('buynode') }}</h2>
                <h3>{{ $t('areyousure') }} {{ chosenNode.name }}?</h3>
                <div class="payment">
                    <p>{{ $t('selectcurrency') }}:</p>
                    <div class="unit">
                        <div
                            :class="{
                                active: currency === 'XIN',
                                inactive: currency !== 'XIN',
                            }"
                            @click="setCurrency('XIN')"
                        >
                            XIN
                        </div>
                        <div
                            :class="{
                                active: currency === 'USDT',
                                inactive: currency !== 'USDT',
                            }"
                            @click="setCurrency('USDT')"
                        >
                            USDT
                        </div>
                        <div
                            :class="{
                                active: currency === '50-50',
                                inactive: currency !== '50-50',
                            }"
                            @click="setCurrency('50-50')"
                        >
                            XIN - USDT
                        </div>
                    </div>
                </div>
                <div v-if="this.currency === '50-50'" class="note">
                    {{ $t('notepay') }}
                </div>
                <p class="content">
                    {{ $t('proceedtobuy') }}
                    <strong>
                        <span
                            v-if="
                                selectedVoucher &&
                                selectedVoucher.voucherSetting &&
                                this.currency === 'XIN'
                            "
                        >
                            {{ chosenNode.name }} -
                            {{
                                roundUpFormat(
                                    chosenNode.priceXin -
                                        chosenNode.priceXin *
                                            selectedVoucher.voucherSetting
                                                .amount,
                                )
                            }}
                            {{ this.currency }}
                        </span>
                        <span
                            v-else-if="
                                selectedVoucher &&
                                selectedVoucher.voucherSetting &&
                                this.currency === 'USDT'
                            "
                        >
                            {{ chosenNode.name }} -
                            {{
                                roundUpFormat(
                                    chosenNode.priceUSDT -
                                        chosenNode.priceUSDT *
                                            selectedVoucher.voucherSetting
                                                .amount,
                                )
                            }}
                            {{ this.currency }}
                        </span>
                        <span
                            v-else-if="
                                selectedVoucher &&
                                selectedVoucher.voucherSetting &&
                                this.currency === '50-50'
                            "
                        >
                            {{ chosenNode.name }} -
                            <span>
                                {{
                                    roundUpFormat(
                                        (chosenNode.priceXin / 2) *
                                            (1 -
                                                selectedVoucher.voucherSetting
                                                    .amount),
                                    )
                                }}
                                XIN
                            </span>
                            {{ $t('and') }}
                            <span>
                                {{
                                    roundUpFormat(
                                        (chosenNode.priceUSDT / 2) *
                                            (1 -
                                                selectedVoucher.voucherSetting
                                                    .amount),
                                    )
                                }}
                                USDT
                            </span>
                        </span>
                        <span v-else-if="this.currency === 'XIN'">
                            {{ chosenNode.name }} -
                            {{ roundUpFormat(chosenNode.priceXin) }}
                            {{ this.currency }}
                        </span>
                        <span v-else-if="this.currency === '50-50'">
                            {{ chosenNode.name }} -
                            <span>
                                {{ roundUpFormat(chosenNode.priceXin / 2) }}
                                XIN
                            </span>
                            and
                            <span>
                                {{ roundUpFormat(chosenNode.priceUSDT / 2) }}
                                USDT
                            </span>
                        </span>
                        <span v-else class="price">
                            {{ chosenNode.name }} -
                            {{ roundUpFormat(chosenNode.priceUSDT) }}
                            {{ this.currency }}
                        </span>
                    </strong>
                    ?
                </p>
                <div class="buttons">
                    <span @click="buyNode">{{ $t('confirm') }}</span>
                    <span @click="$bvModal.hide('buynode-confirm')">{{
                        $t('cancel')
                    }}</span>
                </div>
            </b-modal>
        </div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex';
import EventBanners from '@/components/shared/EventBanners.vue';
import Paginate from '@/components/shared/Paginate.vue';

export default {
    name: 'BuyXnode',
    components: { Paginate, EventBanners },
    data() {
        return {
            colorCoding: [
                {
                    '--name-color': '#F3C862',
                    '--gradient':
                        'linear-gradient(to right, #FCE965, #FAEB83, #FFCB00, #FDEC84)',
                },
                {
                    '--name-color': '#d4d4d4',
                    '--gradient':
                        'linear-gradient(to right, #d8d8d8, #ffffff, #dddddd)',
                },
                {
                    '--name-color': '#d99a26',
                    '--gradient':
                        'linear-gradient(to right, #f6ce69, #d99a26, #f6ce69)',
                },
                {
                    '--name-color': '#dfdfdf',
                    '--gradient':
                        'linear-gradient(to right, #e9e9e9, #b8b8b8, #e3e3e3)',
                },
                {
                    '--name-color': '#fff778',
                    '--gradient':
                        'linear-gradient(to right, #fff778, #fffeea, #fff778)',
                },
                {
                    '--name-color': '#dc78ff',
                    '--gradient':
                        'linear-gradient(to right, #ec78ff, #9875fb, #78f7ff)',
                },
            ],
            chosenNode: {},
            boughtNode: {},
            pending: false,
            voucherId: null,
            particles: [],
            containerWidth: 0,
            containerHeight: 0,
            selectedVoucher: null,
            currency: 'USDT',
            page: 1,
        };
    },
    computed: {
        ...mapGetters({
            NodeList: 'mining/NodeList',
            UserVoucherList: 'voucher/UserVoucherList',
            FilteredUserVoucherList: 'voucher/FilteredUserVoucherList',
            Rates: 'wallet/Rates',
            SinglePromo: 'promotion/SinglePromo',
        }),
        // displayCommentVoucher() {
        //     this.UserVoucherList.voucherSetting.find(el => el.comment).comment
        // }
    },
    methods: {
        setNode(node) {
            this.chosenNode = node;
            this.$bvModal.show('buy-node');
        },
        resetChoice() {
            this.chosenNode = {};
            this.selectedVoucher = null;
            this.voucherId = null;
        },
        async buyNode() {
            if (this.pending) {
                return;
            }
            this.pending = true;

            await this.$recaptchaLoaded();
            const token = await this.$recaptcha('buyXNode')

            if (this.voucherId) {
                if (this.currency === '50-50') {
                    this.$store
                        .dispatch('mining/buyNodeXinUsdt', {
                            nodeId: this.chosenNode.nodeId,
                            voucher: this.voucherId,
                            token
                        })
                        .then((res) => {
                            if (res) {
                                this.boughtNode = { ...this.chosenNode };
                                this.$bvModal.hide('buy-node');
                                this.$bvModal.hide('buynode-confirm');
                                this.$bvModal.show('congratulations');
                                this.$store.dispatch('info/get_aboutXnode');
                                this.$store.dispatch('info/req_getInfo');
                            } else {
                                this.$bvModal.hide('buy-node');
                                this.$bvModal.hide('buynode-confirm');
                                this.$bvModal.show('ohno');
                            }
                            this.$store.dispatch(
                                'voucher/userVoucher',
                                this.page,
                            );
                        });
                } else {
                    this.$store
                        .dispatch('mining/post_buyNode', {
                            nodeId: this.chosenNode.nodeId,
                            voucher: this.voucherId,
                            currency:
                                this.currency === 'XIN' ? 'xinCoin' : 'USDT',
                                token
                        })
                        .then((res) => {
                            if (res) {
                                this.boughtNode = { ...this.chosenNode };
                                this.$bvModal.hide('buy-node');
                                this.$bvModal.hide('buynode-confirm');
                                this.$bvModal.show('congratulations');
                                this.$store.dispatch('info/get_aboutXnode');
                                this.$store.dispatch('info/req_getInfo');
                            } else {
                                this.$bvModal.hide('buy-node');
                                this.$bvModal.hide('buynode-confirm');
                                this.$bvModal.show('ohno');
                            }
                            this.$store.dispatch(
                                'voucher/userVoucher',
                                this.page,
                            );
                        })
                        .finally(() => {
                            this.pending = false;
                        });
                }
            } else if (this.currency === '50-50') {
                this.$store
                    .dispatch('mining/buyNodeXinUsdt', {
                        nodeId: this.chosenNode.nodeId,
                        token
                    })
                    .then((res) => {
                        if (res) {
                            this.boughtNode = { ...this.chosenNode };
                            this.$bvModal.hide('buy-node');
                            this.$bvModal.hide('buynode-confirm');
                            this.$bvModal.show('congratulations');
                            this.$store.dispatch('info/get_aboutXnode');
                            this.$store.dispatch('info/req_getInfo');
                        } else {
                            this.$bvModal.hide('buy-node');
                            this.$bvModal.hide('buynode-confirm');
                            this.$bvModal.show('ohno');
                        }
                        this.$store.dispatch('voucher/userVoucher', this.page);
                    });
            } else {
                this.$store
                    .dispatch('mining/post_buyNode', {
                        nodeId: this.chosenNode.nodeId,
                        currency: this.currency === 'XIN' ? 'xinCoin' : 'USDT',
                        token
                    })
                    .then((res) => {
                        if (res) {
                            this.boughtNode = { ...this.chosenNode };
                            this.$bvModal.hide('buy-node');
                            this.$bvModal.hide('buynode-confirm');
                            this.$bvModal.show('congratulations');
                            this.$store.dispatch('info/get_aboutXnode');
                            this.$store.dispatch('info/req_getInfo');
                            this.$store.dispatch('promotion/get5kPromo');
                        } else {
                            this.$bvModal.hide('buy-node');
                            this.$bvModal.hide('buynode-confirm');
                            this.$bvModal.show('ohno');
                        }
                        this.$store.dispatch('voucher/userVoucher', this.page);
                    })
                    .finally(() => {
                        this.pending = false;
                    });
            }
        },
        openBuyNode() {
            this.$bvModal.show('buynode-confirm');
            // this.$bvModal.hide('buy-node')
        },
        openVoucherModal() {
            this.$bvModal.show('voucher-list');
        },

        useVoucher() {
            this.voucherId = this.selectedVoucher._id;
            this.$bvModal.hide('voucher-list');
        },
        closeBuyNode() {
            this.$bvModal.hide('buy-node');
            this.selectedVoucher = null;
            this.voucherId = null;
        },
        onChangePage(payload) {
            this.page = payload;
            // this.$store.dispatch('voucher/filteredUserVoucher', {page: this.page , page: 'DISCOUNT_BUY_NODE'})
            this.$store.dispatch('voucher/userVoucher', { page: this.page });
        },

        setCurrency(input) {
            this.currency = input;
        },
    },
    created() {
        this.unsubscribe = this.$store.subscribe((mutation, state) => {
            const gotNode = this.NodeList.find((el) => el.nodeId === 4);
            const gotNodeIndex = this.NodeList.findIndex(
                (el) => el.nodeId === 4,
            );
            // eslint-disable-next-line default-case
            switch (mutation.type) {
                case 'promotion/action5kPromo':
                    this.setNode({
                        ...gotNode,
                        style: this.colorCoding[gotNodeIndex],
                    });

                    break;
            }
        });
    },
    mounted() {
        this.$store.dispatch('voucher/filteredUserVoucher', {
            page: this.page,
            type: 'DISCOUNT_BUY_NODE',
        });
        this.$store.dispatch('voucher/userVoucher', { page: this.page });
        this.$store.dispatch('voucher/voucherType');
    },
};
</script>

<style scoped lang="scss">
.buyxnode-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 2rem;
    padding-top: 15px;

    p {
        margin: 0;
    }

    .content {
        flex: 1 0 30%;
        max-width: 420px;
        background-color: #2e77a0;
        min-height: 600px;
        border-radius: 0.75rem;
        padding-bottom: 1rem;
        --name-color: #fff;
        --gradient: linear-gradient(#fce965, #faeb83, #ffcb00, #fdec84);
        display: flex;
        flex-direction: column;
        position: relative;

        .title {
            position: relative;
            border-top-left-radius: 0.75rem;
            border-top-right-radius: 0.75rem;
            padding-top: 2rem;
            padding-bottom: 7rem;
            max-width: 100%;

            .shape {
                width: 100%;
                min-height: 8rem;
                position: absolute;
                background: #0075b1;
                border-top-left-radius: 0.75rem;
                border-top-right-radius: 0.75rem;
                clip-path: polygon(
                    0% 0%,
                    100% 0%,
                    100% 100%,
                    90% 100%,
                    80% 50%,
                    20% 50%,
                    10% 100%,
                    0% 100%
                );
                top: 0;
                z-index: 10;

                .text {
                    padding-top: 10px;

                    h1 {
                        font-size: 34px;
                        font-weight: 600;
                        color: #ffffff;
                        text-align: center;

                        span {
                            font-size: 24px;
                        }
                    }
                }

                .shape-deco {
                    position: absolute;
                    top: 43%;
                    left: 20%;
                    width: 60%;
                    height: 0.5rem;
                    background: linear-gradient(
                        90deg,
                        rgba(0, 117, 177, 0.5),
                        rgba(255, 255, 255, 0.5),
                        rgba(0, 117, 177, 0.5)
                    );
                }
            }

            .shape-2 {
                position: absolute;
                clip-path: polygon(
                    0% 0%,
                    100% 0%,
                    100% 100%,
                    89% 100%,
                    79% 56%,
                    21% 56%,
                    11% 100%,
                    0% 100%
                );
                z-index: 9;
                border-top-left-radius: 0.75rem;
                border-top-right-radius: 0.75rem;
                background: linear-gradient(
                    to bottom,
                    rgb(16, 86, 177),
                    rgb(15, 53, 109)
                );
                width: 100%;
                min-height: 9rem;
                top: 0;
            }

            .package {
                width: 100%;
                position: absolute;
                background: linear-gradient(
                    rgba(7, 44, 84, 1),
                    rgba(18, 73, 132, 1)
                );
                z-index: 8;
                clip-path: polygon(
                    0% 0%,
                    100% 0%,
                    100% 100%,
                    89% 100%,
                    81% 50%,
                    75% 100%,
                    25% 100%,
                    19% 50%,
                    11% 100%,
                    0% 100%
                );
                min-height: 6rem;
                display: flex;
                justify-content: center;
                align-items: flex-end;

                p {
                    font-size: 24px;
                    font-weight: 700;
                    color: var(--name-color);
                    padding-bottom: 5px;
                    @media (min-width: 1200px) {
                        font-size: 16px;
                    }
                }
            }
        }

        .logo-node {
            position: relative;
            text-align: center;
            display: flex;
            justify-content: center;
            align-items: center;
            filter: drop-shadow(0px 0px 12px rgb(27, 32, 38));

            .logo-wrap {
                width: 220px;
                height: 200px;
                margin: 0 auto;
                padding: 1rem;
                background: linear-gradient(
                    rgba(9, 43, 81, 1),
                    rgba(18, 74, 132, 1)
                );
                clip-path: polygon(
                    25% 0,
                    75% 0%,
                    100% 50%,
                    75% 100%,
                    25% 100%,
                    0% 50%
                );
                border-radius: 8px;
                box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
                    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;

                .logo-wrap-2 {
                    width: 100%;
                    height: 100%;
                    padding: 0.5rem;
                    background: rgba(0, 135, 204, 1);
                    clip-path: polygon(
                        25% 0,
                        75% 0%,
                        100% 50%,
                        75% 100%,
                        25% 100%,
                        0% 50%
                    );

                    .logo-wrap-3 {
                        height: 100%;
                        background: linear-gradient(
                            rgba(9, 43, 81, 1),
                            rgba(18, 74, 132, 1)
                        );
                        clip-path: polygon(
                            25% 0,
                            75% 0%,
                            100% 50%,
                            75% 100%,
                            25% 100%,
                            0% 50%
                        );
                        position: relative;
                        z-index: 6;
                    }
                }
            }

            .logo {
                height: 150px;
                position: absolute;

                img {
                  object-fit: contain;
                    width: 100%;
                    height: 100%;
                    margin: auto;
                }
            }
        }

        .year,
        .mana {
            margin-top: 1rem;
            font-size: 24px;
            font-weight: 600;
            color: #fff;
            text-align: center;
        }

        .list {
            padding: 1rem;
            display: flex;
            flex-direction: column;
            gap: 0.5rem;
            margin-bottom: 3rem;

            div {
                display: flex;
                align-items: center;
                gap: 0.5rem;
                font-size: 14px;
                font-weight: 400;
                color: #000000;
            }
        }

        .but-wrap {
            flex-grow: 1;
            text-align: center;
            display: flex;
            align-items: flex-end;
        }

        .button {
            font-size: 15px;
            font-weight: 700;
            text-align: center;
            width: 90%;
            margin: 0 auto;
            padding: 1rem;
            border-radius: 72px;
            cursor: pointer;
            background-image: var(--gradient);
            outline: none;
            transition: 0.2s;

            &:hover {
                opacity: 0.8;
            }
        }

        &:not(:first-child) {
            background-image: url('../../assets/images/background/node-bg.png');
            background-repeat: no-repeat;
            background-size: contain;
            background-position: 100%;
        }

        // &:nth-child(5) {
        //     background-color: #161616;
        //     z-index: 999;

        //     .title {
        //         position: relative;
        //         border-top-left-radius: 0.75rem;
        //         border-top-right-radius: 0.75rem;
        //         padding-top: 2rem;
        //         padding-bottom: 7rem;
        //         max-width: 100%;

        //         .shape {
        //             width: 100%;
        //             min-height: 8rem;
        //             position: absolute;
        //             background: linear-gradient(#fff778, #fffeea, #fff778);
        //             border-top-left-radius: 0.75rem;
        //             border-top-right-radius: 0.75rem;
        //             clip-path: polygon(
        //                 0% 0%,
        //                 100% 0%,
        //                 100% 100%,
        //                 90% 100%,
        //                 80% 50%,
        //                 20% 50%,
        //                 10% 100%,
        //                 0% 100%
        //             );
        //             top: 0;
        //             z-index: 10;
        //             .text {
        //                 padding-top: 10px;
        //                 h1 {
        //                     font-size: 34px;
        //                     font-weight: 600;
        //                     color: #000;
        //                     text-align: center;

        //                     span {
        //                         font-size: 24px;
        //                     }
        //                 }
        //             }

        //             .shape-deco {
        //                 background: linear-gradient(
        //                     90deg,
        //                     rgba(255, 254, 229, 1),
        //                     rgba(253, 246, 133, 1),
        //                     rgba(255, 254, 229, 1)
        //                 );
        //             }
        //         }

        //         .shape-2 {
        //             position: absolute;
        //             clip-path: polygon(
        //                 0% 0%,
        //                 100% 0%,
        //                 100% 100%,
        //                 89% 100%,
        //                 79% 56%,
        //                 21% 56%,
        //                 11% 100%,
        //                 0% 100%
        //             );
        //             z-index: 9;
        //             border-top-left-radius: 0.75rem;
        //             border-top-right-radius: 0.75rem;
        //             background: rgba(49, 49, 49, 1);
        //             width: 100%;
        //             min-height: 9rem;
        //             top: 0;
        //         }

        //         .package {
        //             background: rgba(36, 35, 35, 1);
        //         }
        //     }

        //     .logo-node {
        //         position: relative;
        //         text-align: center;
        //         height: 100%;
        //         filter: drop-shadow(0px 0px 12px rgba(255, 247, 120, 0.6));

        //         .logo-wrap {
        //             background: linear-gradient(
        //                 rgba(21, 21, 21),
        //                 rgba(67, 67, 67)
        //             );

        //             .logo-wrap-2 {
        //                 background: linear-gradient(
        //                     90deg,
        //                     rgba(255, 247, 120, 1),
        //                     rgba(255, 255, 255, 1),
        //                     rgba(255, 247, 120, 1)
        //                 );
        //                 transition: 0.2s;
        //                 // animation: rotateHexa 3s forwards infinite;

        //                 .logo-wrap-3 {
        //                     background: linear-gradient(
        //                         rgba(21, 21, 21),
        //                         rgba(67, 67, 67)
        //                     );
        //                 }
        //             }
        //         }

        //         .logo {
        //             img {
        //                 max-width: 100%;
        //             }
        //         }
        //     }

        //     .year,
        //     .mana {
        //         color: var(--name-color);
        //         text-align: center;
        //     }

        //     .list {
        //         div {
        //             color: #fff;
        //         }
        //     }

        //     .particle-container {
        //         width: 100%;
        //         height: 100%;
        //         position: absolute;
        //         bottom: 0;
        //         z-index: -999;
        //         overflow: hidden;

        //         .particle {
        //             border-radius: 50%;
        //         }

        //         @for $i from 1 through 150 {
        //             @keyframes particle-animation-#{$i} {
        //                 0% {
        //                     transform: translate3d(
        //                         (random(50) * 100%),
        //                         (random(100) * 100%),
        //                         (random(100) * 1px)
        //                     );
        //                 }

        //                 100% {
        //                     transform: translate3d(
        //                         (random(50) * 100%),
        //                         (random(100) * -100%),
        //                         (random(100) * 1px)
        //                     );
        //                 }
        //             }

        //             .particle:nth-child(#{$i}) {
        //                 animation: particle-animation-#{$i} 30s linear infinite;
        //                 $size: random(5) + 2 + px;
        //                 opacity: calc(random(100) / 100);
        //                 height: $size;
        //                 width: $size;
        //                 animation-delay: -$i * 0.02s;
        //                 transform: translate3d(
        //                     (random(100) * 50%),
        //                     (random(100) * 50%),
        //                     (random(100) * 1px)
        //                 );
        //                 background: linear-gradient(#fff387, #999251);
        //             }
        //         }
        //     }
        // }

        // &:nth-child(6) {
        //     background-color: #161616;
        //     box-shadow: 0px 0px 25px #ec78ff;
        //     border: 1px solid #ec78ff;
        //     z-index: 999;

        //     .title {
        //         position: relative;
        //         border-top-left-radius: 0.75rem;
        //         border-top-right-radius: 0.75rem;
        //         padding-top: 2rem;
        //         padding-bottom: 7rem;
        //         max-width: 100%;

        //         .shape {
        //             width: 100%;
        //             min-height: 8rem;
        //             position: absolute;
        //             background: linear-gradient(#ec78ff, #9875fb, #78f7ff);
        //             border-top-left-radius: 0.75rem;
        //             border-top-right-radius: 0.75rem;
        //             clip-path: polygon(
        //                 0% 0%,
        //                 100% 0%,
        //                 100% 100%,
        //                 90% 100%,
        //                 80% 50%,
        //                 20% 50%,
        //                 10% 100%,
        //                 0% 100%
        //             );
        //             top: 0;
        //             z-index: 10;
        //             > div {
        //                 padding-top: 10px;
        //                 h1 {
        //                     font-size: 34px;
        //                     font-weight: 600;
        //                     color: #000;
        //                     text-align: center;

        //                     span {
        //                         font-size: 24px;
        //                     }
        //                 }
        //             }

        //             .shape-deco {
        //                 background: linear-gradient(
        //                     90deg,
        //                     rgba(236, 121, 254, 1),
        //                     rgba(128, 240, 253, 1),
        //                     rgba(236, 121, 254, 1)
        //                 );
        //             }
        //         }

        //         .shape-2 {
        //             position: absolute;
        //             clip-path: polygon(
        //                 0% 0%,
        //                 100% 0%,
        //                 100% 100%,
        //                 89% 100%,
        //                 79% 56%,
        //                 21% 56%,
        //                 11% 100%,
        //                 0% 100%
        //             );
        //             z-index: 9;
        //             border-top-left-radius: 0.75rem;
        //             border-top-right-radius: 0.75rem;
        //             background: rgba(49, 49, 49, 1);
        //             width: 100%;
        //             min-height: 9rem;
        //             top: 0;
        //         }

        //         .package {
        //             background: rgba(36, 35, 35, 1);
        //         }
        //     }

        //     .logo-node {
        //         position: relative;
        //         text-align: center;
        //         height: 100%;
        //         filter: drop-shadow(0px 0px 12px rgba(236, 121, 254, 0.5));

        //         .logo-wrap {
        //             background: linear-gradient(
        //                 rgba(21, 21, 21),
        //                 rgba(67, 67, 67)
        //             );

        //             .logo-wrap-2 {
        //                 background: linear-gradient(
        //                     90deg,
        //                     rgba(236, 121, 254, 1),
        //                     rgba(122, 246, 253, 1),
        //                     rgba(236, 121, 254, 1)
        //                 );

        //                 .logo-wrap-3 {
        //                     background: linear-gradient(
        //                         rgba(21, 21, 21),
        //                         rgba(67, 67, 67)
        //                     );
        //                 }
        //             }
        //         }

        //         .logo {
        //             margin-bottom: 3.5rem;
        //             img {
        //                 max-width: 100%;
        //             }
        //         }
        //     }

        //     .year,
        //     .mana {
        //         color: var(--name-color);
        //         text-align: center;
        //     }

        //     .list {
        //         div {
        //             color: #fff;
        //         }
        //     }

        //     .particle-container {
        //         width: 100%;
        //         height: 100%;
        //         position: absolute;
        //         bottom: 0;
        //         z-index: -999;
        //         overflow: hidden;

        //         .particle {
        //             border-radius: 50%;
        //         }

        //         @for $i from 1 through 150 {
        //             @keyframes particle-animation-#{$i} {
        //                 0% {
        //                     transform: translate3d(
        //                         (random(50) * 100%),
        //                         (random(100) * 100%),
        //                         (random(100) * 1px)
        //                     );
        //                 }

        //                 100% {
        //                     transform: translate3d(
        //                         (random(50) * 100%),
        //                         (random(100) * -100%),
        //                         (random(100) * 1px)
        //                     );
        //                 }
        //             }

        //             .particle:nth-child(#{$i}) {
        //                 animation: particle-animation-#{$i} 30s linear infinite;
        //                 $size: random(5) + 2 + px;
        //                 opacity: calc(random(100) / 100);
        //                 height: $size;
        //                 width: $size;
        //                 animation-delay: -$i * 0.02s;
        //                 transform: translate3d(
        //                     (random(100) * 50%),
        //                     (random(100) * 50%),
        //                     (random(100) * 1px)
        //                 );
        //                 background: #ea79fd;
        //             }
        //         }
        //     }
        // }

        @keyframes rotateHexa {
            0% {
                background: linear-gradient(
                    0deg,
                    rgba(255, 247, 120, 1),
                    rgba(255, 255, 255, 1),
                    rgba(255, 247, 120, 1)
                );
            }

            25% {
                background: linear-gradient(
                    90deg,
                    rgba(255, 247, 120, 1),
                    rgba(255, 255, 255, 1),
                    rgba(255, 247, 120, 1)
                );
            }

            50% {
                background: linear-gradient(
                    90deg,
                    rgba(255, 247, 120, 1),
                    rgba(255, 255, 255, 1),
                    rgba(255, 247, 120, 1)
                );
            }

            75% {
                background: linear-gradient(
                    180deg,
                    rgba(255, 247, 120, 1),
                    rgba(255, 255, 255, 1),
                    rgba(255, 247, 120, 1)
                );
            }

            100% {
                background: linear-gradient(
                    180deg,
                    rgba(255, 247, 120, 1),
                    rgba(255, 255, 255, 1),
                    rgba(255, 247, 120, 1)
                );
            }
        }
    }

    @media (max-width: 1300px) {
        .content {
            flex: 1 0 50%;
        }
    }

    @media (max-width: 767px) {
        grid-template-columns: repeat(1, 1fr);

        .content {
            max-width: 100%;

            .but-wrap {
                button {
                    padding: 10px 15px;
                }
            }
        }
    }

    @media (max-width: 320px) {
        .content {
            max-width: 96%;
        }
    }
}

.partial-wrapper {
    margin: -1em -1em 0px;
    border-radius: 22px 22px 0px 0px;
    // background-image: linear-gradient(#9e9e9e, #656565 10% 90%, #989898);
    padding: 1em;

    .partial-item {
        display: flex;
        background-color: #1478b0;
        border-radius: 6px;
        padding: 10px;

        .left {
            img {
                max-width: 204px;
                width: 100%;
            }
        }

        .title {
            border-top-right-radius: 22px;
            border-top-left-radius: 22px;
            padding-top: 2rem;
            padding-bottom: 2rem;
            max-width: 100%;
            min-height: 152px;

            h1 {
                font-size: 60px;
                font-weight: 600;
                color: #ffffff;
                text-align: center;

                span {
                    font-size: 40px;
                }
            }
        }

        .package {
            background: #6a7a87;
            width: 90%;
            margin: 0 auto;
            padding: 1rem;
            border-radius: 11px;
            margin-top: -1em;

            p {
                font-size: 24px;
                font-weight: 500;
                text-align: center;
                color: var(--name-color);
                margin: 0;
            }
        }

        .right {
            h1 {
                font-size: 25px;
                font-weight: 600;
                color: #fff;
                margin-top: 1rem;
            }

            > p {
                background: #313131;
                border-radius: 6px;
                display: inline-block;
                color: #fff;
                padding: 5px 25px;
            }

            > div {
                div {
                    display: flex;
                    align-items: center;
                    gap: 10px;

                    p {
                        margin-bottom: 0;
                        color: #fff;
                    }
                }
            }
        }

        @media (max-width: 575px) {
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 10px;
        }
    }

    .partial-item:nth-child(5) {
        background: red;
    }
}

.confirmation {
    --name-color: #000;
    padding-top: 15px;

    .use-voucher {
        background: #d9d9d9;
        padding: 5px 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-radius: 6px;

        div:nth-child(1) {
            display: flex;
            align-items: center;
            gap: 10px;

            p {
                font-size: 20px;
                font-weight: 600;
                margin-bottom: 0;
            }
        }

        div:nth-child(2) {
            background: linear-gradient(to right, #1775b8, #91c4e9);
            border-radius: 6px;

            button {
                font-size: 16px;
                font-weight: 700;
                color: #fff;
                padding: 5px 10px;
                outline: none;
            }
        }

        @media (max-width: 418px) {
            div:nth-child(2) {
                button {
                    svg {
                        display: none;
                    }
                }
            }
        }
    }

    .detail {
        margin-top: 15px;

        h3,
        .total {
            font-size: 20px;
        }

        .total {
            display: flex;
            justify-content: space-between;
            font-weight: 600;
        }

        .total.promo {
            color: #262626;

            span {
                color: #0087cc;
            }
        }

        .evoucher {
            display: flex;
            justify-content: space-between;
        }
    }
}

.botans {
    display: flex;
    justify-content: center;
    gap: 10px;

    > button {
        background: linear-gradient(to right, #1775b8, #91c4e9);
        max-width: 465px;
        width: 90%;
        margin: 0 auto;
        border-radius: 100vw;
        padding: 10px 15px;
        font-size: 18px;
        font-size: 18px;
        font-weight: 600;
        color: #fff;
    }

    &.fail {
        grid-template-columns: 1fr;
    }
}

.mi-head {
    font-size: 30px;
    font-weight: 600;
    color: #0087cc;
    text-align: center;

    &.fail {
        color: #cc0000;
    }
}

.mi-sub {
    text-align: center;
    font-weight: 600;
    padding: 20px 0px;
}

.note {
    color: #0087cc;
    text-align: center;
    font-weight: 600;
    font-size: 14px;
    margin-bottom: 10px;
    @media (max-width: 376px) {
        font-size: 16px;
    }
}

.single-promo {
    font-size: 20px;
    font-weight: 600;
    text-align: center;
    color: #f59e0b;

    > .quantity {
        font-size: 1.125em;
        font-weight: 700;
        color: #1478b0;
    }

    > .promo-note {
        color: #f97316;
        font-size: 0.875em;
        line-height: 1;
    }
}
</style>

<style lang="scss">
#voucher-list {
    .modal-dialog {
        max-width: 782px;
        width: 90%;
        margin: 0 auto;

        @media (max-width: 575px) {
            width: 95%;
        }
    }

    .modal-body {
        position: relative;
        border-radius: 22px;

        h2 {
            color: #0087c8;
            font-weight: 600;
            text-align: center;
        }

        .user-voucher-container {
            background: #e6e6e6;
            border-radius: 6px;
            padding: 15px 10px;

            h3 {
                font-size: 18px;
                font-weight: 600;
                color: #0087cc;
            }

            p {
                font-size: 14px;
                font-weight: 400;
                color: #3c3c3c;
            }

            .no-voucher {
                h3 {
                    font-size: 30px;
                    text-align: center;
                }
            }

            .user-voucher-list {
                max-height: 385px;
                overflow-y: scroll;
                margin-top: 15px;

                .voucher {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding: 15px 0px;

                    .branding {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        height: 133px;
                        border-radius: 8px;
                        aspect-ratio: 1;
                        background-color: #006aa1;
                        padding: 1px;
                        mask-image: radial-gradient(
                                circle at top right,
                                transparent 8px,
                                black 8px
                            ),
                            radial-gradient(
                                circle at bottom right,
                                transparent 8px,
                                black 8px
                            );
                        mask-type: alpha;
                        mask-composite: intersect;

                        > img {
                            width: 80px;

                            @media (min-width: 992px) {
                                width: 130px;
                            }
                        }

                        @media (max-width: 575px) {
                            aspect-ratio: unset;
                        }
                    }

                    .content {
                        height: 133px;
                        background-color: #f1f5f9;
                        border-radius: 8px;
                        padding: 10px;
                        flex: 1;
                        mask-image: radial-gradient(
                                circle at top left,
                                transparent 8px,
                                black 8px
                            ),
                            radial-gradient(
                                circle at bottom left,
                                transparent 8px,
                                black 8px
                            );
                        mask-type: alpha;
                        mask-composite: intersect;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        gap: 8px;

                        input {
                            margin: 0;
                            min-width: 30px;
                            width: unset;
                            cursor: pointer;
                            height: 30px;

                            @media (max-width: 575px) {
                                min-width: 20px;
                                height: 20px;
                            }
                        }

                        div:nth-child(1) {
                            .tit {
                                font-size: 18px;
                                font-weight: 600;
                                text-transform: uppercase;

                                @media (min-width: 992px) {
                                    font-size: 24px;
                                }

                                @media (max-width: 575px) {
                                    font-size: 14px;
                                }
                            }

                            .buy {
                                color: #0087cc;
                                background-color: white;
                                font-weight: 600;
                                border-radius: 6px;
                                padding: 2px 15px;
                            }

                            .cost {
                                font-size: 18px;
                                font-weight: 600;
                                line-height: 1;

                                > span {
                                    font-size: 32px;
                                }

                                @media (min-width: 992px) {
                                    > span {
                                        font-size: 50px;
                                    }
                                }
                            }

                            div:nth-child(2) {
                                @media (max-width: 768px) {
                                    display: none;
                                }
                            }

                            .prize {
                                display: flex;
                                align-items: center;
                                gap: 10px;
                                font-size: 20px;
                                font-weight: 400;
                                color: #0087cc;

                                > img {
                                    width: 20px;
                                }

                                @media (min-width: 992px) {
                                    font-size: 20px;

                                    > img {
                                        width: 36px;
                                    }
                                }

                                @media (max-width: 575px) {
                                    font-size: 14px;
                                }
                            }
                        }
                    }
                }

                .vouchers:last-child {
                    border-bottom: none;
                }
            }
        }

        .button {
            max-width: 465px;
            margin: 0 auto;
            display: flex;
            justify-content: center;
            margin-top: 15px;

            button {
                background: linear-gradient(to right, #1775b8, #91c4e9);
                width: 90%;
                border-radius: 72px;
                padding: 10px 15px;
                font-size: 18px;
                font-weight: 600;
                color: #fff;
            }
        }

        .voucher-close {
            position: absolute;
            top: 20px;
            left: 10px;
            font-size: 35px;
            cursor: pointer;
        }
    }
}

#buy-node {
    .modal-dialog {
        max-width: 782px;
        width: 90%;
        margin: 0 auto;
        position: relative;

        .modal-body {
            > svg {
                position: absolute;
                top: 10px;
                right: 10px;
                cursor: pointer;
            }
        }
    }

    h2 {
        font-size: 30px;
        font-weight: 600;
        color: #0087cc;
        text-align: center;
    }

    h3 {
        font-size: 18px;
        font-weight: 600;
        text-align: left;
        color: #0087cc;
    }

    @media (max-width: 575px) {
        .modal-dialog {
            width: 95%;
        }

        .use-voucher {
            p {
                font-size: 16px;
            }
        }

        .detail {
            h3 {
                font-size: 16px;
            }

            p {
                font-size: 16px;
                gap: 15px;
            }
        }

        .botans {
            button {
                width: 100%;
                font-size: 13px;
            }
        }
    }
}

#buynode-confirm {
    @media (min-width: 320px) {
        .modal-content {
            position: relative;
            width: 100%;
            margin: 0 auto;

            .modal-body {
                border-radius: 12px;

                h2 {
                    color: #0087cc;
                    text-align: center;
                    font-size: 30px;
                    margin-bottom: 15px;
                }

                h3 {
                    text-align: center;
                    font-size: 20px;
                    font-weight: 600;
                }

                p {
                    font-size: 18px;
                    font-weight: 500;
                    text-align: center;
                }

                svg {
                    position: absolute;
                    font-size: 30px;
                    top: 10px;
                    right: 10px;
                    cursor: pointer;
                }

                .content {
                    font-size: 16px;
                }

                .payment {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    gap: 5px;
                    margin: 15px 0;

                    p {
                        font-size: 20px;
                        font-weight: 600;
                        color: #0087cc;
                        margin-bottom: 0;
                    }

                    .unit {
                        display: flex;
                        transition: 0.2s;

                        > div {
                            padding: 5px 20px;
                            color: #fff;
                            cursor: pointer;
                            font-weight: 700;
                            flex-shrink: 0;
                            white-space: nowrap;

                            &:nth-child(1) {
                                border-top-left-radius: 6px;
                                border-bottom-left-radius: 6px;
                            }

                            // &:nth-child(2) {
                            //     border-top-right-radius: 6px;
                            //     border-bottom-right-radius: 6px;
                            // }

                            &:nth-child(3) {
                                border-top-right-radius: 6px;
                                border-bottom-right-radius: 6px;
                            }
                        }
                    }

                    .unit > div.inactive {
                        background: #ccc;
                        color: #000;
                        opacity: 0.3;
                    }

                    .unit > div.active {
                        background: #1775b8;
                        color: #fff;
                    }

                    @media (max-width: 991px) {
                        .unit {
                            > div {
                                font-size: 14px;
                            }
                        }
                    }

                    @media (max-width: 418px) {
                        flex-direction: column;

                        .unit {
                            margin-bottom: 15px;
                        }
                    }
                }

                > .buttons {
                    display: flex;
                    align-content: center;
                    justify-content: space-around;
                    gap: 15px;
                    margin-top: 1rem;

                    span {
                        cursor: pointer;
                        max-width: 200px;
                        width: 100%;
                        color: white;
                        border-radius: 72px;
                        padding: 4px 10px;
                        font-size: 18px;
                        text-transform: uppercase;
                        font-weight: 600;
                        text-align: center;
                        transition: 0.2s;

                        &:nth-child(1) {
                            background-image: linear-gradient(
                                to right,
                                #075d9b,
                                #1775b8,
                                #91c4e9,
                                #d2e9fa
                            );
                            background-position: center;
                            background-size: 200%;

                            &:hover {
                                background-position: 0%;
                            }
                        }

                        &:nth-child(2) {
                            color: #0087cc;
                            border: 1px solid #0087cc;
                        }
                    }
                }
            }
        }
    }
}

::-webkit-scrollbar {
    width: 6px;
    display: block;
    padding-right: 5px;
}

::-webkit-scrollbar-track {
    border-radius: 12px;
    background: transparent;
}

::-webkit-scrollbar-thumb {
    background: #0087cc;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #0087c8;
}
</style>
