const axios = require('../axios.config').default;

export default {
    namespaced: true,
    state: () => ({
        stakePackages: [],
        stakeHistory: null,
        stakeProfit: null,
        stakeStat: null,
    }),
    getters: {
        StakePackages: (state) => state.stakePackages,
        StakeHistory: (state) => state.stakeHistory,
        StakeProfit: (state) => state.stakeProfit,
        StakeStat: (state) => state.stakeStat,
    },
    actions: {
        async getPackages({ commit }) {
            const res = await axios.get('/staking');
            if (res.status) {
                commit('SET_PACKAGES', res.data);
            }
            return res;
        },
        async getStakeHistory({ commit }, params) {
            const res = await axios.get('/staking/history', { params });
            if (res.status) {
                commit('SET_STAKE_HISTORY', res.data);
            }
            return res;
        },
        async getStakeProfit({ commit }, params) {
            const res = await axios.get('/staking/history-profit', { params });
            if (res.status) {
                commit('SET_STAKE_PROFIT', res.data);
            }
            return res;
        },
        async postStaking({ commit }, payload) {
            const res = await axios.post('/staking', payload);
            if (res.status) {
                commit('STAKED');
            }
            return res;
        },
        async getTotalStaking({ commit }) {
            const res = await axios.get('/staking/total-staking');
            if (res.status) {
                commit('SET_STAKE_STAT', res.data);
            }
            return res;
        },
    },
    mutations: {
        SET_PACKAGES(state, data) {
            state.stakePackages = data;
        },
        SET_STAKE_HISTORY(state, data) {
            state.stakeHistory = data;
        },
        SET_STAKE_PROFIT(state, data) {
            state.stakeProfit = data;
        },
        STAKED() {},
        SET_STAKE_STAT(state, data) {
            state.stakeStat = data;
        },
    },
};
