<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M15.5984 7.19961C15.5984 9.18783 13.9867 10.7996 11.9984 10.7996C10.0102 10.7996 8.39844 9.18783 8.39844 7.19961C8.39844 5.21139 10.0102 3.59961 11.9984 3.59961C13.9867 3.59961 15.5984 5.21139 15.5984 7.19961Z"
            fill="currentColor" />
          <path
            d="M21.6008 9.59922C21.6008 10.9247 20.5263 11.9992 19.2008 11.9992C17.8753 11.9992 16.8008 10.9247 16.8008 9.59922C16.8008 8.27373 17.8753 7.19922 19.2008 7.19922C20.5263 7.19922 21.6008 8.27373 21.6008 9.59922Z"
            fill="currentColor" />
          <path
            d="M16.7992 17.9992C16.7992 15.3483 14.6501 13.1992 11.9992 13.1992C9.34825 13.1992 7.19922 15.3483 7.19922 17.9992V21.5992H16.7992V17.9992Z"
            fill="currentColor" />
          <path
            d="M7.19844 9.59922C7.19844 10.9247 6.12392 11.9992 4.79844 11.9992C3.47295 11.9992 2.39844 10.9247 2.39844 9.59922C2.39844 8.27373 3.47295 7.19922 4.79844 7.19922C6.12392 7.19922 7.19844 8.27373 7.19844 9.59922Z"
            fill="currentColor" />
          <path
            d="M19.1999 21.6004V18.0004C19.1999 16.7355 18.8738 15.5466 18.3008 14.5136C18.5882 14.4396 18.8895 14.4004 19.1999 14.4004C21.1882 14.4004 22.7999 16.0121 22.7999 18.0004V21.6004H19.1999Z"
            fill="currentColor" />
          <path
            d="M5.69833 14.5136C5.12544 15.5466 4.79922 16.7355 4.79922 18.0004V21.6004H1.19922V18.0004C1.19922 16.0121 2.811 14.4004 4.79922 14.4004C5.10967 14.4004 5.41094 14.4396 5.69833 14.5136Z"
            fill="currentColor" />
        </svg>
</template>
<script>
export default {
  name: 'MemberIcon'
}
</script>
