<template>
    <div>
        <div class="member-left">
            <div class="member-title">
                <h3>{{ $t('affiliate') }}</h3>
                <div v-if="checkAffiliate">
                    <router-link class="router-link" :to="{ name: 'Member' }">
                        {{ $t('affiliate') }}
                        <img
                            src="../../assets/images/icons/arrow_right.svg"
                            alt=""
                        />
                    </router-link>
                </div>
            </div>
            <div class="member-left-content">
                <div class="refer">
                    <div class="text-center">
                        <VueQr :text="url" class="logo" />
                    </div>
                    <div class="ref-link">
                        {{ url }}
                    </div>
                    <b-button
                        class="copy"
                        v-clipboard:copy="url"
                        v-clipboard:success="onCopy"
                        v-clipboard:error="onError"
                    >
                        <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M21.75 24C22.9927 24 24 22.9927 24 21.75V8.25C24 7.00734 22.9927 6 21.75 6H8.25C7.00734 6 6 7.00734 6 8.25V21.75C6 22.9927 7.00734 24 8.25 24H21.75ZM8.25 4.5C6.18225 4.5 4.5 6.18225 4.5 8.25V18H2.25C1.00734 18 0 16.9927 0 15.75V2.25C0 1.00734 1.00734 0 2.25 0H15.75C16.9927 0 18 1.00734 18 2.25V4.5H8.25Z"
                                fill="#1478B0"
                            />
                        </svg>
                        <div class="text pl-2">
                            {{ $t('referralLink') }}
                        </div>
                    </b-button>
                </div>

                <div>
                    <div>
                        <p>
                            {{ $t('totalDownline') }}
                        </p>
                        <p>
                            {{ TruncateToDecimals2(Statistic.totalDownlines) }}
                        </p>
                    </div>
                    <div>
                        <p>
                            {{ $t('totalF1') }}
                        </p>
                        <p>
                            {{ TruncateToDecimals2(Statistic.totalF1) }}
                        </p>
                    </div>
                    <div>
                        <p>
                            {{ $t('totalF1Sales') }}
                        </p>
                        <p>
                            {{ TruncateToDecimals2(TotalFSale) }}
                            USDT
                        </p>
                    </div>
                    <div>
                        <p>
                            {{ $t('totalGroupSales') }}
                        </p>
                        <p>
                            {{ TruncateToDecimals2(TotalGroupSale) }}
                            USDT
                        </p>
                    </div>
                    <div>
                        <p>
                            {{ $t('totalGroupMinings') }}
                        </p>
                        <p>
                            {{ TruncateToDecimals2(TotalGroupMint) }}
                            XIN
                        </p>
                    </div>
                    <div class="updated-at">
                        <template v-if="GroupStat">
                            {{ getDateTime3(GroupStat.date) }}
                            <button
                                class="update-data"
                                @click="updateGroupStat"
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                >
                                    <path
                                        fill="none"
                                        stroke="currentColor"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        stroke-width="2"
                                        d="M20 20v-5h-5M4 4v5h5m10.938 2A8.001 8.001 0 0 0 5.07 8m-1.008 5a8.001 8.001 0 0 0 14.868 3"
                                    />
                                </svg>
                            </button>
                        </template>
                        <template v-else>
                            <span class="h-4 d-inline-block"></span>
                        </template>
                    </div>
                </div>
            </div>
            <div class="plane-1">
                <img
                    src="../../assets/images/background/flight-big.png"
                    alt=""
                />
            </div>
            <div class="plane-2">
                <img
                    src="../../assets/images/background/flight-small.png"
                    alt=""
                />
            </div>
            <div class="plane-3">
                <img
                    src="../../assets/images/background/flight-tiny.png"
                    alt=""
                />
            </div>
        </div>
    </div>
</template>

<script>
import VueQr from 'vue-qr';

export default {
    components: {
        VueQr,
    },
    props: {
        Statistic: {
            type: Object,
        },
        TotalFSale: {
            type: Number,
        },
        TotalGroupSale: {
            type: Number,
        },
        TotalGroupMint: {
            type: Number,
        },
        GroupStat: {
            type: Object,
        },
        UserInfo: {
            type: Object,
        },
    },
    computed: {
        url() {
            return `${window.location.hostname}/register?sponsor=${this.UserInfo.refId}`;
        },

        checkAffiliate() {
            return this.$route.fullPath !== '/member';
        },
    },
    methods: {
        updateGroupStat() {
            this.$store.dispatch('member/reqTotalGroupStat', { refresh: true });
        },
        onCopy() {
            this.$toastr.s('Copy Link Referral Success', 'Successfully');
        },
        onError() {
            this.$toastr.e(
                'Copy Link Referral Fail,Please Try Again',
                'Failed',
            );
        },
    },

    mounted() {
        console.log(this.$route.fullPath);
    },
};
</script>

<style lang="scss">
.member-title {
    display: flex;
    justify-content: space-between;
    width: 100%;
    color: #fff;

    h3 {
        font-size: 20px;
        font-weight: 600;
    }

    > div {
        .router-link {
            border-radius: 6px;
            background: #fff;
            padding: 5px 15px;
            color: #1478b0;
            font-weight: 700;
            transition: 0.2s;

            img {
                width: 15px;
                height: 13px;
            }

            &:hover {
                opacity: 0.8;
            }
        }
    }
}

.member-left {
    background-color: #107fb9;
    min-height: 434px;
    border-radius: 22px;
    border: 1px solid #d8d8d8;
    padding: 15px;
    height: 100%;
    position: relative;
    z-index: 999;

    .member-left-content {
        display: flex;
        margin-top: 1rem;
        flex-wrap: wrap;

        > div:nth-child(1) {
            display: flex;
            flex-direction: column;
            gap: 2rem;
            flex-basis: 100%;

            .img {
                display: flex;
                justify-content: center;
            }

            input {
                width: 90%;
                margin: 0 auto;
                padding: 0.5rem;
                border: 1px solid #e4e4e4;
                border-radius: 12px;
                outline: none;
                font-size: 20px;
                font-weight: 400;
                color: #a6a6a6;
            }

            button {
                width: 90%;
                margin: 0 auto;
                border-radius: 72px;
                background: #fff;
                color: #1478b0;
                padding: 0.5rem;
            }
        }

        > div:nth-child(2) {
            flex-basis: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            gap: 1rem;
            padding: 10px 0px;

            > div:not(.updated-at) {
                display: flex;
                justify-content: space-between;

                p {
                    font-size: 18px;
                    font-weight: 400;
                    color: #fff;
                }

                p:nth-child(2) {
                    color: #97d7f8;
                }
            }
            .updated-at {
                text-align: right;
                color: #dfdfdf;
                font-weight: 500;
            }
            .update-data {
                color: #1478b0;
                background-color: #fff;
                border-radius: 100vw;
                font-weight: 600;
                padding: 4px;
            }
        }

        @media (min-width: 768px) {
            > div:nth-child(1) {
                flex-basis: 50%;
            }

            > div:nth-child(2) {
                flex-basis: 50%;
                padding: 0px 10px;
            }
        }
    }

    .plane-1,
    .plane-2,
    .plane-3 {
        position: absolute;
        z-index: -999;
    }

    .plane-1 {
        top: 35px;
        left: 0;

        img {
            width: 150px;
        }
    }

    .plane-2 {
        top: 18px;
        right: 65px;

        img {
            width: 150px;
        }
    }

    .plane-3 {
        top: 15px;
        right: 50%;
    }
}

.refer {
    padding: 0px 10px;

    .logo {
        max-width: 165px;
        width: 100%;
        margin: 30px auto;
    }

    .ref-link {
        background-color: #579ec6;
        color: #dfdfdf;
        box-shadow: 0px 0px 5px 1px #0000001e;
        border-radius: 6px;
        max-width: 465px;
        min-width: 0;
        margin: auto;
        display: block;
        width: 100%;
        border: none;
        padding: 6px 10px;
        word-wrap: break-word;
        text-align: center;
    }

    .copy {
        background-image: linear-gradient(
            50deg,
            #0087c8 14.98%,
            #00d1ff 106.07%
        );
        width: 100%;
        max-width: 465px;
        border-radius: 100vw;
        font-weight: 600;
        font-size: 18px;
        margin: auto;
        margin-top: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-transform: capitalize;
        border: none;
        transition: 0.2s;

        &:hover {
            opacity: 0.8;
        }
    }
}
</style>
