<template>
    <div class="container py-2">
        <StakingStatistic></StakingStatistic>
        <StakingPackages></StakingPackages>
        <StakingHistory></StakingHistory>
        <StakingProfit></StakingProfit>
    </div>
</template>

<script>
import StakingPackages from '@/components/Staking/StakingPackages.vue';
import StakingHistory from '@/components/Staking/StakingHistory.vue';
import StakingProfit from '@/components/Staking/StakingProfit.vue';
import StakingStatistic from '../../components/Staking/StakingStatistic.vue';

export default {
    name: 'StakingView',
    components: {
        StakingPackages,
        StakingHistory,
        StakingProfit,
        StakingStatistic,
    },
    mounted() {
        this.$store.dispatch('stake/getTotalStaking');
    },
};
</script>
