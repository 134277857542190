<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_7099_10920)">
      <path d="M3 13H11V3H3V13ZM3 21H11V15H3V21ZM13 21H21V11H13V21ZM13 3V9H21V3H13Z" fill="currentColor" />
    </g>
    <defs>
      <clipPath id="clip0_7099_10920">
        <rect width="24" height="24" fill="currentColor" />
      </clipPath>
    </defs>
  </svg>
</template>
<script>
export default {
  name: 'DashBoardIcon'
}
</script>
