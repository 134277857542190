<template>
    <div class="payment-container">
        <div v-if="myCountry">
            <div class="header">
                <router-link :to="{ name: 'LocalEsim' }" class="back">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="1em"
                        height="1em"
                        viewBox="0 0 24 24"
                    >
                        <path
                            fill="currentColor"
                            d="M19 11H7.83l4.88-4.88c.39-.39.39-1.03 0-1.42a.996.996 0 0 0-1.41 0l-6.59 6.59a.996.996 0 0 0 0 1.41l6.59 6.59a.996.996 0 1 0 1.41-1.41L7.83 13H19c.55 0 1-.45 1-1s-.45-1-1-1"
                        />
                    </svg>
                    {{ $t('goBack') }}
                </router-link>
                <div class="country">
                    <img :src="myCountry.data.results[0].image" alt="" />
                    <div class="title">
                        {{ myCountry.data.results[0].title }}
                    </div>
                </div>
            </div>
            <div
                v-for="(item, index) in myCountry.data.results"
                :key="index"
                class="payment-wrapper"
            >
                <div
                    v-for="(itemPackage, index) in item.packages"
                    :key="index"
                    class="payment-card"
                >
                    <div class="header">
                        <h3>Esim</h3>
                        <img src="@/assets/images/esim/payment.png" alt="" />
                    </div>
                    <div class="content">
                        <div>
                            <div>
                                <svg
                                    width="20"
                                    height="20"
                                    viewBox="0 0 20 20"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M2.252 8C2.08401 8.65337 1.99934 9.32537 2 10C2 10.69 2.088 11.36 2.252 12H5.1C4.96633 10.67 4.96633 9.32998 5.1 8H2.252ZM3.07 6H5.416C5.682 4.783 6.066 3.693 6.537 2.786C5.08536 3.48568 3.87746 4.60543 3.07 6ZM17.748 8H14.9C15.0337 9.32998 15.0337 10.67 14.9 12H17.748C18.0845 10.6879 18.0845 9.3121 17.748 8ZM16.93 6C16.1225 4.60543 14.9146 3.48568 13.463 2.786C13.935 3.693 14.318 4.783 14.584 6H16.93ZM7.112 8C7.03757 8.66407 7.00018 9.33177 7 10C7 10.685 7.038 11.355 7.112 12H12.888C13.0383 10.6709 13.0383 9.3291 12.888 8H7.112ZM7.47 6H12.53C12.3477 5.24839 12.0852 4.51854 11.747 3.823C11.119 2.568 10.447 2 10 2C9.553 2 8.881 2.568 8.253 3.823C7.938 4.455 7.673 5.19 7.47 6ZM3.07 14C3.87746 15.3946 5.08536 16.5143 6.537 17.214C6.065 16.307 5.682 15.217 5.416 14H3.07ZM16.93 14H14.584C14.318 15.217 13.934 16.307 13.463 17.214C14.9146 16.5143 16.1225 15.3946 16.93 14ZM7.47 14C7.673 14.81 7.938 15.545 8.253 16.177C8.881 17.432 9.553 18 10 18C10.447 18 11.119 17.432 11.747 16.177C12.062 15.545 12.327 14.81 12.53 14H7.47ZM10 20C4.477 20 0 15.523 0 10C0 4.477 4.477 0 10 0C15.523 0 20 4.477 20 10C20 15.523 15.523 20 10 20Z"
                                        fill="white"
                                    />
                                </svg>
                                {{ $t('coverage') }}
                            </div>
                            <div>{{ item.title }}</div>
                        </div>
                        <div>
                            <div>
                                <svg
                                    width="20"
                                    height="24"
                                    viewBox="0 0 20 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M5.99997 1.99994L5.29297 1.29294L5.99997 0.585938L6.70697 1.29294L5.99997 1.99994ZM6.99997 16.9999C6.99997 17.2652 6.89461 17.5195 6.70708 17.707C6.51954 17.8946 6.26518 17.9999 5.99997 17.9999C5.73475 17.9999 5.4804 17.8946 5.29286 17.707C5.10533 17.5195 4.99997 17.2652 4.99997 16.9999H6.99997ZM0.292969 6.29294L5.29297 1.29294L6.70697 2.70694L1.70697 7.70694L0.292969 6.29294ZM6.70697 1.29294L11.707 6.29294L10.293 7.70694L5.29297 2.70694L6.70697 1.29294ZM6.99997 1.99994V16.9999H4.99997V1.99994H6.99997ZM14 21.9999L13.293 22.7069L14 23.4139L14.707 22.7069L14 21.9999ZM15 6.99994C15 6.73472 14.8946 6.48037 14.7071 6.29283C14.5195 6.10529 14.2652 5.99994 14 5.99994C13.7348 5.99994 13.4804 6.10529 13.2929 6.29283C13.1053 6.48037 13 6.73472 13 6.99994H15ZM8.29297 17.7069L13.293 22.7069L14.707 21.2929L9.70697 16.2929L8.29297 17.7069ZM14.707 22.7069L19.707 17.7069L18.293 16.2929L13.293 21.2929L14.707 22.7069ZM15 21.9999V6.99994H13V21.9999H15Z"
                                        fill="white"
                                    />
                                </svg>
                                {{ $t('data') }}
                            </div>
                            <div>{{ itemPackage.data }}</div>
                        </div>
                        <div>
                            <div>
                                <svg
                                    width="20"
                                    height="21"
                                    viewBox="0 0 20 21"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M4 3V0.5H6V3H14V0.5H16V3H20V21H0V3H4ZM2 5V8H18V5H2ZM18 10H2V19H18V10Z"
                                        fill="white"
                                    />
                                </svg>
                                {{ $t('validity') }}
                            </div>
                            <div>{{ itemPackage.day }} {{ $t('days') }}</div>
                        </div>
                        <div>
                            <div>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="20"
                                    height="21"
                                    viewBox="0 0 20 21"
                                >
                                    <path
                                        fill="white"
                                        fill-rule="evenodd"
                                        d="m16.137 4.728l1.83 1.83C20.656 9.248 22 10.592 22 12.262c0 1.671-1.344 3.015-4.033 5.704c-2.69 2.69-4.034 4.034-5.705 4.034c-1.67 0-3.015-1.344-5.704-4.033l-1.83-1.83c-1.545-1.546-2.318-2.318-2.605-3.321c-.288-1.003-.042-2.068.45-4.197l.283-1.228c.413-1.792.62-2.688 1.233-3.302s1.51-.82 3.302-1.233l1.228-.284c2.13-.491 3.194-.737 4.197-.45c1.003.288 1.775 1.061 3.32 2.606m-4.99 9.6c-.673-.672-.668-1.638-.265-2.403a.75.75 0 0 1 1.04-1.046c.34-.18.713-.276 1.085-.272a.75.75 0 0 1-.014 1.5a.88.88 0 0 0-.609.277c-.387.387-.286.775-.177.884c.11.109.497.21.884-.177c.784-.784 2.138-1.044 3.005-.177c.673.673.668 1.639.265 2.404a.75.75 0 0 1-1.04 1.045a2.2 2.2 0 0 1-1.472.232a.75.75 0 1 1 .302-1.47c.177.037.463-.021.708-.266c.387-.388.286-.775.177-.884c-.11-.109-.497-.21-.884.177c-.784.784-2.138 1.044-3.005.176m-1.126-4.035a2 2 0 1 0-2.829-2.828a2 2 0 0 0 2.829 2.828"
                                        clip-rule="evenodd"
                                    />
                                </svg>
                                {{ $t('price') }}
                            </div>
                            <div>{{ itemPackage.price }} USDT</div>
                        </div>
                        <button
                            @click="
                                openPaymentModal(
                                    itemPackage,
                                    item,
                                    itemPackage.amount,
                                )
                            "
                        >
                            {{ $t('choose') }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <b-modal
            id="local-payment-modal"
            class="local-payment-modal"
            hide-header
            hide-footer
            centered
            @change="resetModal"
        >
            <h3>{{ $t('payment') }}</h3>
            <div class="detail">
                <div class="left">
                    <img src="@/assets/images/esim/payment2.png" alt="" />
                </div>
                <div class="right">
                    <div>
                        <div>
                            <svg
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M2.252 8C2.08401 8.65337 1.99934 9.32537 2 10C2 10.69 2.088 11.36 2.252 12H5.1C4.96633 10.67 4.96633 9.32998 5.1 8H2.252ZM3.07 6H5.416C5.682 4.783 6.066 3.693 6.537 2.786C5.08536 3.48568 3.87746 4.60543 3.07 6ZM17.748 8H14.9C15.0337 9.32998 15.0337 10.67 14.9 12H17.748C18.0845 10.6879 18.0845 9.3121 17.748 8ZM16.93 6C16.1225 4.60543 14.9146 3.48568 13.463 2.786C13.935 3.693 14.318 4.783 14.584 6H16.93ZM7.112 8C7.03757 8.66407 7.00018 9.33177 7 10C7 10.685 7.038 11.355 7.112 12H12.888C13.0383 10.6709 13.0383 9.3291 12.888 8H7.112ZM7.47 6H12.53C12.3477 5.24839 12.0852 4.51854 11.747 3.823C11.119 2.568 10.447 2 10 2C9.553 2 8.881 2.568 8.253 3.823C7.938 4.455 7.673 5.19 7.47 6ZM3.07 14C3.87746 15.3946 5.08536 16.5143 6.537 17.214C6.065 16.307 5.682 15.217 5.416 14H3.07ZM16.93 14H14.584C14.318 15.217 13.934 16.307 13.463 17.214C14.9146 16.5143 16.1225 15.3946 16.93 14ZM7.47 14C7.673 14.81 7.938 15.545 8.253 16.177C8.881 17.432 9.553 18 10 18C10.447 18 11.119 17.432 11.747 16.177C12.062 15.545 12.327 14.81 12.53 14H7.47ZM10 20C4.477 20 0 15.523 0 10C0 4.477 4.477 0 10 0C15.523 0 20 4.477 20 10C20 15.523 15.523 20 10 20Z"
                                    fill="black"
                                />
                            </svg>
                            {{ $t('coverage') }}
                        </div>
                        <div v-if="selectedData">
                            {{ selectedData.title }}
                        </div>
                    </div>
                    <div>
                        <div>
                            <svg
                                width="20"
                                height="24"
                                viewBox="0 0 20 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M5.99997 1.99994L5.29297 1.29294L5.99997 0.585938L6.70697 1.29294L5.99997 1.99994ZM6.99997 16.9999C6.99997 17.2652 6.89461 17.5195 6.70708 17.707C6.51954 17.8946 6.26518 17.9999 5.99997 17.9999C5.73475 17.9999 5.4804 17.8946 5.29286 17.707C5.10533 17.5195 4.99997 17.2652 4.99997 16.9999H6.99997ZM0.292969 6.29294L5.29297 1.29294L6.70697 2.70694L1.70697 7.70694L0.292969 6.29294ZM6.70697 1.29294L11.707 6.29294L10.293 7.70694L5.29297 2.70694L6.70697 1.29294ZM6.99997 1.99994V16.9999H4.99997V1.99994H6.99997ZM14 21.9999L13.293 22.7069L14 23.4139L14.707 22.7069L14 21.9999ZM15 6.99994C15 6.73472 14.8946 6.48037 14.7071 6.29283C14.5195 6.10529 14.2652 5.99994 14 5.99994C13.7348 5.99994 13.4804 6.10529 13.2929 6.29283C13.1053 6.48037 13 6.73472 13 6.99994H15ZM8.29297 17.7069L13.293 22.7069L14.707 21.2929L9.70697 16.2929L8.29297 17.7069ZM14.707 22.7069L19.707 17.7069L18.293 16.2929L13.293 21.2929L14.707 22.7069ZM15 21.9999V6.99994H13V21.9999H15Z"
                                    fill="black"
                                />
                            </svg>
                            {{ $t('data') }}
                        </div>
                        <div v-if="selectedData">
                            {{ selectedPackage.data }}
                        </div>
                    </div>
                    <div>
                        <div>
                            <svg
                                width="20"
                                height="21"
                                viewBox="0 0 20 21"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M4 3V0.5H6V3H14V0.5H16V3H20V21H0V3H4ZM2 5V8H18V5H2ZM18 10H2V19H18V10Z"
                                    fill="black"
                                />
                            </svg>
                            {{ $t('validity') }}
                        </div>
                        <div v-if="selectedData">
                            {{ selectedPackage.day }} {{ $t('days') }}
                        </div>
                    </div>
                    <div>
                        <div>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="21"
                                viewBox="0 0 20 21"
                            >
                                <path
                                    fill="currentColor"
                                    d="M6.5 14.975v-1H4v-2h5v-2H5q-.425 0-.712-.287T4 8.975v-4q0-.425.288-.712T5 3.975h1.5v-1h2v1H11v2H6v2h4q.425 0 .713.288t.287.712v4q0 .425-.288.713t-.712.287H8.5v1zm7.45 6l-4.25-4.25l1.4-1.4l2.85 2.85l5.65-5.65l1.4 1.4z"
                                />
                            </svg>
                            {{ $t('price') }}
                        </div>
                        <div v-if="selectedPackage">
                            {{ selectedPackage.price }} USDT
                        </div>
                    </div>
                </div>
            </div>
            <div class="info">
                <div class="right">
                    <h4>{{ $t('additionalInfo') }}</h4>
                    <div class="content" v-if="selectedData">
                        <div v-for="item in selectedData.coverages">
                            <div
                                v-for="subItem in item.networks"
                                class="network"
                            >
                                <svg
                                    width="20"
                                    height="21"
                                    viewBox="0 0 20 21"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M19 21V13.5C19 13.1022 18.842 12.7206 18.5607 12.4393C18.2794 12.158 17.8978 12 17.5 12H14.5C14.1022 12 13.7206 12.158 13.4393 12.4393C13.158 12.7206 13 13.1022 13 13.5V21V4C13 2.586 13 1.879 12.56 1.44C12.122 1 11.415 1 10 1C8.585 1 7.879 1 7.44 1.44C7 1.878 7 2.585 7 4V21V8.5C7 8.10218 6.84196 7.72064 6.56066 7.43934C6.27936 7.15804 5.89782 7 5.5 7H2.5C2.10218 7 1.72064 7.15804 1.43934 7.43934C1.15804 7.72064 1 8.10218 1 8.5V21"
                                        stroke="black"
                                        stroke-width="1.5"
                                    />
                                </svg>
                                <div>
                                    <div>
                                        {{ $t('nameInfo') }}:
                                        {{ subItem.name }}
                                    </div>
                                    <div>
                                        <div v-for="type in subItem.types">
                                            {{ $t('typeInfo') }}:
                                            {{ type }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="use-voucher" v-if="EsimVoucher">
                <div>
                    <svg
                        class="voucher-icon"
                        width="60"
                        height="60"
                        viewBox="0 0 60 60"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M10 10C8.67392 10 7.40215 10.5268 6.46447 11.4645C5.52678 12.4021 5 13.6739 5 15V25C6.32608 25 7.59785 25.5268 8.53553 26.4645C9.47322 27.4021 10 28.6739 10 30C10 31.3261 9.47322 32.5979 8.53553 33.5355C7.59785 34.4732 6.32608 35 5 35V45C5 46.3261 5.52678 47.5979 6.46447 48.5355C7.40215 49.4732 8.67392 50 10 50H50C51.3261 50 52.5979 49.4732 53.5355 48.5355C54.4732 47.5979 55 46.3261 55 45V35C53.6739 35 52.4021 34.4732 51.4645 33.5355C50.5268 32.5979 50 31.3261 50 30C50 28.6739 50.5268 27.4021 51.4645 26.4645C52.4021 25.5268 53.6739 25 55 25V15C55 13.6739 54.4732 12.4021 53.5355 11.4645C52.5979 10.5268 51.3261 10 50 10H10ZM38.75 17.5L42.5 21.25L21.25 42.5L17.5 38.75L38.75 17.5ZM22.025 17.6C24.475 17.6 26.45 19.575 26.45 22.025C26.45 23.1986 25.9838 24.3241 25.1539 25.1539C24.3241 25.9838 23.1986 26.45 22.025 26.45C19.575 26.45 17.6 24.475 17.6 22.025C17.6 20.8514 18.0662 19.7259 18.8961 18.8961C19.7259 18.0662 20.8514 17.6 22.025 17.6ZM37.975 33.55C40.425 33.55 42.4 35.525 42.4 37.975C42.4 39.1486 41.9338 40.2741 41.104 41.104C40.2741 41.9338 39.1486 42.4 37.975 42.4C35.525 42.4 33.55 40.425 33.55 37.975C33.55 36.8014 34.0162 35.6759 34.8461 34.8461C35.6759 34.0162 36.8014 33.55 37.975 33.55Z"
                            fill="#0087CC"
                        />
                    </svg>
                    <p>eSim Voucher ({{ EsimVoucher.quantity }})</p>
                </div>
                <div
                    class="voucher-btn"
                    @click="toggleVoucher(EsimVoucher.voucherId)"
                >
                    {{ selectedVoucherId ? 'Undo' : 'Use Now' }}
                </div>
            </div>
            <button
                class="nice-gradient"
                @click="buyEsim(selectedPackage.id, selectedVoucherId)"
            >
                {{ $t('confirm') }}
            </button>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="1em"
                height="1em"
                viewBox="0 0 24 24"
                class="close-modal"
                @click="$bvModal.hide('local-payment-modal')"
            >
                <path
                    fill="currentColor"
                    d="M6.4 19L5 17.6l5.6-5.6L5 6.4L6.4 5l5.6 5.6L17.6 5L19 6.4L13.4 12l5.6 5.6l-1.4 1.4l-5.6-5.6z"
                />
            </svg>
        </b-modal>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    data() {
        return {
            search: null,
            countryCode: this.$route.query.country_code,
            myCountry: null,
            selectedPackage: null,
            selectedData: null,
            selectedVoucherId: null,
            page: 1,
            pending: false,
        };
    },

    computed: {
        ...mapGetters({
            EsimVoucher: 'nft/EsimVoucher',
        }),
    },

    methods: {
        openPaymentModal(chosenPackage, data, amount) {
            this.selectedPackage = chosenPackage;
            this.selectedData = data;
            this.$bvModal.show('local-payment-modal');
            this.$store.dispatch('nft/getEsimVoucher', {
                eSimGB: amount / 1024,
            });

            console.log('amount data: ', amount);
        },

        resetModal() {
            this.selectedVoucherId = null;
        },

        toggleVoucher(voucherId) {
            if (this.selectedVoucherId === voucherId) {
                this.selectedVoucherId = null;
            } else {
                this.selectedVoucherId = voucherId;
            }
        },

        async buyEsim(packageId, voucherId) {
            if (this.pending) {
                return;
            }
            this.pending = true;

            await this.$recaptchaLoaded();
            const token = await this.$recaptcha('buyEsim');

            if (voucherId) {
                await this.$store
                    .dispatch('nft/buyEsim', {
                        type: 'local',
                        package_id: packageId,
                        currency: 'USDT',
                        voucherId,
                        token,
                    })
                    .then((res) => {
                        if (res) {
                            this.$bvModal.hide('local-payment-modal');
                            this.$store.dispatch('info/req_getInfo');
                            this.$store.commit('nft/BUY_ESIM_SUCCESS');
                            this.selectedVoucherId = null;
                        }
                    });
            } else {
                await this.$store
                    .dispatch('nft/buyEsim', {
                        type: 'local',
                        package_id: packageId,
                        currency: 'USDT',
                        token,
                    })
                    .then((res) => {
                        if (res) {
                            this.$bvModal.hide('local-payment-modal');
                            this.$store.dispatch('info/req_getInfo');
                            this.$store.dispatch('nft/getEsimHistory', {
                                page: this.page,
                            });
                            this.$store.commit('nft/BUY_ESIM_SUCCESS');
                            this.selectedVoucherId = null;
                        }
                    });
            }
            this.pending = false;
        },
    },

    mounted() {
        this.$store
            .dispatch('nft/getEsimLocal', {
                filter: {
                    type: 'local',
                    country: this.countryCode,
                },
            })
            .then((res) => {
                this.myCountry = res;
            });
    },
};
</script>

<style lang="scss" scoped>
.payment-container {
    .header {
        margin-top: 2rem;
        gap: 16px;
        .back {
            cursor: pointer;
            font-size: 20px;
            font-weight: 600;
            display: flex;
            align-items: center;
            gap: 5px;
            color: #0087cc;
            transition: 0.2s;
            &:hover {
                opacity: 0.7;
            }
        }
        .country {
            margin-top: 1rem;
            display: flex;
            align-items: center;
            gap: 6px;
            img {
                width: 50px;
                border-radius: 4px;
            }

            .title {
                font-size: 18px;
                font-weight: 600;
            }
        }
    }
    .payment-wrapper {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        .payment-card {
            margin-top: 2rem;
            width: 300px;
            padding: 1rem;
            border-radius: 12px;
            color: #fff;
            background: linear-gradient(
                to right,
                rgb(102, 195, 204),
                rgba(29, 119, 239, 1)
            );
            position: relative;
            .header {
                display: flex;
                justify-content: space-between;

                img {
                    position: absolute;
                    top: -15px;
                    right: 15px;
                }
            }

            .content {
                margin-top: 2rem;
                > div {
                    padding: 17px 0;
                    display: flex;
                    justify-content: space-between;
                    position: relative;
                    &:before {
                        content: '';
                        display: block;
                        width: 100%;
                        height: 1px;
                        background: rgba(255, 255, 255, 0.3);
                        position: absolute;
                        top: 0;
                    }
                    > div {
                        display: flex;
                        align-items: center;
                        gap: 7px;
                        font-weight: 600;
                    }
                }

                button {
                    margin-top: 1.5rem;
                    background: #fff;
                    border-radius: 100px;
                    color: #0087cc;
                    font-weight: 600;
                    width: 100%;
                    padding: 10px 0;
                    outline: none;
                    transition: 0.2s;
                    &:hover {
                        background: #e0dcdc;
                    }
                }
            }
        }

        @media (max-width: 1500px) {
            grid-template-columns: repeat(3, 1fr);
        }

        @media (max-width: 1200px) {
            grid-template-columns: repeat(2, 1fr);
        }

        @media (max-width: 766px) {
            grid-template-columns: repeat(1, 1fr);
            justify-items: center;

            .payment-card {
                width: 100%;
            }
        }
    }
}

/* width */
::-webkit-scrollbar {
    width: 3px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}
</style>

<style lang="scss">
#local-payment-modal {
    .modal-dialog {
        max-width: 755px;
    }
    .modal-body {
        border-radius: 20px;
        position: relative;
    }
    h3 {
        color: #0087cc;
        font-weight: 600;
        font-size: 30px;
        text-align: center;
    }

    .detail {
        display: flex;
        gap: 10px;
        align-items: center;
        justify-content: space-between;
        margin-top: 1rem;
        .right {
            width: 50%;
            > div {
                border-top: 1px solid #000;
                display: flex;
                justify-content: space-between;
                padding: 1rem 0;

                div:nth-child(1) {
                    display: flex;
                    align-items: center;
                    gap: 6px;
                    font-weight: 600;
                }

                div:nth-child(2) {
                    font-weight: 500;
                }
            }
        }
    }

    .info {
        margin-top: 1rem;
        // display: flex;
        // justify-content: space-between;
        h4 {
            font-weight: 700;
            margin-bottom: 1rem;
        }

        .right {
            .content {
                box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
                height: 150px;
                overflow-y: auto;
                .network {
                    display: flex;
                    align-items: center;
                    gap: 10px;
                    padding: 15px;
                    border-bottom: 1px solid #ddd7d7;

                    svg {
                        font-size: 20px;
                    }
                }
            }
        }
    }

    .voucher-wrapper {
        .content {
            box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
            height: 150px;
            overflow-y: auto;
            .network {
                display: flex;
                align-items: center;
                gap: 10px;
                padding: 15px;
                border-bottom: 1px solid #ddd7d7;

                svg {
                    font-size: 20px;
                }
            }
        }
    }

    .use-voucher {
        background: #e8e6e6;
        padding: 5px 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-radius: 6px;
        margin-top: 1rem;
        > div:nth-child(1) {
            display: flex;
            align-items: center;
            gap: 10px;

            .voucher-icon {
                width: 40px;
                height: 50px;
            }

            p {
                font-size: 18px;
                font-weight: 600;
                margin-bottom: 0;
            }
        }

        .voucher-btn {
            background-image: linear-gradient(
                to right,
                #075d9b,
                #1775b8,
                #91c4e9,
                #d2e9fa
            );
            cursor: pointer;
            border-radius: 4px;
            padding: 4px 10px;
            color: #fff;
            background-position: center;
            background-size: 200%;
            transition: 0.3s;

            &:hover {
                background-position: 0%;
            }

            label {
                padding-top: 1px;
            }
        }

        @media (max-width: 418px) {
            div:nth-child(2) {
                button {
                    svg {
                        display: none;
                    }
                }
            }
        }
    }

    button {
        margin-top: 1rem;
        color: #fff;
        font-weight: 600;
        width: 100%;
        padding: 10px 0;
        border-radius: 20px;
    }

    .close-modal {
        cursor: pointer;
        position: absolute;
        top: 10px;
        right: 10px;
        transition: 0.2s;
        font-size: 24px;
        &:hover {
            opacity: 0.75;
        }
    }

    @media (max-width: 766px) {
        .detail {
            flex-direction: column;
            align-items: flex-start;

            .left {
                img {
                    width: 100%;
                }
            }
            .right {
                width: 100%;
            }
        }

        .info {
            h4 {
                font-size: 20px;
            }
        }

        .use-voucher {
            flex-direction: column;
        }

        button {
            padding: 5px 0;
        }
    }
}
</style>
