import DashboardIcons from "../icons/navigations/DashboardIcons.vue";

// eslint-disable-next-line import/prefer-default-export
export const navigations = [
    {
        nameKey: 'dashboard',
        route: {
            name: 'Dashboard'
        },
        icon: DashboardIcons
    }
]
