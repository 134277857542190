<template>
    <div class="wrap">
        <div class="history">
            <router-link :to="{ name: 'GotItHistory' }">
                {{ $t('history') }}
            </router-link>
        </div>
        <h1 class="text-center">{{ $t('superGotItGiftcard') }}</h1>
        <div class="gotit-container">
            <div v-for="(voucher, index) in GotitVoucherList" :key="index">
                <div class="img-wrap">
                    <img src="@/assets/images/mockup/gotit.png" />
                    <div class="ribbon">
                        {{ TruncateToDecimals2(Number(voucher.priceValue)) }}
                        VNĐ
                    </div>
                    <button
                        class="nice-gradient botan"
                        @click="checkout(voucher)"
                    >
                        {{ $t('get') }}
                    </button>
                </div>
            </div>
        </div>
        <b-modal
            id="gotit-checkout"
            hide-header
            hide-footer
            dialog-class="supa-modal"
            centered
        >
            <div class="mi-head">{{ $t('exchangeGiftcard') }}</div>
            <div class="detail">
                <div>
                    <div>{{ $t('giftcardValue') }}:</div>
                    <div class="price">
                        {{
                            TruncateToDecimals2(
                                selectedVoucher
                                    ? Number(selectedVoucher.priceValue)
                                    : 0,
                            )
                        }}
                        VNĐ
                    </div>
                </div>
                <div class="tear-notch"></div>
                <div>
                    <div>
                        {{ $t('estimateCost', { something: $t('cost') }) }}:
                    </div>
                    <div class="price" v-if="selectedVoucher">
                        <span v-if="selectedVoucher.xinValue">
                            {{ TruncateToDecimals2(selectedVoucher.xinValue) }}
                            XIN
                        </span>
                        <span v-else> 0 XIN </span>
                    </div>
                </div>
                <div>
                    <div>{{ $t('giftcardID') }}</div>
                    <div class="price" v-if="selectedVoucher">
                        {{ selectedVoucher.productId }}
                    </div>
                </div>
            </div>
            <button class="pay" @click="checkOutConfirm(selectedVoucher)">
                {{ $t('checkout') }}
            </button>
            <button
                class="close black"
                @click="$bvModal.hide('gotit-checkout')"
                type="button"
            >
                <svg
                    data-v-a0ac3ca6=""
                    xmlns="http://www.w3.org/2000/svg"
                    width="1em"
                    height="1em"
                    buyVoucherviewBox="0 0 24 24"
                >
                    <path
                        data-v-a0ac3ca6=""
                        fill="currentColor"
                        d="M6.4 19L5 17.6l5.6-5.6L5 6.4L6.4 5l5.6 5.6L17.6 5L19 6.4L13.4 12l5.6 5.6l-1.4 1.4l-5.6-5.6z"
                    ></path>
                </svg>
            </button>
        </b-modal>
        <b-modal
            id="checkout-confirm"
            hide-header
            hide-footer
            dialog-class="supa-modal"
            centered
        >
            <div class="mi-head">{{ $t('confirmCheckout') }}</div>
            <div class="wallet">
                <div>
                    {{ $t('balance') }}:
                    <span>
                        {{ TruncateToDecimals2(UserInfo.xinCoin) }}
                        XIN
                    </span>
                </div>
            </div>
            <div class="detail">
                <div>
                    <div>
                        {{ $t('estimateCost', { something: $t('cost') }) }}:
                    </div>
                    <div class="price" v-if="selectedVoucher">
                        <span v-if="selectedVoucher.xinValue">
                            {{ TruncateToDecimals2(selectedVoucher.xinValue) }}
                            XIN
                        </span>
                        <span v-else> 0 XIN </span>
                    </div>
                </div>
            </div>
            <div class="botans">
                <button class="botan-confirm" @click="buyVoucherGotIt">
                    {{ $t('confirm') }}
                </button>
                <button
                    class="botan-cancel"
                    @click="$bvModal.hide('checkout-confirm')"
                >
                    {{ $t('cancel') }}
                </button>
            </div>
            <button
                class="close black"
                @click="$bvModal.hide('checkout-confirm')"
                type="button"
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="1em"
                    height="1em"
                    buyVoucherviewBox="0 0 24 24"
                >
                    <path
                        fill="currentColor"
                        d="M6.4 19L5 17.6l5.6-5.6L5 6.4L6.4 5l5.6 5.6L17.6 5L19 6.4L13.4 12l5.6 5.6l-1.4 1.4l-5.6-5.6z"
                    />
                </svg>
            </button>
        </b-modal>
        <BrandsList />
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import BrandsList from '../../../components/brands/BrandsList.vue';

export default {
    name: 'GotIt',
    components: {
        BrandsList,
    },
    data() {
        return {
            chosenValue: null,
            pending: false,
            selectedVoucher: null,
        };
    },
    computed: {
        ...mapGetters({
            GotitVoucherList: 'voucher/GotitVoucherList',
            UserInfo: 'info/UserInfo',
        }),
        PriceList() {
            const array = [];
            if (this.GotitVoucherList) {
                this.GotitVoucherList.forEach((el) => {
                    if (!array.includes(Number(el.priceValue))) {
                        array.push(Number(el.priceValue));
                    }
                });
            }
            array.sort((a, b) => a - b);
            const mapped = array.map((el) => {
                return {
                    value: el,
                    text: `${this.TruncateToDecimals2(el)} VNĐ`,
                };
            });
            return [
                {
                    value: null,
                    text: this.$t('selectGiftcard'),
                },
                ...mapped,
            ];
        },
        ChosenVoucher() {
            if (this.GotitVoucherList) {
                return this.GotitVoucherList.find((el) => {
                    return Number(el.priceValue) === this.chosenValue;
                });
            }
            return undefined;
        },
    },
    methods: {
        async buyVoucherGotIt() {
            if (this.selectedVoucher) {
                if (this.pending) {
                    return;
                }
                this.pending = true;

                await this.$recaptchaLoaded();
                const token = await this.$recaptcha('buyGotit');

                this.$store
                    .dispatch('voucher/buyVoucherGotIt', {
                        productId: this.selectedVoucher.productId,
                        priceId: this.selectedVoucher.priceId,
                        price: this.selectedVoucher.priceValue,
                        _id: this.selectedVoucher._id,
                        type: 'XIN',
                        token
                    })
                    .then(async (res) => {
                        if (res.status) {
                            await this.$store.dispatch(
                                'voucher/gotItVoucherList',
                            );
                            await this.$store.dispatch('info/req_getInfo');
                            this.selectedVoucher = null;
                            this.$bvModal.hide('gotit-checkout');
                            this.$bvModal.hide('checkout-confirm');
                        }
                    })
                    .finally(() => {
                        this.pending = false;
                    });
            }
        },
        LockIn() {
            if (this.chosenValue) {
                this.$bvModal.show('gotit-checkout');
            }
        },
        checkout(voucher) {
            this.selectedVoucher = voucher;
            console.log('selected voucher: ', this.selectedVoucher);
            this.$bvModal.show('gotit-checkout');
        },
        checkOutConfirm(voucher) {
            this.selectedVoucher = voucher;
            this.$bvModal.show('checkout-confirm');
        },
    },
    mounted() {
        this.$store.dispatch('voucher/gotItVoucherList');
    },
};
</script>
<style lang="scss" scoped>
.my-container {
    padding-bottom: 60px;
    background-image: url('~@/assets/images/mockup/pattern.png');
    min-height: 80vh;
    margin-bottom: -100px;
    > div {
        margin: 0 auto;
        max-width: 650px;
    }
}
// .wrap {
//     margin: 0 auto;
//     // max-width: 680px;
// }
.img-wrap {
    text-align: center;
    position: relative;
    overflow: hidden;
    filter: drop-shadow(2px 2px 2px #000000aa);
    > img {
        max-width: 100%;
    }
    .ribbon {
        background-color: #fff;
        position: absolute;
        right: 0;
        top: 15%;
        line-height: 1.6;
        font-size: 20px;
        font-weight: 600;
        color: #be3030;
        filter: drop-shadow(1px 1px 2px #000000aa);
        &::before {
            content: '';
            position: absolute;
            border: 0.8em solid #fff;
            border-left-color: transparent;
            right: 100%;
            top: 0;
            bottom: 0;
        }

        @media (max-width: 1300px) {
            font-size: 16px;
        }
    }
}
.botan {
    position: absolute;
    right: 0%;
    bottom: 15%;
    display: flex;
    align-items: center;
    gap: 6px;
    border-top-left-radius: 9px;
    border-bottom-left-radius: 9px;
    padding: 4px 15px;
    // background: #fff;
    color: #fff;
    font-size: 18px;
    font-weight: 700;
    text-transform: uppercase;
    transition: 0.2s;

    &:hover {
        text-decoration: underline;
    }

    @media (max-width: 1300px) {
        font-size: 14px;
        padding: 0 15px;
    }
}

.voucher-form {
    padding: 15px 0px;
    > label {
        display: block;
        font-size: 20px;
        font-weight: 600;
    }
    // .botan {
    //     width: 100%;
    //     border-radius: 9px;
    //     padding: 10px 15px;
    //     color: #fff;
    //     font-size: 24px;
    //     font-weight: 700;
    //     text-transform: uppercase;
    // }
    .deets {
        display: flex;
        font-size: 20px;
        font-weight: 700;
        justify-content: space-between;
        padding: 5px 0px;
        transition: 0.2s;
        &.hide {
            opacity: 0;
        }
        .price {
            color: #0087cc;
        }
    }
}

.gotit-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;

    @media (max-width: 1025px) {
        grid-template-columns: repeat(2, 1fr);
        gap: 1rem;
    }

    @media (max-width: 575px) {
        grid-template-columns: repeat(1, 1fr);
        gap: 1rem;
    }
}

.mi-head {
    font-size: 24px;
    font-weight: 600;
    color: #0087cc;
    text-align: center;

    &.fail {
        color: #cc0000;
    }
    @media (min-width: 768px) {
        font-size: 30px;
    }
}
#gotit-checkout {
    .detail {
        font-size: 18px;
        font-weight: 600;
        > div {
            display: flex;
            justify-content: space-between;
            padding: 5px;
            .price {
                color: #0087cc;
            }
            &:last-child {
                padding-bottom: 20px;
            }
        }
        .tear-notch {
            border-bottom: dashed 2px #0000006c;
            padding: 0;
            margin: 10px 0px;
        }
    }
    .pay {
        background-color: #0087cc;
        color: #fff;
        border-radius: 100vw;
        padding: 8px 15px;
        font-size: 20px;
        font-weight: 700;
        width: 100%;
        display: block;
        max-width: 240px;
        margin: auto;
    }
}
#checkout-confirm {
    .wallet {
        font-size: 18px;
        font-weight: 600;
        background-color: #c4e3f5;
        border-radius: 6px;
        padding: 8px 15px;
        color: #1f2937;
        div {
            > span {
                color: #0087cc;
            }
        }
    }
    .detail {
        font-size: 18px;
        font-weight: 600;
        > div {
            display: flex;
            justify-content: space-between;
            padding: 5px;
            .price {
                color: #0087cc;
            }
            &:last-child {
                padding-bottom: 20px;
            }
        }
        .tear-notch {
            border-bottom: dashed 2px #0000006c;
            padding: 0;
            margin: 10px 0px;
        }
    }
    .botans {
        display: flex;
        align-items: center;
        justify-content: space-around;
        gap: 10px;

        .botan-confirm,
        .botan-cancel {
            border: 1px solid currentColor;
            border-radius: 100vw;
            padding: 8px 15px;
            font-size: 20px;
            font-weight: 700;
            flex-grow: 1;
        }

        .botan-confirm {
            color: #fff;
            background-color: #0087cc;
        }

        .botan-cancel {
            color: #0087cc;
            background-color: #fff;
        }
    }
}
.slide-left-enter-active {
    transition: 0.4s;
}
.slide-left-enter {
    transform: translate(100%);
}

.history {
    text-align: right;
    padding-right: 15px;
    padding-bottom: 15px;
    a {
        font-size: 18px;
        font-weight: 600;
        color: #fff;
        padding: 0px 10px;
        position: relative;
        line-height: 1.6;
        display: inline-block;
        background-color: #0087cc;
        filter: drop-shadow(1px 1px 2px #a1a1a1);
        &:hover {
            text-decoration: underline;
        }
        &::before {
            content: '';
            position: absolute;
            border: 0.8em solid #0087cc;
            border-left-color: transparent;
            right: 100%;
            top: 0;
            bottom: 0;
        }
        &::after {
            content: '';
            position: absolute;
            border: 0.8em solid transparent;
            border-color: transparent transparent transparent #0087cc;
            left: 100%;
            top: 0;
            bottom: 0;
        }
    }
}
</style>
