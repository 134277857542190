<template>
    <div>
        <apexchart
            width="335"
            type="pie"
            :options="chartOptions"
            :series="series"
        ></apexchart>
    </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts';

export default {
    name: 'StakingSharePieChart',
    components: {
        apexchart: VueApexCharts,
    },
    props: {
        series: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            chartOptions: {
                chart: {
                    width: '100%',
                    type: 'pie',
                },
                labels: [],
                theme: {
                    monochrome: {
                        enabled: false,
                    },
                },
                colors: ['#1E90FF', '#004AAD'],
                dataLabels: {
                    enabled: true,
                    style: {
                        colors: ['#ffffff'],
                    },
                    formatter(value, { seriesIndex }) {
                        return seriesIndex === 0
                            ? `${value.toLocaleString('en-US', {
                                  maximumFractionDigits: 1,
                              })}%`
                            : '';
                    },
                },
                plotOptions: {
                    pie: {
                        dataLabels: {
                            offset: -10,
                        },
                    },
                },
                tooltip: {
                    enabled: true,
                    fillSeriesColor: false,
                    theme: 'light',
                    y: {
                        formatter(value) {
                            return `${Number(value).toLocaleString(
                                'en-US',
                            )} XIN`;
                        },
                    },
                },
                legend: {
                    position: 'right',
                    markers: {
                        width: 12,
                        height: 12,
                        radius: 12,
                    },
                    width: 140,
                    offsetY: 30,
                    offsetX: 10,
                    horizontalAlign: 'center',
                    labels: {
                        colors: ['#3C3C3C'],
                        useSeriesColors: false,
                    },
                },
                responsive: [
                    {
                        breakpoint: 992,
                        options: {
                            legend: {
                                position: 'bottom',
                                offsetY: 0,
                                horizontalAlign: 'left',
                            },
                        },
                    },
                    {
                        breakpoint: 1440,
                        options: {
                            legend: {
                                position: 'bottom',
                                offsetY: 0,
                                horizontalAlign: 'left',
                                width: 200,
                            },
                            style: {
                                fontSize: '11px',
                                colors: ['#ffffff'],
                            },
                        },
                    },
                    {
                        breakpoint: 1220,
                        options: {
                            legend: {
                                position: 'bottom',
                                offsetY: 0,
                                width: 200,
                            },
                        },
                    },
                ],
            },
        };
    },
    computed: {
        translatedLabels() {
            return [this.$t('myStake'), this.$t('totalPoolStake')];
        },
    },
    watch: {
        translatedLabels(newLabels) {
            this.updateLabels(newLabels);
        },
    },
    methods: {
        updateLabels(newLabels) {
            this.chartOptions = {
                ...this.chartOptions,
                labels: newLabels,
            };
        },
    },
    mounted() {
        this.updateLabels(this.translatedLabels);
    },
};
</script>
<style scoped>
.apexcharts-legend {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
}
</style>
