<template>
    <div
        class="support-container px-4 py-4 mb-8 d-flex flex-column gap-3 text-dark"
    >
        <div
            class="request-btn d-flex flex-column flex-lg-row justify-content-end align-items-center mb-3"
        >
            <button class="mt-3 mt-lg-0" @click="$bvModal.show('openConfirm')">
                {{ $t('requestSupport') }} (-{{ Fees.buyTicketXin }}
                <img src="@/assets/images/icons/sp.svg" alt="" />)
            </button>
            <!-- <b-button variant="primary"> </b-button> -->
        </div>
        <div class="support-table">
            <div
                class="d-flex align-items-center justify-content-between header"
            >
                <h3 class="font-weight-bold">{{ $t('supportCenter') }}</h3>
                <form
                    @submit.prevent
                    inline
                    class="d-flex flex-column flex-wrap-1 flex-lg-row align-items-center justify-content-center"
                >
                    <div
                        class="flex-shrink-lg-0 mb-2 mb-lg-0 mr-lg-2 w-lg-auto searching-input-box"
                    >
                        <select
                            v-model="selectedStatus"
                            class="p-2 w-100"
                            style="
                                border-radius: 12px;
                                border: 1px solid #ced4da;
                                cursor: pointer;
                            "
                        >
                            <option
                                v-for="(item, index) in StatusTicket"
                                :key="index"
                                :value="item.value"
                            >
                                {{ item.label }}
                            </option>
                        </select>
                    </div>
                    <div
                        class="flex-shrink-lg-0 mx-2 w-lg-auto mb-3 mb-lg-0 order-3 order-lg-1 searching-input-box"
                    >
                        <b-form-datepicker
                            :date-format-options="{
                                year: 'numeric',
                                month: 'numeric',
                                day: 'numeric',
                            }"
                            v-model="params.dateReportFrom"
                            :placeholder="$t('startTime')"
                            class="w-100 bg-white"
                            @input="onDateReportFromChange"
                        ></b-form-datepicker>
                    </div>
                    <div
                        class="flex-shrink-lg-0 mx-2 w-lg-auto mb-3 mb-lg-0 order-4 order-lg-2 searching-input-box"
                    >
                        <b-form-datepicker
                            :date-format-options="{
                                year: 'numeric',
                                month: 'numeric',
                                day: 'numeric',
                            }"
                            v-model="params.dateReportTo"
                            :placeholder="$t('endTime')"
                            class="w-100 bg-white"
                            @input="onDateReportToChange"
                        ></b-form-datepicker>
                    </div>
                    <div
                        class="mx-2 w-lg-auto mb-3 mb-lg-0 order-2 order-lg-3 searching-input-box"
                    >
                        <b-form-input
                            v-model="params.query"
                            :placeholder="$t('search')"
                            class="w-100 bg-white"
                            @change="handleSearch"
                        ></b-form-input>
                    </div>
                </form>
            </div>
            <b-table
                v-if="supportList && supportList.results"
                striped
                hover
                :items="supportList.results"
                show-empty
                :fields="fields"
            >
                <template #empty>
                    <h4 class="text-center my-3">
                        {{ $t('noData') }}
                    </h4>
                </template>
                <template #head()="{ label }">
                    {{ $t(label) }}
                </template>
                <!-- <template #cell()="{ value }">
                    <span>{{ value }}</span>
                </template> -->
                <template #cell(status)="{ value }">
                    <span class="in-progress" v-if="value === 'in_progress'">
                        {{ $t('inProgress') }}
                    </span>
                    <span class="approved" v-else-if="value === 'approved'">
                        {{ $t('approve') }}
                    </span>
                    <span
                        class="in-response"
                        v-else-if="value === 'in_response'"
                    >
                        {{ $t('inResponse') }}
                    </span>
                    <span class="rejected" v-else-if="value === 'rejected'">
                        {{ $t('reject') }}
                    </span>
                </template>
                <!-- <template #cell(more)>
                    <div class="more" @click="">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="1em"
                            height="1em"
                            viewBox="0 0 24 24"
                        >
                            <g
                                fill="none"
                                stroke="currentColor"
                                stroke-dasharray="16"
                                stroke-dashoffset="16"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                            >
                                <path d="M5 12h14" stroke-dashoffset="0" />
                                <path d="M12 5v14" stroke-dashoffset="0" />
                            </g>
                        </svg>
                    </div>
                </template> -->
                <template #cell(chat)="{ item }">
                    <Chat :item="item" />
                </template>
                <template #cell(amount)="{ value }">
                    <div class="fee">
                        {{ value }}
                        <img src="@/assets/images/icons/sp.svg" alt="" />
                    </div>
                </template>
                <template #cell(createdAt)="{ value }">
                    <span>{{ formatDate(value) }}</span>
                </template>
                <template #cell(dateResponse)="{ value }">
                    <span v-if="value">{{ formatDate(value) }}</span>
                    <span v-else>{{ $t('noResponse') }}</span>
                </template>
                <template #cell(show_details)="row">
                    <button class="btn-more" @click="row.toggleDetails">
                        {{ row.detailsShowing ? $t('hide') : $t('show') }}
                    </button>
                    <!-- <b-button size="sm" variant="info" class="px-2 py-0">
                        {{ row.detailsShowing ? $t('hide') : $t('show') }}
                    </b-button> -->
                </template>

                <template #row-details="{ item }">
                    <div class="detail">
                        <div>
                            {{ $t('ticketId') }}:
                            <div>{{ item.refId }}</div>
                        </div>
                        <div>
                            {{ $t('requestTime') }}:
                            <div>
                                {{ new Date(item.createdAt).toLocaleString() }}
                            </div>
                        </div>
                        <div>
                            {{ $t('requestTitle') }}:
                            <div>
                                {{ item.title }}
                            </div>
                        </div>
                        <div>
                            {{ $t('requestDesc') }}:
                            <div>
                                {{ item.description }}
                            </div>
                        </div>
                        <div>
                            {{ $t('uploadImage') }}:
                            <div
                                class="file-list"
                                v-if="item && item.images.length > 0"
                            >
                                <div v-for="image in item.images" :key="image">
                                    <img
                                        v-if="
                                            image.endsWith('.jpg') ||
                                            image.endsWith('.jpeg') ||
                                            image.endsWith('.png') ||
                                            image.endsWith('.gif')
                                        "
                                        @click="
                                            $bvModal.show('img-preview' + image)
                                        "
                                        :src="image"
                                        alt="image"
                                    />
                                    <b-modal
                                        content-class="custom-class-modal"
                                        :id="'img-preview' + image"
                                        hide-footer
                                        hide-header
                                        centered
                                    >
                                        <img :src="image" alt="img" />
                                    </b-modal>
                                </div>
                            </div>
                            <div v-else>{{ $t('noFile') }}</div>
                        </div>
                        <div>
                            {{ $t('uploadVideo') }}:
                            <div
                                class="file-list"
                                v-if="item && item.images.length > 0"
                            >
                                <div v-for="file in item.images" :key="file">
                                    <video
                                        class="video"
                                        v-if="
                                            file.endsWith('.mp4') ||
                                            file.endsWith('.webm') ||
                                            file.endsWith('.ogg')
                                        "
                                        @click="
                                            $bvModal.show(
                                                'video-preview' + file,
                                            )
                                        "
                                        :src="file"
                                        alt="file"
                                    ></video>
                                    <b-modal
                                        content-class="custom-class-modal"
                                        :id="'video-preview' + file"
                                        hide-footer
                                        hide-header
                                        centered
                                    >
                                        <video :src="file" alt="file"></video>
                                    </b-modal>
                                </div>
                            </div>
                            <div v-else>{{ $t('noFile') }}</div>
                        </div>
                    </div>
                </template>
            </b-table>
        </div>

        <b-pagination
            class="mt-3"
            v-if="supportList && supportList.results"
            v-model="supportList.page"
            :total-rows="supportList.totalResults"
            :per-page="supportList.limit"
            @change="handlePageChange"
        ></b-pagination>

        <b-modal id="openConfirm" hide-footer hide-header centered>
            <svg
                @click="$bvModal.hide('openConfirm')"
                xmlns="http://www.w3.org/2000/svg"
                width="1em"
                height="1em"
                viewBox="0 0 24 24"
            >
                <path
                    fill="currentColor"
                    d="M6.4 19L5 17.6l5.6-5.6L5 6.4L6.4 5l5.6 5.6L17.6 5L19 6.4L13.4 12l5.6 5.6l-1.4 1.4l-5.6-5.6z"
                />
            </svg>
            <h3>
                {{ $t('createTicket') }}
            </h3>
            <form @submit.prevent="handleAddTicket">
                <div>
                    <label>{{ $t('title') }}</label>
                    <b-form-input
                        type="text"
                        :placeholder="$t('title')"
                        v-model="title"
                        class="w-100 font-weight-medium mb-3"
                    />
                    <p class="text-error">{{ formError.title }}</p>
                </div>
                <div>
                    <label>{{ $t('description') }}</label>
                    <b-form-textarea
                        v-model="description"
                        :placeholder="$t('description')"
                        :maxlength="MAX_LENGTH"
                        class="w-100"
                        @input="handleInput"
                    >
                    </b-form-textarea>
                    <span class="text-sm"
                        >{{ description.length }} / {{ MAX_LENGTH }}</span
                    >
                    <p class="text-error">{{ formError.description }}</p>
                </div>
                <div class="upload-wrap w-100 mt-3">
                    <div class="thumbnails" v-if="thumbnailsTicket.length">
                        <ul class="row w-100 list-unstyled">
                            <li
                                v-for="(file, index) in thumbnailsTicket"
                                :key="index"
                                class="col-auto"
                            >
                                <div class="position-relative">
                                    <img
                                        :src="file"
                                        alt="Image preview"
                                        class="w-100 h-100 object-cover"
                                        style="
                                            max-width: 100px;
                                            max-height: 100px;
                                        "
                                    />
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="1em"
                                        height="1em"
                                        viewBox="0 0 24 24"
                                        class="delete position-absolute bg-danger text-white rounded-circle p-1"
                                        @click="removeFile(index)"
                                        style="
                                            top: -0.5rem;
                                            right: -0.5rem;
                                            width: 1.5rem;
                                            height: 1.5rem;
                                        "
                                    >
                                        <path
                                            fill="currentColor"
                                            d="M6.4 19L5 17.6l5.6-5.6L5 6.4L6.4 5l5.6 5.6L17.6 5L19 6.4L13.4 12l5.6 5.6l-1.4 1.4l-5.6-5.6z"
                                        />
                                    </svg>
                                </div>
                            </li>
                        </ul>
                    </div>

                    <div class="thumbnails" v-if="filesTicketUrls.length">
                        <ul class="row w-100 list-unstyled">
                            <li
                                v-for="(file, index) in filesTicketUrls"
                                :key="index"
                                class="col-auto"
                            >
                                <div class="position-relative">
                                    <img
                                        :src="file.url"
                                        alt="Image preview"
                                        class="w-100 h-100 object-cover"
                                        style="
                                            max-width: 100px;
                                            max-height: 100px;
                                        "
                                    />
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="1em"
                                        height="1em"
                                        viewBox="0 0 24 24"
                                        class="delete position-absolute bg-danger text-white rounded-circle p-1"
                                        @click="removeFile(index)"
                                        style="
                                            top: -0.5rem;
                                            right: -0.5rem;
                                            width: 1.5rem;
                                            height: 1.5rem;
                                        "
                                    >
                                        <path
                                            fill="currentColor"
                                            d="M6.4 19L5 17.6l5.6-5.6L5 6.4L6.4 5l5.6 5.6L17.6 5L19 6.4L13.4 12l5.6 5.6l-1.4 1.4l-5.6-5.6z"
                                        />
                                    </svg>
                                </div>
                            </li>
                        </ul>
                    </div>

                    <div class="upload">
                        <label for="upload">
                            <div class="custom-input">{{ $t('upload') }}</div>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24px"
                                height="24px"
                                viewBox="0 0 48 48"
                            >
                                <path
                                    fill="none"
                                    stroke="currentColor"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="4"
                                    d="M24.707 9.565L9.858 24.415a9 9 0 0 0 0 12.727v0a9 9 0 0 0 12.728 0l17.678-17.677a6 6 0 0 0 0-8.486v0a6 6 0 0 0-8.486 0L14.101 28.657a3 3 0 0 0 0 4.243v0a3 3 0 0 0 4.242 0l14.85-14.85"
                                />
                            </svg>
                            <input
                                class="d-none"
                                id="upload"
                                type="file"
                                multiple
                                @change="handleFileChange"
                                ref="fileInput"
                            />
                        </label>
                    </div>
                    <p class="note">
                        {{ $t('note') + ': ' + $t('max5imgLessThan2mb') }}
                    </p>
                </div>
                <div class="buttons d-flex gap-2 justify-content-around pt-4">
                    <button
                        type="button"
                        class="cancel"
                        @click="$bvModal.hide('openConfirm')"
                    >
                        {{ $t('cancel') }}
                    </button>
                    <button class="confirm">
                        {{ $t('confirm') }}
                    </button>
                </div>
            </form>
        </b-modal>
        <b-modal
            id="ticket-confirm"
            hide-header
            hide-footer
            dialog-class="supa-modal"
            centered
        >
            <div class="mi-head">{{ $t('confirmTicket') }}?</div>
            <div class="mi-confirm">
                {{ $t('requestATicketCostNumberXIN', { number: Fees.buyTicketXin }) }}?
            </div>
            <div class="botans">
                <button class="botan confirm" @click="confirmSendTicket">
                    {{ $t('confirm') }}
                </button>
                <button class="botan" @click="$bvModal.hide('ticket-confirm')">
                    {{ $t('cancel') }}
                </button>
            </div>
            <button
                class="close black"
                @click="$bvModal.hide('ticket-confirm')"
                type="button"
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="1em"
                    height="1em"
                    buyVoucherviewBox="0 0 24 24"
                >
                    <path
                        fill="currentColor"
                        d="M6.4 19L5 17.6l5.6-5.6L5 6.4L6.4 5l5.6 5.6L17.6 5L19 6.4L13.4 12l5.6 5.6l-1.4 1.4l-5.6-5.6z"
                    />
                </svg>
            </button>
        </b-modal>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { debounce } from 'lodash';
import Chat from '../../components/support/Chat.vue';

export default {
    components: {
        Chat,
    },

    data() {
        return {
            pendingPayment: false,
            MAX_LENGTH: 500,
            description: '',
            title: '',
            selectedStatus: '',
            thumbnailsTicket: [],
            videos: [],
            fileList: [],
            fileInput: null,
            fields: [
                {
                    key: 'refId',
                    label: 'ID',
                    class: 'text-center',
                },
                {
                    key: 'title',
                    label: this.$t('title'),
                    class: 'title',
                },
                {
                    key: 'createdAt',
                    label: this.$t('dateReport'),
                    class: 'text-center',
                },
                {
                    key: 'amount',
                    label: this.$t('amount'),
                    class: 'text-center amount',
                },
                {
                    key: 'dateResponse',
                    label: this.$t('dateResponse'),
                    class: 'text-center',
                },
                {
                    key: 'status',
                    label: this.$t('status'),
                    class: 'text-center status',
                },
                {
                    key: 'Chat',
                    label: this.$t('chat'),
                    class: 'text-center chat',
                },
                {
                    key: 'show_details',
                    label: this.$t('moreInfo'),
                    class: 'text-center show_details',
                },
            ],
            params: {
                limit: 10,
                page: 1,
                dateReportFrom: '',
                dateReportTo: '',
                status: '',
                query: '',
            },
            tickets: null,
            myDate: null,
            myMessageList: null,
            formError: {
                title: '',
                description: '',
            },
        };
    },

    computed: {
        ...mapGetters({
            supportList: 'support/supportList',
            MessageList: 'support/MessageList',
            Fees: 'wallet/Fees',
        }),

        StatusTicket() {
            return [
                { label: this.$t('all'), value: '' },
                { label: this.$t('inProgress'), value: 'in_progress' },
                { label: this.$t('approve'), value: 'approved' },
                { label: this.$t('reject'), value: 'rejected' },
            ];
        },

        isTitleValid() {
            return this.title.trim().length > 5;
        },

        isDescriptionValid() {
            return this.description.trim().length > 5;
        },

        filesTicketUrls() {
            return this.fileList.map((file) => ({
                name: file.name,
                url: URL.createObjectURL(file),
            }));
        },
    },

    methods: {
        ...mapActions('support', ['req_listTicket', 'reqTicket']),

        handlePageChange(page) {
            console.log(page);
            this.params.page = page;
            this.reqTableData();
        },

        snapImage(video, url) {
            const canvas = document.createElement('canvas');
            canvas.width = video.videoWidth;
            canvas.height = video.videoHeight;

            const ctx = canvas.getContext('2d');
            if (!ctx) return null;

            ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
            const image = canvas.toDataURL();
            const success = image.length > 100000;

            if (success) {
                this.thumbnailsTicket.push(image);
                URL.revokeObjectURL(url);
            }

            return success;
        },

        handleFileChange(event) {
            const { files } = event.target;
            const type = files[0].type.split('/')[0];

            if (type === 'video') {
                if (this.videos.length + files.length > 1) {
                    this.$toastr.e('You can only upload 1 videos');
                    return;
                }

                this.videos.push(...Array.from(files));
                const fileReader = new FileReader();

                fileReader.onload = () => {
                    if (!fileReader.result) {
                        console.error('Failed to read file');
                        return;
                    }

                    const blob = new Blob([fileReader.result], {
                        type: files[0].type,
                    });

                    const url = URL.createObjectURL(blob);
                    const video = document.createElement('video');

                    const timeupdate = () => {
                        if (this.snapImage(video, url)) {
                            video.removeEventListener('timeupdate', timeupdate);
                            video.pause();
                        }
                    };

                    video.addEventListener('loadeddata', () => {
                        if (this.snapImage(video, url)) {
                            video.removeEventListener('timeupdate', timeupdate);
                        }
                    });

                    video.src = url;
                    video.addEventListener('timeupdate', timeupdate);
                    video.preload = 'metadata';
                    video.muted = true;
                    video.playsInline = true;
                    video.play();
                };

                fileReader.readAsArrayBuffer(files[0]);
            }

            if (type === 'image') {
                if (this.filesTicketUrls.length + files.length > 5) {
                    this.$toastr.e('You can only upload 5 images');
                    return;
                }

                this.fileList.push(...Array.from(files));
            }
        },

        triggerFileInput() {
            if (this.$refs.fileInput) {
                this.$refs.fileInput.click();
            }
        },

        handleSearch: debounce(function () {
            this.reqTableData();
        }, 500),

        removeFile(index) {
            URL.revokeObjectURL(this.fileList[index].preview);
            this.fileList.splice(index, 1);
        },

        onDateReportFromChange(newVal) {
            this.params.dateReportFrom = newVal;
            this.reqTableData();
        },

        onDateReportToChange(newVal) {
            this.params.dateReportTo = newVal;
            this.reqTableData();
        },

        formatDate(input) {
            const options = {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit',
                hour12: false,
                weekday: 'short',
            };
            const date = new Date(input);

            return date.toLocaleDateString('en-US', options);
        },

        handleInput(event) {
            this.description = event.target.value;
        },

        async handleAddTicket() {
            const validations = [
                {
                    field: 'title',
                    isValid: this.isTitleValid,
                    errorMessage:
                        'This is a required field and must be at least 5 characters.',
                },
                {
                    field: 'description',
                    isValid: this.isDescriptionValid,
                    errorMessage:
                        'This is a required field and must be at least 5 characters.',
                },
            ];

            let hasError = false;

            validations.forEach(({ field, isValid, errorMessage }) => {
                if (!isValid) {
                    this.formError[field] = errorMessage;
                    hasError = true;
                } else {
                    this.formError[field] = '';
                }
            });

            if (hasError) return;

            // const formData = new FormData();
            // formData.append('title', this.title);
            // formData.append('description', this.description);

            // this.fileList.forEach((file) => {
            //     formData.append('files', file);
            // });

            // this.videos.forEach((file) => {
            //     formData.append('files', file);
            // });

            // const response = await this.$store.dispatch(
            //     'support/reqTicket',
            //     formData,
            // );

            // if (!response) {
            //     this.pendingPayment = false;
            //     return;
            // }

            // this.$bvModal.hide('openConfirm');
            // this.description = '';
            // this.title = '';
            // this.fileList = [];
            // this.pendingPayment = false;

            this.$bvModal.show('ticket-confirm');
        },
        async reqTableData() {
            Object.keys(this.params).forEach((key) => {
                if (!this.params[key]) {
                    delete this.params[key];
                }
            });
            await this.$store.dispatch('support/req_listTicket', {
                params: this.params,
            });
        },
        async confirmSendTicket() {
            if (this.pendingPayment) {
                return;
            }

            const formData = new FormData();
            formData.append('title', this.title);
            formData.append('description', this.description);

            this.fileList.forEach((file) => {
                formData.append('files', file);
            });

            this.videos.forEach((file) => {
                formData.append('files', file);
            });

            const response = await this.$store.dispatch(
                'support/reqTicket',
                formData,
            );

            if (!response) {
                this.pendingPayment = false;
                this.$bvModal.hide('ticket-confirm');
                return;
            }

            this.$bvModal.hide('openConfirm');
            this.$bvModal.hide('ticket-confirm');
            this.description = '';
            this.title = '';
            this.fileList = [];
            this.pendingPayment = false;
        },
    },

    watch: {
        selectedStatus() {
            this.params.status = this.selectedStatus;
            this.reqTableData();
        },
    },

    mounted() {
        this.reqTableData();
    },
};
</script>

<style scoped lang="scss">
.searching-input-box {
    width: 100%;

    @media (min-width: 992px) {
        width: auto;
    }
}

.mi-head {
    font-size: 24px;
    font-weight: 600;
    color: #0087cc;
    text-align: center;

    &.fail {
        color: #cc0000;
    }

    @media (min-width: 768px) {
        font-size: 30px;
    }
}

.mi-confirm {
    font-size: 18px;
    text-align: center;
    padding: 15px 0px;
}

.botans {
    display: flex;
    align-items: center;
    justify-content: space-around;
    gap: 10px;

    .botan {
        color: #0087cc;
        border: 1px solid currentColor;
        border-radius: 100vw;
        padding: 8px 15px;
        font-size: 20px;
        font-weight: 700;
        flex-grow: 1;

        &.confirm {
            color: #fff;
            background-color: #0087cc;
        }
    }
}
</style>

<style lang="scss">
.support-container {
    .support-table {
        overflow-x: auto;
        border: 1px solid #d8d8d8;
        border-radius: 12px;
        padding: 15px;
        background: #fff;

        .header {
            h3 {
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 1;
                overflow: hidden;
            }

            form {
                div {
                    label {
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 1;
                        overflow: hidden;
                    }
                }
            }
        }
    }

    .request-btn {
        button {
            width: 240px;
            color: #fff;
            background: #0087cc;
            border-radius: 22px;
            padding: 10px 15px;
            outline: none;
            transition: 0.2s;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 4px;
            
            &:hover {
                opacity: 0.7;
            }
        }
    }

    table {
        min-width: 1200px;
        margin-top: 2rem;

        .title {
            width: 150px;
        }

        .amount {
            width: 100px;
        }

        .status {
            width: 200px;
        }

        .chat {
            width: 200px;
        }

        .show_details {
            width: 150px;
        }

        .contact {
            color: #0087cc;
            font-weight: 700;
            display: flex;
            align-items: center;
            gap: 5px;
            transition: 0.2s;
            cursor: pointer;
            justify-content: center;

            &:hover {
                text-decoration: underline;
            }
        }

        .fee {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 5px;
        }

        .in-progress {
            background: #fdf8ec;
            color: #d4a60f;
            border: 1px solid #efd8ab;
            border-radius: 22px;
            padding: 3px 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 10px;

            &::before {
                content: '';
                display: block;
                width: 10px;
                height: 10px;
                border-radius: 100%;
                background: #d4a60f;
            }
        }

        .approved {
            background: #ecfdf3;
            color: #067647;
            border: 1px solid #abefc6;
            border-radius: 22px;
            padding: 3px 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 10px;

            &::before {
                content: '';
                display: block;
                width: 10px;
                height: 10px;
                border-radius: 100%;
                background: #067647;
            }
        }

        .in-response {
            background: #cdeeff;
            color: #0087cc;
            border: 1px solid #0087cc;
            border-radius: 22px;
            padding: 3px 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 10px;

            &::before {
                content: '';
                display: block;
                width: 10px;
                height: 10px;
                border-radius: 100%;
                background: #0087cc;
            }
        }

        .rejected {
            background: #fdecec;
            color: #d51111;
            border: 1px solid #efabab;
            border-radius: 22px;
            padding: 3px 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 10px;

            &::before {
                content: '';
                display: block;
                width: 10px;
                height: 10px;
                border-radius: 100%;
                background: #d51111;
            }
        }

        .more {
            svg {
                cursor: pointer;
            }
        }

        .detail {
            > div {
                display: flex;
                justify-content: space-between;
                padding: 20px 0;
                border-bottom: 1px solid #d8d8d8;

                > div {
                    color: #0087cc;
                    font-weight: 700;
                }

                img {
                    width: 200px;
                    cursor: pointer;
                    transition: 0.2s;

                    &:hover {
                        opacity: 0.7;
                    }
                }
            }
        }

        .btn-more {
            background: #0087cc;
            color: #fff;
            border-radius: 12px;
            padding: 4px 10px;
            outline: none;
            transition: 0.2s;

            &:hover {
                opacity: 0.7;
            }
        }
    }

    @media (max-width: 1280px) {
        .support-table {
            .header {
                flex-direction: column;
                gap: 12px;
            }
        }
    }
}

#openConfirm {
    .modal-dialog {
        max-width: 660px;
    }

    .modal-body {
        border-radius: 22px;
        padding: 32px;

        h3 {
            font-size: 30px;
            font-weight: 700;
            text-align: center;
        }

        > svg {
            color: #a4a4a4;
            font-size: 20px;
            position: absolute;
            top: 15px;
            right: 15px;
            cursor: pointer;
            transition: 0.2s;

            &:hover {
                color: #000;
            }
        }

        .upload-wrap {
            .upload {
                label {
                    cursor: pointer;
                    position: relative;
                    margin: 0;
                    width: 100%;

                    svg {
                        position: absolute;
                        top: 50%;
                        transform: translateY(-50%);
                        right: 10px;
                    }

                    .custom-input {
                        padding: 8px 12px;
                        border-radius: 8px;
                        border: 1px solid #d8d8d8;
                    }
                }
            }

            .delete {
                cursor: pointer;
            }

            .note {
                font-size: 12px;
                color: #ff4d4f;
                margin: 0;
            }
        }

        form {
            .text-error {
                font-size: 14px;
                color: red;
            }

            input[type='text'],
            textarea {
                padding: 8px 12px;
                border-radius: 8px;
                border: 1px solid #d8d8d8;
                outline: none;
                background: white;
            }

            textarea {
                height: 256px;
            }

            .upload {
                .upload-input {
                    position: relative;
                    width: 100%;

                    input {
                        cursor: pointer;
                        caret-color: transparent;
                    }

                    svg {
                        font-size: 14px;
                        position: absolute;
                        top: 10px;
                        right: 10px;
                        cursor: pointer;
                    }
                }
            }

            .buttons {
                .cancel {
                    width: 270px;
                    border: 1px solid #0087cc;
                    border-radius: 22px;
                    color: #0087cc;
                    padding: 7px 15px;
                }

                .confirm {
                    width: 270px;
                    background: #0087cc;
                    border-radius: 22px;
                    color: #fff;
                    padding: 7px 15px;
                }
            }
        }
    }
}

.modal {
    .modal-dialog {
        justify-content: center;
    }

    .custom-class-modal {
        background-color: transparent;
        width: fit-content;

        .modal-body {
            background-color: transparent;

            img {
                max-width: 100%;
            }

            video {
                height: 500px;
            }
        }
    }
}

.file-list {
    display: flex;
    gap: 12px;

    div {
        width: 100px !important;
        height: 100px;
    }

    img {
        width: 100% !important;
        height: 100%;
    }

    .video {
        width: 100px;
        height: 100px;
    }
}
</style>
