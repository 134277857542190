<template>
    <svg
        :class="classIcon"
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 48 48"
    >
        <path
            fill="none"
            stroke="#c5c5c5"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="4"
            d="M19 12L31 24L19 36"
        />
    </svg>
</template>
<script>
export default {
  name: 'ArrowDownIcon',
  props : {
    classIcon: String,
  }
};
</script>
<style lang=""></style>
