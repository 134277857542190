<template lang="">
    <div class="staking-pool-container">
        <h3>{{ $t('totalPoolStake') }}:</h3>
        <div class="staking-pool-detail">
            <span>
                {{ $t('totalStake') }}
            </span>
            <span> {{ StakeStat.totalStakingAmount.toLocaleString('en-US') }} XIN </span>
        </div>
        <div class="staking-pool-detail">
            <span> {{ $t('totalStakeProfit') }}: </span>
            <span> {{ StakeStat.totalStakingProfit.toLocaleString('en-US') }} XIN </span>
        </div>
        <div class="staking-pool-detail">
            <span> {{ $t('total12Months') }}: </span>
            <span>
                {{ StakeStat.totalOneYearPackage.toLocaleString('en-US') }} {{ $t('packages') }}
            </span>
        </div>
        <div class="staking-pool-detail">
            <span> {{ $t('totalPoolStake1%') }}: </span>
            <span> {{ StakeStat.totalOneYearStakingAmount.toLocaleString('en-US') }} XIN </span>
        </div>
        <div class="staking-pool-detail">
            <span> {{ $t('poolReward') }}: </span>
            <span> {{ StakeStat.rewardPool.toLocaleString('en-US') }} XIN </span>
        </div>
        <div class="staking-pool-detail">
            <span> {{ $t('myStake') }}: </span>
            <span> {{ StakeStat.selfStakingAmount.toLocaleString('en-US') }} XIN </span>
        </div>
    </div>
</template>
<script>
import { h } from 'vue';
import { mapGetters } from 'vuex';

export default {
    name: 'StackingPool',
    computed: {
        ...mapGetters({
            StakeStat: 'stake/StakeStat',
        }),
    },
    methods: {
        stylizedText(input) {
            const sep = input.split('.');
            if (sep[1]) {
                return [h('span', sep[0]), h('span', sep[1])];
            }
            return [h('span', input)];
        },
    },
};
</script>
<style lang="scss" scoped>
.staking-pool-container {
    border: 1px solid #ffff;
    border-radius: 20px;
    padding: 32px;
    background: #fff;
    width: 100%;
    border-color: #d8d8d8;
    min-height: 386px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .staking-pool-detail {
        display: flex;
        justify-content: space-between;
        margin-top: 20px;
    }

    h3 {
        font-size: 24px;
        font-weight: 700;
        line-height: 24px;
        text-align: left;
    }

    .staking-pool-detail span:first-child {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.5px;
        color: #3c3c3c;
    }

    .staking-pool-detail span:last-child {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        text-align: right;
        color: #0087cc;
    }

    @media (min-width: 922px) {
        .staking-pool-detail
            span:first-child
            .staking-pool-detail
            span:last-child {
            font-weight: 600;
            font-size: 18px;
            line-height: 24px;
            text-align: right;
            color: #0087cc;
        }
    }
}
</style>
