<template>
    <div class="member-list">
        <div
            class="d-flex justify-content-between flex-wrap align-items-start mb-3"
        >
            <div class="search-member">
                <div class="d-flex align-items-center title-member">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="30"
                        height="30"
                        viewBox="0 0 30 30"
                        fill="none"
                        class="mr-2"
                    >
                        <path
                            d="M15 6.25C13.8397 6.25 12.7269 6.71094 11.9064 7.53141C11.0859 8.35188 10.625 9.46468 10.625 10.625C10.625 11.7853 11.0859 12.8981 11.9064 13.7186C12.7269 14.5391 13.8397 15 15 15C16.1603 15 17.2731 14.5391 18.0936 13.7186C18.9141 12.8981 19.375 11.7853 19.375 10.625C19.375 9.46468 18.9141 8.35188 18.0936 7.53141C17.2731 6.71094 16.1603 6.25 15 6.25ZM15 8.75C15.4973 8.75 15.9742 8.94754 16.3258 9.29917C16.6775 9.65081 16.875 10.1277 16.875 10.625C16.875 11.1223 16.6775 11.5992 16.3258 11.9508C15.9742 12.3025 15.4973 12.5 15 12.5C14.5027 12.5 14.0258 12.3025 13.6742 11.9508C13.3225 11.5992 13.125 11.1223 13.125 10.625C13.125 10.1277 13.3225 9.65081 13.6742 9.29917C14.0258 8.94754 14.5027 8.75 15 8.75ZM6.875 10C6.0462 10 5.25134 10.3292 4.66529 10.9153C4.07924 11.5013 3.75 12.2962 3.75 13.125C3.75 14.3 4.4125 15.3125 5.3625 15.85C5.8125 16.1 6.325 16.25 6.875 16.25C7.425 16.25 7.9375 16.1 8.3875 15.85C8.85 15.5875 9.2375 15.2125 9.525 14.7625C8.61435 13.5757 8.12209 12.1209 8.125 10.625V10.275C7.75 10.1 7.325 10 6.875 10ZM23.125 10C22.675 10 22.25 10.1 21.875 10.275V10.625C21.875 12.125 21.3875 13.575 20.475 14.7625C20.625 15 20.7875 15.1875 20.975 15.375C21.5514 15.9339 22.3221 16.2475 23.125 16.25C23.675 16.25 24.1875 16.1 24.6375 15.85C25.5875 15.3125 26.25 14.3 26.25 13.125C26.25 12.2962 25.9208 11.5013 25.3347 10.9153C24.7487 10.3292 23.9538 10 23.125 10ZM15 17.5C12.075 17.5 6.25 18.9625 6.25 21.875V23.75H23.75V21.875C23.75 18.9625 17.925 17.5 15 17.5ZM5.8875 18.1875C3.475 18.475 0 19.7 0 21.875V23.75H3.75V21.3375C3.75 20.075 4.6125 19.025 5.8875 18.1875ZM24.1125 18.1875C25.3875 19.025 26.25 20.075 26.25 21.3375V23.75H30V21.875C30 19.7 26.525 18.475 24.1125 18.1875ZM15 20C16.9125 20 19.05 20.625 20.2875 21.25H9.7125C10.95 20.625 13.0875 20 15 20Z"
                            fill="url(#paint0_linear_57_1695)"
                        />
                        <defs>
                            <linearGradient
                                id="paint0_linear_57_1695"
                                x1="0"
                                y1="15"
                                x2="30"
                                y2="15"
                                gradientUnits="userSpaceOnUse"
                            >
                                <stop offset="0.48" stop-color="#00D1FF" />
                                <stop offset="1" stop-color="#0087C8" />
                            </linearGradient>
                        </defs>
                    </svg>
                    <div class="title p-0 mr-4">{{ $t('referralList') }}</div>
                </div>
                <div class="input-box">
                    <input
                        :placeholder="$t('findUserByRefIdEmail')"
                        @keydown.enter="onSearch"
                        v-model="search.keyword"
                    />
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="1.5em"
                        height="1.5em"
                        viewBox="0 0 32 32"
                        class="search"
                        @click="onSearch"
                    >
                        <path
                            fill="currentColor"
                            d="M25 9a7 7 0 1 1-14 0a7 7 0 0 1 14 0m-2 0a5 5 0 1 0-10 0a5 5 0 0 0 10 0m-5 21q-.505 0-1-.027a2.5 2.5 0 0 0-.732-1.74l-.368-.368c.675.088 1.378.135 2.1.135c2.877 0 5.434-.744 7.241-1.894C27.061 24.95 28 23.472 28 22v-.5a1.5 1.5 0 0 0-1.5-1.5H14.073a7.5 7.5 0 0 0-1.072-2H26.5a3.5 3.5 0 0 1 3.5 3.5v.5c0 2.393-1.523 4.417-3.685 5.793C24.141 29.177 21.198 30 18 30M7 28.5c1.296 0 2.496-.41 3.476-1.11l3.317 3.317a1 1 0 0 0 1.414-1.414l-3.316-3.316A6 6 0 1 0 7 28.5m0-2a4 4 0 1 1 0-8a4 4 0 0 1 0 8"
                        />
                    </svg>
                </div>
                <div class="input-F">
                    <label class="label-F" for="F">F</label>
                    <input
                        id="F"
                        @keydown.enter="onSearch"
                        type="number"
                        v-model="search.f"
                    />
                </div>
            </div>
        </div>

        <div class="d-none d-lg-block base-table super-round">
            <b-table
                striped
                hover
                :items="Member.list"
                :fields="fields"
                show-empty
                responsive
                thead-class="custom-header"
            >
                <template #empty>
                    <h4 class="text-center my-4">{{ $t('noReferral') }}</h4>
                </template>
                <template #cell(refId)="data">
                    <span>
                        {{ data.value }}
                    </span>
                </template>
                <template #cell(sponsor)="data">
                    <span v-if="data.item.sponsors">
                        {{ data.item.sponsors[1] }}
                    </span>
                </template>
                <template #cell(f)="data">
                    <span v-if="data.item.sponsors"> F{{ data.value }} </span>
                </template>
                <template #cell(largestNode)="data">
                    <span v-if="data.largestNode == null"> None </span>
                </template>
                <template #cell(address)="data">
                    <span>
                        {{ data.item.address }}
                    </span>
                </template>

                <template #cell(ref)="data">
                    <span> F{{ data.item.ref }} </span>
                </template>
                <template #cell(createdAt)="data">
                    <span>
                        {{ getDateTime3(data.item.createdAt) }}
                    </span>
                </template>
                <template #head()="data">
                    {{ $t(data.label) }}
                </template>
            </b-table>
        </div>
        <div class="d-lg-none base-table super-round mobile">
            <b-table
                :items="Member.list"
                :fields="mobileFields"
                show-empty
                striped
                responsive
                thead-class="custom-header"
            >
                <template #cell(#)="row">
                    <b-button
                        size="sm"
                        variant="info"
                        @click="row.toggleDetails"
                        class="px-2 py-0"
                    >
                        {{ row.detailsShowing ? $t('hide') : $t('show') }}
                    </b-button>
                </template>
                <template #cell(f)="data">
                    <span v-if="data.item.sponsors"> F{{ data.value }} </span>
                </template>
                <template #cell(largestNode)="data">
                    <span v-if="data.largestNode == null"> None </span>
                </template>
                <template #cell(refId)="data">
                    <div class="d-block">
                        <div>
                            {{ data.item.refId }}
                        </div>
                        <div>
                            {{ data.item.email }}
                        </div>
                    </div>
                </template>
                <template #cell(ref)="data"> F{{ data.item.ref }} </template>
                <template #row-details="{ item }">
                    <div class="details">
                        <!-- <div class="d-flex justify-content-between">
                                <div>Address:</div>
                                <button
                                    class="address"
                                    @click="CopyAddress(item.address)"
                                >
                                    {{ item.address }}
                                </button>
                            </div> -->
                        <div class="mobile-data">
                            <div>Email:</div>
                            <div>
                                {{ item.email }}
                            </div>
                        </div>
                        <div class="mobile-data">
                            <div>{{ $t('createdDate') }}:</div>
                            <div>
                                {{ getDateTime3(item.createdAt) }}
                            </div>
                        </div>
                        <div class="mobile-data">
                            <div>{{ $t('sponsor') }}:</div>
                            <div v-if="item.sponsors">
                                {{ item.sponsors[1] }}
                            </div>
                        </div>
                    </div>
                </template>
                <template #head()="data">
                    {{ $t(data.label) }}
                </template>
            </b-table>
        </div>
        <div class="table-paginate">
            <Paginate @current="onChangePage" :totalPages="Member.total" />
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Paginate from '@/components/shared/Paginate.vue';

export default {
    name: 'MemberList',
    components: { Paginate },
    data() {
        return {
            renderComponent: true,
            search: {
                keyword: '',
                page: 1,
                address: null,
                f: '',
            },
            mobileFields: [
                {
                    key: 'refId',
                    sortable: true,
                    label: 'refID',
                    class: 'text-left',
                },
                {
                    key: 'largestNode',
                    sortable: false,
                    label: 'Largest Node',
                    class: 'text-center',
                },
                {
                    key: 'totalNode',
                    sortable: false,
                    label: 'Total Node',
                    class: 'text-center',
                },
                {
                    key: 'mana',
                    sortable: false,
                    label: 'Mana',
                    class: 'text-center',
                },
                {
                    key: 'f',
                    sortable: true,
                    label: 'f',
                    class: 'text-center',
                },
                {
                    key: '#',
                    label: 'action',
                    class: 'text-center',
                },
            ],
            fields: [
                {
                    key: 'refId',
                    sortable: true,
                    label: 'refID',
                    class: 'text-center',
                },
                {
                    key: 'email',
                    sortable: true,
                    label: 'email',
                    class: 'text-center',
                },
                {
                    key: 'largestNode',
                    sortable: false,
                    label: 'Largest Node',
                    class: 'text-center',
                },
                {
                    key: 'totalNode',
                    sortable: false,
                    label: 'Total Node',
                    class: 'text-center',
                },
                {
                    key: 'mana',
                    sortable: false,
                    label: 'Mana',
                    class: 'text-center',
                },
                // {
                //     key: 'ref',
                //     label: 'Referral',
                //     sortable: false,
                //     class: 'text-center',
                // },
                {
                    key: 'sponsor',
                    sortable: true,
                    label: 'sponsor',
                    class: 'text-center',
                },
                {
                    key: 'f',
                    sortable: true,
                    label: 'f',
                    class: 'text-center',
                },
                {
                    key: 'createdAt',
                    sortable: true,
                    label: 'registerDate',
                },
            ],
        };
    },
    computed: {
        ...mapGetters({
            Member: 'member/Member',
        }),
    },
    methods: {
        onSearch(type) {
            if (type === 'reset') {
                this.search = {
                    keyword: '',
                    page: 1,
                };
            }
            this.search.page = 1;
            this.$store.dispatch('member/req_getListMember', this.search);
        },
        onChangePage(payload) {
            this.search.page = payload;
            this.$store.dispatch('member/req_getListMember', this.search);
        },
    },
    mounted() {
        this.onSearch();
    },
};
</script>

<style lang="scss" scoped>
.member-tab {
    .header-icon {
        max-width: 55px;
        width: 100%;
        height: 100%;

        img {
            width: 100%;
            height: auto;
            object-fit: scale-down;
        }
    }

    .title {
        font-size: clamp(1.2em, 4vw, 1.8em);
        padding-bottom: 10px;
        position: relative;
        letter-spacing: 0.6px;
        width: max-content;
        // margin: 0 0 25px;
        font-weight: 600;
        background: #0087cc;

        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    .statistical {
        margin-top: 15px;
    }

    .member-list {
        margin-top: 30px;
    }

    .search-member {
        display: flex;
        gap: 32px;
        align-items: center;

        .title-member {
            width: fit-content;
        }

        .input-box {
            min-width: none;
        }

        // input {
        //     outline: none;
        //     border: none;
        //     height: 40px;
        //     width: 100%;
        //     border-radius: 12px;
        //     background: transparent;
        //     border: 1px solid #d8d8d8;
        //     color: #fff;
        //     min-width: 0px;

        //     &:focus {
        //         box-shadow: none;
        //     }
        // }

        button {
            border-radius: 100vw;
            background: linear-gradient(50deg, #0087c8 14.98%, #00d1ff 106.07%);
            box-shadow: 0px 2px 11.6px 1px rgba(176, 176, 176, 0.25) inset;
            display: flex;
            gap: 5px;
        }

        @media (max-width: 1280px) {
            gap: 20px;

            .title-member {
                .title {
                    font-size: 24px;
                }
            }
        }

        @media (max-width: 1024px) {
            flex-direction: column;
            align-items: flex-start;
        }
    }

    .box-input-group {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
        gap: 5px;

        .link-input {
            border: none;
            max-width: 570px;
            width: 100%;
            padding: 5px;
            word-wrap: break-word;
            background: transparent;
            box-shadow: 0px 0px 5px 1px #0000001e;
            // opacity: 0.5;
            border-radius: 12px;
            outline: none;
            color: #a6a6a6;
        }

        .copy {
            background-image: linear-gradient(
                50deg,
                #0087c8 14.98%,
                #00d1ff 106.07%
            );
        }

        svg {
            margin-right: 5px;
        }

        input {
            outline: none;
            border: 1px solid #00c2ff;
            background: linear-gradient(180deg, #00172a 0%, #00000b 100%);
            height: 40px;
            width: 80%;
            border-radius: 0;
            color: #fff;
            border: none;
        }

        button {
            max-width: 170px;
            width: 100%;
            height: 42px;
            border-radius: 267px;
            background: rgba(0, 255, 255, 0.15);
            font-weight: 600;
            font-size: 18px;
            display: flex;
            align-items: center;
            justify-content: center;
            text-transform: capitalize;
            border: none;
        }
    }

    // .table-responsive {
    //     background: #1478b0;
    //     border-radius: 22px;

    //     .customer-header {
    //         background-color: transparent;
    //         text-align: center;
    //         font-style: normal;
    //         font-weight: 500;
    //         font-size: 16px;
    //         line-height: 24px;
    //         color: #fff;
    //     }

    //     .table th,
    //     .table td {
    //         border: none;
    //     }

    //     table {
    //         thead {
    //             tr {
    //                 th {
    //                     white-space: nowrap;
    //                 }
    //             }
    //         }

    //         tbody {
    //             tr {
    //                 height: 70px;
    //                 border-radius: 12px;
    //                 background: transparent;

    //                 &:nth-child(even) {
    //                     background: #e1e1e175;
    //                 }

    //                 td {
    //                     background: none;
    //                     height: 100%;
    //                     padding: 0.75rem;
    //                     vertical-align: middle;
    //                     color: #fff;
    //                     font-weight: 500;

    //                     .mobile-data {
    //                         display: flex;
    //                         justify-content: space-between;
    //                         flex-wrap: wrap;
    //                         line-height: 1;
    //                         padding: 5px 0px;

    //                         > div:last-child {
    //                             color: #fff;
    //                         }
    //                     }

    //                     > span {
    //                         height: 70px;
    //                         display: flex;
    //                         padding: 0 0.75rem;
    //                         align-items: center;
    //                         margin-bottom: 0;
    //                         justify-content: center;
    //                         backdrop-filter: blur(15.899999618530273px);
    //                     }
    //                 }
    //             }
    //         }
    //     }
    // }

    .table-statistical {
        background: #1478b0;
        border-radius: 22px;
        border: 1px solid #d8d8d8;
        padding: 15px;
        color: #fff;
        position: relative;
        z-index: 999;

        .logo {
            max-width: 165px;
            width: 100%;
            margin: 30px auto;
        }

        .ref-link {
            background-color: #579ec6;
            box-shadow: 0px 0px 5px 1px #0000001e;
            border-radius: 6px;
            max-width: 465px;
            min-width: 0;
            margin: auto;
            display: block;
            width: 100%;
            border: none;
            padding: 6px 10px;
            word-wrap: break-word;
            text-align: center;
            font-size: 14px;
            line-height: 1;
        }

        .copy {
            background: #fff;
            width: 100%;
            max-width: 465px;
            border-radius: 100vw;
            font-weight: 600;
            font-size: 18px;
            margin: auto;
            margin-top: 15px;
            display: flex;
            align-items: center;
            justify-content: center;
            text-transform: capitalize;
            border: none;
            color: #1478b0;
            transition: 0.2s;

            &:hover {
                opacity: 0.8;
            }
        }

        .item {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            padding: 10px 0px;

            > div:last-child {
                color: #97d7f8;
            }
        }
        .updated-at {
            text-align: right;
            color: #dfdfdf;
            font-weight: 500;
        }
        .update-data {
            color: #1478b0;
            background-color: #fff;
            border-radius: 100vw;
            font-weight: 600;
            padding: 4px;
        }

        .plane-1,
        .plane-2,
        .plane-3 {
            position: absolute;
            z-index: -999;
        }

        .plane-1 {
            top: 35px;
            left: 0;

            img {
                width: 150px;
            }
        }

        .plane-2 {
            bottom: 0;
            right: 0;

            img {
                width: 150px;
            }
        }

        .plane-3 {
            top: 15px;
            right: 50%;
        }

        .col-lg-7 {
            display: flex;
            flex-direction: column;
            justify-content: center;
        }
    }

    .box-statistical {
        background: #1478b0;
        height: 100%;
        outline: none;
        padding: 15px;
        position: relative;
        border-radius: 22px;
        border: 1px solid #d8d8d8;

        .icons {
            flex: 0 0 70px;
            width: 70px;
            height: 100%;

            img {
                width: 50px;
                height: auto;
            }
        }

        .text {
            color: #fff;
            z-index: 1;
            flex-grow: 1;
            font-size: 14px;

            .text-title {
                font-size: 1em;
                font-style: italic;
                font-weight: 500;
                margin-bottom: 15px;
                text-transform: capitalize;
                display: flex;
                justify-content: space-between;
            }

            .text-content {
                font-size: 1.125em;
                color: #6dceff;
                font-weight: 700;
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
                gap: 5px;

                &.bound {
                    color: #0f766e;
                }

                > span {
                    min-width: 0;
                    word-wrap: break-word;
                }

                > div:last-child {
                    flex-shrink: 0;
                }
            }

            &.bound {
                .text-content {
                    color: #fff;
                }

                .text-title {
                    color: #fff;
                }
            }

            @media (min-width: 992px) {
                font-size: 16px;
            }
        }
    }
}
.input-box,
.input-F {
    position: relative;

    > input {
        border-radius: 6px;
        padding: 4px 36px 4px 16px;
        border: 1px solid #adadad;
        outline: none;
        // box-shadow: 0px 0px 0px 1px #adadad;
    }

    .search {
        position: absolute;
        right: 5px;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;
    }
}

.input-F {
    position: relative;

    > input {
        padding: 4px 4px 4px 30px;
    }

    .label-F {
        position: absolute;
        z-index: 10;
        top: 50%;
        transform: translateY(-50%);
        left: 5%;
        color: #767676;
    }
}
</style>
